<script setup>
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { computed, onMounted, ref } from "vue";
import { BACK_URL } from "@/constant";
import Swal from "sweetalert2";
import axiosClient from "@/axios";

const store  = useStore();
const router = useRouter();
const previewPhoto = ref('')
const isProfileUpdating = ref(false);
const profileFormErrors = ref({});

const connectedEntreprise = computed(() =>
    store.getters.getEntrepriseUser
);

const profileForm  = ref({
    nom_entreprise : connectedEntreprise.value.nom_entreprise,
    logo : connectedEntreprise.value.logo,
    telephone : connectedEntreprise.value.telephone,
    adresse : connectedEntreprise.value.adresse,
})

const handleAvatarUpload = (e) => {
    const files = e.target.files
    if (!files || !files.length) return
    const file = files[0]
    profileForm.value.logo = file;
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => {
        previewPhoto.value  = String(reader.result);
    }
};

function updateProfile(){
        Swal.fire({
        title: 'Êtes-vous sûr?',
        text: "Les modifications seront enregistées ensuite",
        icon: 'question',
        showCloseButton: true,
        showCancelButton: true,
        confirmButtonColor:  '#022279', //'#ffc107',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Continuer',
        cancelButtonText: 'Annuler'
        }).then((result) => {
            if (result.isConfirmed) {
                    isProfileUpdating.value = true;
                    const formData = new FormData();
                    formData.append('nom_entreprise', profileForm.value.nom_entreprise);
                    formData.append('logo', profileForm.value.logo);
                    formData.append('telephone', profileForm.value.telephone);
                    formData.append('adresse', profileForm.value.adresse);
                    formData.append('_method', 'put');
                    axiosClient.post(`/entreprise_profile`, formData)
                        .then((res)=>{
                            if (res.status == 201) {
                                Swal.fire({
                                    title: 'Mise à jour réussie',
                                    text: 'Votre profile été modifié avec succès',
                                    icon: 'success',
                                    confirmButtonColor: '#022279', //'#ffc107',
                                    confirmButtonText: 'OK'
                                }).finally(()=>{
                                    store.commit('setUser', res.data);
                                })
                            }
                        }).catch((error) => {
                            if (error.response.status == 422) {
                                profileFormErrors.value = error.response.data.errors;
                            }else{
                                Swal.fire({
                                title:'Erreur',
                                text: 'L\'opération de mise à jour n\'a pas pu être effectuée',
                                icon: 'error',
                                confirmButtonColor: '#022279', //'#ffc107',
                                confirmButtonText: 'OK'
                            });
                            }
                        }).finally(() => {   
                            isProfileUpdating.value = false;    
                        })
            }
        });
    }

onMounted(() => {
    previewPhoto.value =  
        connectedEntreprise.value.logo ? BACK_URL + 'storage/logo/'+ connectedEntreprise.value.logo: '/images/user.jpg'
});

function goBack() {
    router.back();
}

</script>
<template>
    <div class="aon-admin-heading">
        <div class="d-flex justify-content-start align-items-center">
            <button class="btn btn-light btn-lg mr-2" type="button" @click="goBack()">
                <i class="feather-arrow-left"></i>
            </button>
            <h3 class="text-center">PROFIL</h3>
        </div>
    </div>                
    <div class="card aon-card">
                	<div class="card-header aon-card-header"><h4>INFORMATIONS SUR LE PROFIL</h4></div>
                    <div class="card-body aon-card-body">
                    	<div class="row">
                            <div class="col-xl-6">
                                <div class="aon-staff-avtar">
                                    <div class="aon-staff-avtar-header">
                                        <div class="aon-pro-avtar-pic">
                                            <img :src="previewPhoto" alt="Avatar">
                                            <button class="admin-button has-toltip">
                                                <i class="fa fa-camera"></i>
                                                <span class="header-toltip">Téléverser</span>
                                                <!-- Input pour télécharger l'avatar -->
                                                <input type="file" name="avatar" @change="handleAvatarUpload"  enctype="multipart/form-data">
                                            </button>
                                        </div>
                                    </div>
                                    <div class="aon-staff-avtar-footer">
                                        <h4 class="aon-staff-avtar-title">Téléverser une Image</h4>
                                        <ul>
                                            <li>Taille maximale du fichier: <span>1MB</span></li>
                                            <li>Extensions: <span>JPEG, PNG, PNG</span></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-6">
                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label>NOM DE L'ENTREPRISE</label>
                                            <input class="form-control"  type="text" v-model="profileForm.nom_entreprise" :class="{'is-invalid':profileFormErrors.nom_entreprise}">
                                            <span v-if="profileFormErrors.nom_entreprise" class="invalid-feedback">{{ profileFormErrors.nom_entreprise[0]}}</span>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label>TELEPHONE</label>
                                            <input class="form-control"  type="number" v-model="profileForm.telephone" :class="{'is-invalid':profileFormErrors.telephone}">
                                            <span v-if="profileFormErrors.telephone" class="invalid-feedback">{{ profileFormErrors.telephone[0]}}</span>
                                        </div>
                                    </div>
                                    <div class="col-md-12">
                                        <div class="form-group">
                                            <label>ADRESSE</label>
                                            <textarea class="form-control" rows="4" v-model="profileForm.adresse" :class="{'is-invalid':profileFormErrors.adresse}"></textarea>
                                            <span v-if="profileFormErrors.adresse" class="invalid-feedback">{{ profileFormErrors.adresse[0]}}</span>
                                        </div>
                                    </div>
                                    <div class="col-md-12">
                                        <button class="btn admin-button" :disabled="isProfileUpdating" @click="updateProfile()">
                                            <i v-if="isProfileUpdating" class="fa fa-fw fa-spinner fa-spin mr-1"></i>
                                            <i v-else class="fa fa-save"></i>
                                            {{ isProfileUpdating ? 'ENREGISTREMENT' : 'ENREGISTRER LES MODIFICATIONS' }}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>   
                
                <!--<div class="card aon-card" id="aon-passUpdate-panel">
                	<div class="card-header aon-card-header"><h4><i class="fa fa-lock"></i> Password Update</h4></div>
                    <div class="card-body aon-card-body">
                    	<div class="row">
                        	<div class="col-md-6">
                                <div class="form-group">
                                    <label>New Password</label>
                                    <div class="aon-inputicon-box"> 
                                        <input class="form-control sf-form-control" name="company_name" type="text">
                                        <i class="aon-input-icon fa fa-lock"></i>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label>Repeat Password</label>
                                    <div class="aon-inputicon-box"> 
                                        <input class="form-control sf-form-control" name="company_name" type="text">
                                        <i class="aon-input-icon fa fa-lock"></i>
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                        <p>Enter same password in both fields. Use an uppercase letter and a number for stronger password.</p>
                    </div>
                </div>-->
</template>
<style>
</style>