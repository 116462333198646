<script>
import { useStore } from "vuex";
import { computed, onMounted, ref, watch } from "vue";
import { useRouter } from "vue-router";
import axiosClient from '@/axios';
import Swal from 'sweetalert2';

export default {
    setup() {
        const router = useRouter();
        const store = useStore();
        const emailQuery = ref('')
        const telephoneQuery = ref('');
        const isPageLoading = ref(false);
        const isSearching = ref(false);
        const dateQuery = ref('');
        const selectedMessage = ref({});
        const currentPage = ref(1);
        const perPage = 10; 
        const contact = computed(() => store.state.contactModule.contact);

        const paginatedContacts = computed(() => {
            const startIndex = (currentPage.value - 1) * perPage;
            const endIndex = startIndex + perPage;
            return contact.value.slice(startIndex, endIndex);
        });

        const totalPages = computed(() => Math.ceil(contact.value.length / perPage));


        const formatDate = (dateTimeString) => {
            const fullDate = new Date(dateTimeString);
            const options = {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
                hour: '2-digit',
                minute: '2-digit',
                second: '2-digit',
                hour12: false
            };
            return fullDate.toLocaleString('fr-FR', options);
        };

        function shortenText(name, maxLength = 100) {
            if (name.length > maxLength) {
                return name.substring(0, maxLength - 3) + '...';
            }
                return name;
        }

        function viewMore(contact) {
            selectedMessage.value = contact
            $('#message_dialog').modal('show'); 
        }

        function closeMessageDialog(){
            $('#message_dialog').modal('hide');
        }

        function goBack() {
            router.back();
        }

        async function deleteContact(contactId) {
            const result = await Swal.fire({
            icon: 'question',
            title: 'Confirmation',
            text: 'Êtes-vous sûr de vouloir supprimer ce message ?',
            confirmButtonColor:  '#022279', //'#ffc107',
            cancelButtonColor: '#d33',
            showCloseButton: true,
            showCancelButton: true,
            confirmButtonText: 'Continuer',
            cancelButtonText: 'Annuler',
        });

        if (result.isConfirmed) {
            closeMessageDialog();
           await axiosClient.delete(`/contact/${contactId}`)
                .then((res) => {
                    if (res.status == 204) {
                        store.dispatch('contactModule/fetchContact', {
                            emailQuery: emailQuery.value, telephoneQuery: telephoneQuery.value, dateQuery: dateQuery.value

                        }).then(() =>{
                                Swal.fire({
                                    icon: 'success',
                                    title: 'Le message a été supprimé avec succès',
                                }); 
                            })
                    }
                }).catch(error => {
                    Swal.fire({
                        title: 'Erreur',
                        text: 'Un problème est survenu lors de la suppresion du service, vérifiez votre connexion internet puis réessayez',
                        icon: 'error',
                        confirmButtonColor: '#022279', //'#ffc107',
                        confirmButtonText: 'OK'
                    });
                });
            }
        };

        function clearFilters(){
            emailQuery.value = '';
            telephoneQuery.value = '';
            dateQuery.value = '';
        }

        function previousPage() {
            if (currentPage.value > 1) {
                currentPage.value--;
            }
        }

        function nextPage() {
            if (currentPage.value < totalPages.value) {
                currentPage.value++;
            }
        }

        onMounted((async) => {
            isPageLoading.value = true;
            store.dispatch('contactModule/fetchContact', {
                emailQuery: emailQuery.value, telephoneQuery: telephoneQuery.value, dateQuery: dateQuery.value
            }).then(() =>{
                isPageLoading.value = false;
            }); 
        });

        watch(emailQuery, async (newValue) => {
            isSearching.value = true
            store.dispatch('contactModule/fetchContact', {emailQuery: newValue, telephoneQuery: telephoneQuery.value, dateQuery: dateQuery.value})
            .then(()=>{
                isSearching.value = false;
            })
        });

        watch(telephoneQuery, async (newValue) => {
            isSearching.value = true
            store.dispatch('contactModule/fetchContact', {emailQuery: emailQuery.value, telephoneQuery: newValue, dateQuery: dateQuery.value})
            .then(()=>{
                isSearching.value = false;
            })
        });

        watch(dateQuery, async (newValue) => {
            isSearching.value = true
            store.dispatch('contactModule/fetchContact', {emailQuery: emailQuery.value, telephoneQuery: telephoneQuery.value, dateQuery: newValue})
            .then(()=>{
                isSearching.value = false;
            })
        });

        return {
            store,
            router,
            contact,
            emailQuery,
            telephoneQuery,
            dateQuery,
            isSearching,
            isPageLoading,
            selectedMessage,
            perPage,
            currentPage,
            totalPages,
            paginatedContacts,
            formatDate,
            shortenText,
            viewMore,
            goBack,
            deleteContact,
            clearFilters,
            closeMessageDialog,
            previousPage,
            nextPage,
        };
    },
};
</script>
<template>
    <div>
        <div>
            <div class="aon-admin-heading">
                <div class="d-flex justify-content-start align-items-center">
                    <button class="btn btn-light btn-lg mr-2" type="button" @click="goBack()">
                        <i class="feather-arrow-left"></i>
                    </button>
                    <h3 class="text-center">MESSAGES</h3>
                </div>
            </div>
            <div class="card aon-card">
                <div class="card-header aon-card-header">
                    <div class="aon-card-header aon-blog-style-1 d-flex justify-content-between align-items-center flex-wrap" style="background-color: rgba(50, 50, 168, 0.2); border: 1px solid rgba(50, 50, 168, 0.5); border-radius: 10px;">
                        <div>
                            <h4><i class="fa fa-list"></i> LISTE DES MESSAGES</h4>
                        </div> 
                    </div>
                </div>
                <div class="card-body aon-card-body">
                    <div class="pb-3 px-3 mb-4" style="background-color: rgba(50, 50, 168, 0.2); border: 1px solid rgba(50, 50, 168, 0.5); border-radius: 10px;">
                        <div class="py-3 d-flex justify-content-between align-items-center flex-wrap">
                            <h5>FAIRE UNE RECHERCHE PAR : </h5>
                            <button class="btn btn-secondary" @click="clearFilters">Effacer les filtres</button>
                        </div>
                        <!-- Search inputs -->
                        <div class="row">
                            <div class="col-lg-4 form-group">
                                <label>EMAIL</label>
                                <input class="form-control" type="text" v-model="emailQuery">
                            </div>
                            <div class="col-lg-4 form-group">
                                <label>NUMERO DE TELEPHONE</label>
                                <input class="form-control" type="number" v-model="telephoneQuery">
                            </div>
                            <div class="col-lg-4 form-group">
                                <label>DATE</label>
                                <input class="form-control" type="date" v-model="dateQuery">
                            </div>
                        </div>
                    </div> 
                    <div v-if="isSearching" class="text-center align-middle">
                        <i class="fa fa-2x fa-spinner fa-spin mr-2"></i> Filtrage en cours...
                    </div>
                    <div v-if="contact.length > 0" class="sf-bs-data-table mt-2">
                        <div class="table-responsive">
                            <table  class="table table-striped table-bordered aon-booking-table" style="width:100%">
                                <thead>
                                    <tr class="bg-warning">
                                        <th class="text-center align-middle">N</th>
                                        <th class="text-center align-middle">EMAIL</th>
                                        <th class="text-center align-middle d-none d-lg-table-cell">TELEPHONE</th>
                                        <th class="text-start align-middle ">SUJET</th>
                                        <th class="text-start align-middle">MESSAGE</th>
                                        <th class="text-center align-middle d-none d-lg-table-cell">DATE D'ENVOI</th>
                                        <th class="text-center align-middle">ACTIONS</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(contacts, index) in paginatedContacts" :key="index">
                                        <td class="text-center align-middle">
                                            {{ (currentPage- 1)*(perPage) + (index +1) }}            
                                        </td>
                                        <td class="text-center align-middle">
                                            {{ contacts.email }}           
                                        </td>
                                        <td class="text-center align-middle d-none d-lg-table-cell">
                                            {{ contacts.telephone }}          
                                        </td>
                                        <td class="text-start align-middle">
                                            {{ contacts.sujet }}
                                        </td>
                                        <td class="text-start align-middle">
                                            {{ shortenText(contacts.message) }}
                                        </td>
                                        <td class="text-center align-middle d-none d-lg-table-cell">
                                            {{ formatDate(contacts.created_at) }}
                                        </td>
                                        <td class="text-center"> 
                                            <button class="btn btn-primary m-1" type="button" @click="viewMore(contacts)">
                                                <i class="fa fa-eye"></i>
                                            </button>
                                            <button class="btn btn-danger m-1" type="button" @click="deleteContact(contacts.id)">
                                                <i class="fa fa-trash-o"></i>
                                            </button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <div class="d-flex justify-content-between align-items-center">
                                <button class="btn admin-button sm:btn-sm" @click="previousPage" :disabled="currentPage === 1">
                                    <i class="feather-arrow-left"></i>
                                    Précédent
                                </button>
                                {{ currentPage }} sur {{ totalPages }} pages
                                <button class="btn admin-button sm:btn-sm" @click="nextPage" :disabled="currentPage === totalPages">
                                    Suivant
                                    <i class="feather-arrow-right"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div v-else>
                        <div v-if="isPageLoading" class="text-center">
                            <i class="fa fa-2x fa-spinner fa-spin mr-2"></i> Chargement des messages
                        </div>
                        <div v-else class="my-2 alert alert-warning">
                            Aucune message retrouvé.
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal fade content-admin-main" id="message_dialog" tabindex="-1" role="dialog" aria-hidden="true">
            <div class="modal-dialog model-w800" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel2">MESSAGE</h5>
                        <button type="button" class="close" @click="closeMessageDialog()"  aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="sf-md-padding">
                            <ul class="customer-info ">
                                <li class="d-flex flex-wrap"><strong>Email expéditeur : </strong>{{ selectedMessage.email }}</li>
                                <li class="d-flex flex-wrap"><strong>Numéro expéditeur : </strong> {{ selectedMessage.telephone }}</li>
                                <li class="d-flex flex-wrap"><strong>Date d'envoie : </strong>{{ formatDate(selectedMessage.created_at )}}</li>
                                <li class="d-flex flex-wrap"><strong>Objet du message : </strong>{{ selectedMessage.sujet }}</li>
                                <div class="d-flex flex-wrap text-black"><strong>Contenu du message : </strong>{{ selectedMessage.message }}</div>
                            </ul>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="admin-button" @click="closeMessageDialog()">FERMER</button>
                        <button @click="deleteContact(selectedMessage.id)" type="button" class="btn admin-button-danger">
                            <i class="fa fa-trash-o"></i>
                            Supprimer
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<style>
</style>