<script>
import axios from '@/axios';
import { BACK_URL } from '@/constant';
import axiosClient from '@/axios';
import { reactive } from 'vue';
import Swal from 'sweetalert2';
import store from '@/store';
import { useSwalSuccess } from '@/composables/alert';

export default {
    data() {
        return {
            contratId: null,
            contrat: {},
            BACK_URL,
            isDetailsLoading: false,
            isObjetModalOpen: false,
            isObjetUpdating: false,
            selectedObjetId: null,

            objetData :  reactive({
                intitule: '',
                prix_unitaire: '',
                niveau_priorite: '',
                contrat_id: '',
            }),
        };
    },

    computed:{
        objetFormErrors(){
            return store.state.prestataireModule.objetErrors;
        },
    },

    methods :{
        async downloadContratFile(contratPath) {
            await axiosClient.get(`/download-contrat-file?path=${contratPath}` , {responseType: 'blob'})
                .then((response) => {
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', contratPath);
                    document.body.appendChild(link);
                    link.click();
                }).catch(error => {
                    console.error('Erreur lors du téléchargement du fichier :', error);
                }).finally(() => {

                });
        },

        editObjet(objet){
            this.selectedObjetId = objet.id;
            this.objetData.contrat_id = this.contratId;
            this.objetData.intitule = objet.intitule;
            this.objetData.niveau_priorite = objet.niveau_priorite;
            this.objetData.prix_unitaire = objet.prix_unitaire;
            this.isObjetModalOpen = true;
        },

        updateObjet() {
            Swal.fire({
                title: 'Êtes-vous sûr?',
                text: "Les modifications seront enregistées ensuite",
                icon: 'question',
                showCloseButton: true,
                showCancelButton: true,
                confirmButtonColor:  '#022279', //'#ffc107',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Continuer',
                cancelButtonText: 'Annuler'
                }).then((result) => {
                    if (result.isConfirmed) {
                        if (this.selectedObjetId != null) {
                            this.isObjetUpdating = true;
                            const formData = new FormData();
                            formData.append('intitule', this.objetData.intitule);
                            formData.append('prix_unitaire', this.objetData.prix_unitaire);
                            formData.append('niveau_priorite', this.objetData.niveau_priorite);
                            formData.append('_method', 'put');
                            axiosClient.post(`/objets/${this.selectedObjetId}`, formData)
                                .then((res)=>{
                                    if (res.status = 201) {
                                        this.closeObjetModal();
                                        Swal.fire({
                                            title: 'Mise à jour réussie',
                                            text: 'L\'objet a été modifié avec succès',
                                            icon: 'success',
                                            showCloseButton: true,
                                            confirmButtonColor: '#022279', //'#ffc107',
                                            confirmButtonText: 'OK'
                                        }).finally(()=>{
                                        })
                                        axiosClient.get(`/contrats/${parseInt(this.contratId)}`)
                                            .then((response)=>{
                                                this.contrat = response.data.contrat;
                                            })
                                    }
                                }).catch((error) => {
                                    Swal.fire({
                                        title:'Erreur',
                                        text: 'Echec de la mise à jour de l\'objet',
                                        icon: 'error',
                                        showCloseButton: true,
                                        confirmButtonColor: '#022279', //'#ffc107',
                                        confirmButtonText: 'OK'
                                    });
                                    if (error.response.status == 422) {
                                        store.commit('prestataireModule/setObjetErrors', error.response.data.errors);
                                    }
                                }).finally(() => {   
                                    this.isObjetUpdating = false;    
                                })
                        }
                    }
                });
        },

        async deleteObjet(objetId){
            Swal.fire({
                title: 'Êtes-vous sûr?',
                text: "Cet objet sera supprimé ensuite!",
                icon: 'question',
                showCloseButton: true,
                showCancelButton: true,
                confirmButtonColor:'#022279', //'#ffc107',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Continuer',
                cancelButtonText: 'Annuler'
                }).then((result) => {
                    if (result.isConfirmed) {
                        axiosClient.delete(`/objets/${objetId}`)
                            .then((res) => {
                                if (res.status == 204) {
                                    axiosClient.delete(`/contrats/${parseInt(this.contratId)}`)
                                        .then(() =>{
                                            useSwalSuccess(`L\'objet a été supprimé avec succès`);
                                        })
                                }
                            }).catch(error => {
                                if(error.response.status == 403){
                                    Swal.fire({
                                        title: 'Attention',
                                        text: 'L\'objet que vous tentez de supprimer est lié à une tâche au moins. Il ne peut donc être supprimé.',
                                        icon: 'warning',
                                        showCloseButton: true,
                                        confirmButtonColor: '#022279', //'#ffc107',
                                        confirmButtonText: 'OK'
                                    });
                                }else{
                                    Swal.fire({
                                        title: 'Erreur',
                                        text: 'Un problème est survenu lors de la suppresion, vérifiez votre connexion internet puis réessayez',
                                        icon: 'error',
                                        showCloseButton: true,
                                        confirmButtonColor: '#022279', //'#ffc107',
                                        confirmButtonText: 'OK'
                                    });
                                }
                            });
                    } 
                })  
        },

        closeObjetModal(){
            this.isObjetModalOpen = false;
            this.resetObjetForm();
        },
    
        resetObjetForm(){
            store.commit('prestataireModule/setObjetErrors', {});
            this.objetData.intitule = '',
            this.objetData.prix_unitaire = '',
            this.objetData.niveau_priorite = ''
        },

        goBack() {
            this.$router.back();
        }
    },

    async mounted() {

        this.isDetailsLoading = true;
        this.contratId = this.$route.params.id;

        try {
            const response = await axios.get(`/contrats/${this.contratId}`);
            this.contrat = response.data.contrat;
        } catch (error) {
            console.error('Erreur lors de la récupération des détails du contrat :', error);
        } finally{
            this.isDetailsLoading = false;
        }
    },

};
</script>

<template>
    <div>
        <div class="aon-admin-heading">
            <div class="d-flex justify-content-start align-items-center">
                <button class="btn btn-light btn-lg mr-2" type="button" @click="goBack()">
                    <i class="feather-arrow-left"></i>
                </button>
                <h3 class="text-center">CONTRATS > DETAILS</h3>
            </div>
        </div>     
        <div class="card aon-card">
            <div class="card-header aon-card-header">
                <div class="aon-card-header aon-blog-style-1 d-flex justify-content-between align-items-center flex-wrap" style="background-color: rgba(50, 50, 168, 0.2); border: 1px solid rgba(50, 50, 168, 0.5); border-radius: 10px;">
                    <div class="py-2">
                        <h4><i class="fa fa-file-text"></i> DESCRIPTION DU CONTRAT</h4>
                    </div>
                    <div v-if="contrat">
                        <button v-if="contrat.termes_contrat" class="btn admin-button-secondry" type="button" @click="downloadContratFile(contrat.termes_contrat)">
                            <i class="fa fa-download"></i>
                            Télécharger le fichier 
                        </button>
                    </div> 
                </div>
            </div>
            <div v-if="contrat" class="card-body aon-card-body">
                <div  class="sf-bs-data-table">
                    <div class="row">
                        <div class="col-lg-6">
                            <div class="sf-vender-list-wrap mb-4">
                                <div class="sf-vender-list-box p-4">
                                    <div class="py-2 mb-3 text-center align-middle description-div-title">
                                        <strong>INFORMATIONS GÉNÉRALES</strong>
                                    </div>
                                    <div v-if="contrat.references" class="d-flex justify-content-between flex-wrap">
                                        <ul class="customer-info">
                                            <li  class="d-flex flex-wrap"><strong>Référence du contrat : </strong>{{ contrat ? contrat.references : '' }}</li>
                                            <li>
                                                <strong>Statut du contrat : </strong>
                                                <span v-if="contrat.statut == 'actif'" class="badge badge-success">{{ contrat.statut }}</span>
                                                <span v-if="contrat.statut == 'terminee'" class="badge badge-danger">{{ contrat.statut }}</span>
                                            </li>
                                            <li>
                                                <a type="button" class="btn btn-small admin-button" :href="`/service/contrats/${parseInt(contratId)}/taches`">
                                                    <i class="fa fa-list"></i> Vers les tâches
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                    <div v-else class="text-center">
                                        <i class="fa fa-2x fa-spinner fa-spin mr-2"></i>
                                    </div>
                                </div>
                            </div>
                            <div class="sf-vender-list-wrap mb-4">
                                <div class="sf-vender-list-box p-4">
                                    <div class="py-2 mb-3 text-center align-middle description-div-title">
                                        <strong>OBJETS DU CONTRAT</strong>
                                    </div>
                                    <div v-if="contrat.objets" class="d-flex justify-content-between flex-wrap" >
                                        <div v-if="contrat.objets.length != 0" class="sf-bs-data-table">
                                            <div class="table-responsive">
                                                <table class="table table-striped table-bordered table-vcenter" style="width:100%;">
                                                    <thead class="bg-warning">
                                                        <tr>
                                                            <th class="text-center align-middle">N°</th>
                                                            <th class="text-start align-middle">INTITULE</th>
                                                            <th class="text-center align-middle d-none d-md-table-cell">PRIX U (FCFA)</th>
                                                            <th class="text-center align-middle">PRIORITE</th>
                                                            <th class="text-center align-middle">ACTIONS</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr v-for="(objet, index) in contrat.objets" :key="index">
                                                            <td class="text-center align-middle">{{ index + 1}}</td>
                                                            <td class="text-start align-middle">{{ objet.intitule }}</td>
                                                            <td class="text-center align-middle d-none d-md-table-cell">{{ objet.prix_unitaire }}</td>
                                                            <td class="text-center align-middle">
                                                                <span v-if="objet.niveau_priorite == 'normal'" class="badge badge-info mr-2">{{ objet.niveau_priorite }}</span>
                                                                <span v-if="objet.niveau_priorite == 'urgent'" class="badge badge-warning mr-2">{{ objet.niveau_priorite }}</span>
                                                                <span v-if="objet.niveau_priorite == 'critique'" class="badge badge-danger mr-2">{{ objet.niveau_priorite }}</span>
                                                            </td>
                                                            <td class="text-center align-middle">
                                                                <button @click="editObjet(objet)" type="button" class="btn btn-sm btn-warning m-2" title="Éditer">
                                                                    <i class="fa fa-edit"></i>
                                                                </button> 
                                                                <button @click="deleteObjet(objet.id)" type="button" class="btn btn-sm btn-danger" title="Supprimer">
                                                                    <i class="fa fa-trash"></i>
                                                                </button>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        <div v-else class="my-2 alert alert-warning">
                                            Aucun objet retrouvé
                                        </div> 
                                    </div>
                                    <div v-else class="text-center">
                                        <i class="fa fa-2x fa-spinner fa-spin mr-2"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="sf-vender-list-wrap mb-4">
                                <div class="sf-vender-list-box p-4">
                                    <div class="py-2 mb-3 text-center align-middle description-div-title">
                                        <strong>INFORMATIONS DE L'ENTREPRISE</strong>
                                    </div>
                                    <div v-if="contrat.entreprise" class="d-flex justify-content-between flex-wrap" >
                                        <div>
                                            <ul class="customer-info">
                                                <li class="d-flex flex-wrap"><strong>Nom de l'Entreprise : </strong>{{ contrat.entreprise.nom_entreprise }}</li>
                                                <li class="d-flex flex-wrap"><strong>Service : </strong> {{ contrat.service.nom_service }}</li>
                                                <li class="d-flex flex-wrap"><strong>Responsable Service : </strong>{{ contrat.service.resp_service }}</li>
                                                <li class="d-flex flex-wrap"><strong>Email : </strong>{{ contrat.service.user.email }}</li>
                                                <li class="d-flex flex-wrap"><strong>Téléphone : </strong>{{ contrat.service.telephone }}</li>
                                            </ul>
                                        </div>
                                        <div style=" border: 1px solid rgba(50, 50, 168); border-radius: 50%; overflow: hidden; position: relative; box-shadow: 0px 0px 50px rgba(112, 66, 225, 0.2); max-width: 100px; max-height: 100px;">
                                            <a  href="javascript:void(0)" class="sf-jobs-media"><img style="max-width: 100px; max-height: 100px;" :src="contrat.entreprise.logo ? BACK_URL + 'storage/logo/' + contrat.entreprise.logo : '/../../../images/placeholder/entreprise-user.jpeg'" alt=""></a>
                                        </div>
                                    </div>
                                    <div v-else class="text-center">
                                        <i class="fa fa-2x fa-spinner fa-spin mr-2"></i>
                                    </div>
                                </div>
                            </div>
                            <div class="sf-vender-list-wrap mb-4">
                                <div class="sf-vender-list-box p-4">
                                    <div class="py-2 mb-3 text-center align-middle description-div-title">
                                        <strong>INFORMATIONS DU PRESTATAIRE</strong>
                                    </div>
                                    <div v-if="contrat.prestataire" class="d-flex justify-content-between flex-wrap" >
                                        <div>
                                            <ul class="customer-info">
                                                <li class="d-flex flex-wrap"><strong>Nom & Prénoms : </strong>{{ contrat.prestataire.nom }} {{ contrat.prestataire.prenoms }}</li>
                                                <li class="d-flex flex-wrap"><strong>Raison Sociale : </strong>{{ contrat.prestataire.raison_sociale  }}</li>
                                                <li class="d-flex flex-wrap"><strong>Email : </strong>{{ contrat.prestataire.user.email  }}</li>
                                                <li class="d-flex flex-wrap"><strong>Téléphone : </strong>{{ contrat.prestataire.telephone }}</li>
                                                <li class="d-flex flex-wrap"><strong>Adresse : </strong>{{ contrat.prestataire.address  }}</li>
                                            </ul>
                                        </div>
                                        <div style=" border: 1px solid rgba(50, 50, 168); border-radius: 50%; overflow: hidden; position: relative; box-shadow: 0px 0px 50px rgba(112, 66, 225, 0.2); max-width: 100px; max-height: 100px;">
                                            <a href="javascript:void(0)" class="sf-jobs-media"><img style="max-width: 100px; max-height: 100px; height: auto; width: auto;" :src="contrat.prestataire.photo ? BACK_URL + 'storage/photoProfile/' + contrat.prestataire.photo : '/../../../images/placeholder/placeholder-user.jpg'" alt=""></a>
                                        </div>
                                    </div>
                                    <div v-else class="text-center">
                                        <i class="fa fa-2x fa-spinner fa-spin mr-2"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>    
            <div v-else class="card-body aon-card-body">
                <div v-if="isDetailsLoading" class="text-center">
                    <i class="fa fa-2x fa-spinner fa-spin mr-2"></i> Chargement
                </div>
                <div v-else class="my-2 alert alert-warning">
                    Les informations n'ont pas pu être chargées. Vérifiez votre connection internet puis réessayez
                </div> 
            </div>
        </div>
        <div
            v-if="isObjetModalOpen" 
            style="
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 1000;
            background-color: rgba(0, 0, 0, 0.5);
            align-items: center;
            justify-content: center;">
            <div class="modal-dialog modal-lg  modal-dialog-centered modal-dialog-popout modal-dialog-scrollable" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">MODIFIER UN OBJET</h5>
                        <button type="button" class="close" @click="closeObjetModal()" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <form id="objetForm" @submit.prevent="updateObjet()" enctype="multipart/form-data">
                        <div class="modal-body">
                            <div class="sf-md-padding">
                                <div class="row">
                                    <div class="col-12">
                                        <div class="form-group">
                                            <label>Intitulé</label>
                                            <textarea class="form-control" rows="3" v-model="objetData.intitule" :class="{'is-invalid':objetFormErrors.intitule}"></textarea>  
                                            <span v-if="objetFormErrors.intitule" class="invalid-feedback">{{ objetFormErrors.intitule[0]}}</span>
                                        </div>
                                    </div>
                                    <div class="col-lg-6">
                                        <div class="form-group">
                                            <label>Prix Unitaire (FCFA)</label>
                                            <input type="number" class="form-control" v-model="objetData.prix_unitaire"
                                                :class="{ 'is-invalid': objetFormErrors.prix_unitaire }">
                                            <span v-if="objetFormErrors.prix_unitaire" class="invalid-feedback">{{ objetFormErrors.prix_unitaire[0] }}</span>
                                        </div>
                                    </div>
                                    <div class="col-lg-6">
                                        <div class="form-group">
                                            <label>Niveau de priorité</label>
                                            <select class="form-control" name="niveau-de-priorite" v-model="objetData.niveau_priorite" :class="{'is-invalid':objetFormErrors.niveau_priorite}">
                                                <option :value = null ></option>
                                                <option value="normal">Normal</option>
                                                <option value="urgent">Urgent</option>
                                                <option value="critique">Critique</option>
                                            </select>
                                            <span v-if="objetFormErrors.niveau_priorite" class="invalid-feedback">{{ objetFormErrors.niveau_priorite[0]}}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="modal-footer d-flex justify-content-end align-items-center">
                            <div>
                                <button type="button" @click="closeObjetModal()" class="btn btn-warning mr-2">Annuler</button>
                                <button type="submit" form="objetForm" class="btn btn-warning" :disabled="isObjetUpdating">
                                    <i v-if="isObjetUpdating" class="fa fa-fw fa-spinner fa-spin mr-1"></i>
                                    <i v-else class="fa fa-save"></i>
                                    {{ isObjetUpdating ? 'Enregistrement...' : 'Enregistrer les modifications' }}
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
.description-div-title{
    background-color: rgba(50, 50, 168, 0.2);
    border: 1px solid rgba(50, 50, 168);
    border-radius: 10px;
    
}

.description-div-title > strong{
    color: #022279;
}
</style>