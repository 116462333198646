<script>
import { useStore, mapGetters } from "vuex";
import { computed, onMounted, ref, watch } from "vue";
import { useRouter } from "vue-router";
import Swal from 'sweetalert2';

export default {
    setup() {
        const store = useStore();
        const router = useRouter();
        const actualRoute = ref(null)

        const userRole = store.getters.getUserRole;
        const isLoggedIn = store.getters.isLoggedIn;

        function showLogoutConfirmation() {
            Swal.fire({
                title: 'Êtes-vous sûr de vouloir vous déconnecter ?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Oui',
                cancelButtonText: 'Non',
                confirmButtonColor: '#e04f1a',
                cancelButtonColor: '#ffb600'

            }).then((result) => {
                if (result.isConfirmed) {
                    logout();
                }
            });
        }

        onMounted(() => {
            if (router.currentRoute.value.name == "accueil") {
                actualRoute.value = 1
            }else if (router.currentRoute.value.name  == "a-propos"){
                actualRoute.value = 2
            }else if (router.currentRoute.value.name == "contact"){
                actualRoute.value = 3
            }else{
                
            }

        });
        watch(router.currentRoute, ()=>{
            if (router.currentRoute.value.name = "accueil") {
                actualRoute.value = 1
            }else if (router.currentRoute.value.name  = "a-propos"){
                actualRoute.value = 2
            }else if (router.currentRoute.value.name ="contact"){
                actualRoute.value = 3
            }else{
                
            }
        })

        function logout() {
            store.dispatch("logout").then(() => {
                router.push({
                    name: "acceuil",
                });
            });
        }

        return {
            user: computed(() => store.state.user.data),
            showLogoutConfirmation,
            logout,
            userRole,
            isLoggedIn,
            actualRoute
        };
    },
}
</script>

<template>
    <header class="site-header header-style-1 mobile-sider-drawer-menu header-full-width">
        <div class="sticky-wrapper" style="height: 90px;">
            <div class="sticky-header main-bar-wraper navbar-expand-lg is-fixed">
                <div class="main-bar">

                    <div class="container clearfix">
                        <!--Logo section start-->
                        <div class="logo-header">
                            <div class="logo-header-inner logo-header-one">
                                <a href="/">
                                    <img src="images/logo-light.png" alt="" class="site-logo-has">
                                    <img src="images/logo-dark.png" alt="" class="site-logo-sticky">
                                </a>
                            </div>
                        </div>
                        <!--Logo section End-->

                        <!-- NAV Toggle Button -->
                        <button id="mobile-side-drawer" data-target=".header-nav" data-toggle="collapse" type="button"
                            class="navbar-toggler collapsed">
                            <span class="sr-only">Toggle navigation</span>
                            <span class="icon-bar icon-bar-first"></span>
                            <span class="icon-bar icon-bar-two"></span>
                            <span class="icon-bar icon-bar-three"></span>
                        </button>

                        <!-- MAIN Vav -->
                        <div class="nav-animation header-nav navbar-collapse collapse d-flex justify-content-start">

                            <ul class=" nav navbar-nav">
                                <li :class="{'has-child current-menu-item' : actualRoute == 1}">
                                <a href="/">ACCEUIL</a>
                              </li>
                              <li :class="{'has-child current-menu-item' : actualRoute == 2}">
                                <a href="/a-propos">A PROPOS</a>                             
                              </li>
                              <li :class="{'has-child current-menu-item' : actualRoute == 3}">
                                <a href="/contact">CONTACTEZ-NOUS</a>                                                               
                              </li>

                            </ul>

                        </div>

                        <!-- Header Right Section-->
                        <div class="extra-nav header-2-nav">
                            <div class="extra-cell">
                                <button type="button" class="site-button aon-btn-login" data-toggle="modal"
                                    data-target="#login-signup-model" v-if="!isLoggedIn">
                                    <i class="fa fa-user"></i>CONNEXION
                                </button>
                                <button type="button" class="site-button aon-btn-login" data-toggle="modal"
                                    @click="showLogoutConfirmation" v-if="isLoggedIn">
                                    <i class="fa fa-power-off"></i>DECONNEXION
                                </button>
                                <a href="/admin" class="site-button aon-btn-signup m-l20"
                                    v-if="isLoggedIn && userRole === 'admin'">
                                    <i class="fa fa-dashboard"></i> TABLEAU DE BORD 
                                </a>
                                <a href="/prestataire" class="site-button aon-btn-signup m-l20"
                                    v-if="isLoggedIn && userRole === 'prestataire'">
                                    <i class="fa fa-dashboard"></i> TABLEAU DE BORD 
                                </a>
                                <a href="/entreprise" class="site-button aon-btn-signup m-l20"
                                    v-if="isLoggedIn && userRole === 'entreprise'">
                                    <i class="fa fa-dashboard"></i> TABLEAU DE BORD 
                                </a>
                                <a href="/service" class="site-button aon-btn-signup m-l20"
                                    v-if="isLoggedIn && userRole === 'service'">
                                    <i class="fa fa-dashboard"></i> TABLEAU DE BORD 
                                </a>
                            </div>
                        </div>

                    </div>

                </div>
            </div>
    </div>
</header></template>

<style></style>