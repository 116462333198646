<template>
  
    <footer class="site-footer footer-light">
  
  <!-- FOOTER NEWSLETTER START -->
  
  <!-- FOOTER BLOCKES START -->
  <div class="footer-top">
    <div class="container">
      <div class="row">
        <!-- Footer col 1-->
        <div class="col-lg-3 col-md-6 col-sm-6  m-b30">
          <div class="sf-site-link sf-widget-link">
            <h4 class="sf-f-title">KATUFAI</h4>
            <ul>
              <li><a href="#">La plateforme digitale des prestations de service.</a></li>
            </ul>
             

          </div>
        </div>
        <!-- Footer col 2-->
        <div class="col-lg-3 col-md-6 col-sm-6  m-b30">
          <div class="sf-site-link sf-widget-cities">
            <h4 class="sf-f-title">LIEN RAPIDE</h4>
            <ul>
              <li><a href="/">Accueil</a></li>
              <li><a href="/contact">Contactez-nous</a></li>
              <li><a href="/a-propos">A Propos</a></li>
            </ul>
          </div>
        </div>
        <!-- Footer col 1-->
        <div class="col-lg-3 col-md-6 col-sm-6  m-b30">
          <div class="sf-site-link sf-widget-categories">
            <h4 class="sf-f-title">POLITIQUE</h4>
            <ul>
              <li><a href="">Politique de confidentialité</a></li>
            </ul>
          </div>
        </div>
        <!-- Footer col 1-->
        <div class="col-lg-3 col-md-6 col-sm-6  m-b30">
          <div class="sf-site-link sf-widget-contact">
            <h4 class="sf-f-title">CONTACTS</h4>
            <ul>
              <li>Benin</li>
              <li>+229 97724561</li>
              <li>contact@katufai.com</li>
            </ul>
          </div>
        </div>
  
      </div>
    </div>
  </div>
  <!-- FOOTER COPYRIGHT -->
  <div class="footer-bottom">
    <div class="container">
      <div class="sf-footer-bottom-section">
        <div class="sf-f-logo"><a href="javascript:void(0);"><img src="images/logo-dark.png" alt=""></a></div>
        <div class="sf-f-copyright">
          <span>Copyright 2024 | nero-technologie. Tous droits réservés</span>
        </div>
  
        <div class="sf-f-social">
          <ul class="socila-box">
            <li><a href="javascript:void(0);"><i class="fa fa-twitter"></i></a></li>
            <li><a href="javascript:void(0);"><i class="fa fa-facebook"></i></a></li>
            <li><a href="javascript:void(0);"><i class="fa fa-linkedin"></i></a></li>
            <li><a href="javascript:void(0);"><i class="fa fa-instagram"></i></a></li>
          </ul>
        </div>
  
      </div>
    </div>
  </div>
  
  </footer>
  
  </template>
  
  <script>
  export default {
  
  }
  </script>
  
  <style>
  
  </style>