<script setup>
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { computed, onMounted, reactive, ref } from "vue";
import { BACK_URL } from "@/constant";
import Swal from 'sweetalert2';
import axiosClient from "@/axios";

    const store = useStore();
    const router = useRouter();
    const prestataire = computed(() => store.state.prestataires);
    const errorMessage = ref('');
    const loading = ref(false);

    const currentPage = ref(1);
    const itemsPerPage = 20;

    const searchNom = ref('');
    const searchPrenoms = ref('');
    const searchRaisonSociale = ref('');
    const searchTelephone = ref('');

    const prestataireData = reactive({
        nom : '',
        prenoms : '',
        raison_sociale : '',
        telephone : '',
        address : '',
        email : '',
    })

    const fetchPage = async (page) => {
        await store.dispatch('fetchPrestataire', page);
        currentPage.value = page;
    };

    onMounted(() => {
        fetchPage(currentPage.value);
    });

    const selectedPrestataire = ref(null);
    const selectedrestataireContrat = ref([]);

    const filteredPretataire = computed(() => {
        if (!Array.isArray(prestataire.value)) return [];
            return prestataire.value.filter(prestatair => {
                const nomMatch = prestatair.nom.toLowerCase().includes(searchNom.value.toLowerCase());
                const PrenomsMatch = prestatair.prenoms.toLowerCase().includes(searchPrenoms.value.toLowerCase());
                const RaisonSocialeMatch = prestatair.raison_sociale.toLowerCase().includes(searchRaisonSociale.value.toLowerCase());
                return nomMatch && PrenomsMatch && RaisonSocialeMatch;
        });
    });

    const totalDataItems = computed(() => filteredPretataire.value.length);
    const totalPages = computed(() => Math.ceil(totalDataItems.value / itemsPerPage));
    const hasNextPage = computed(() => currentPage.value < totalPages.value);
    const visiblePageNumbers = computed(() => {
        const pages = [];
        for (let i = 1; i <= totalPages.value; i++) {
            pages.push(i);
        }
        return pages;
    });

    const clearFilters = () => {
        searchNom.value = '';
        searchPrenoms.value = '';
        searchRaisonSociale.value = '';
        searchTelephone.value = '';
    };

    const deletePrestataire = async (prestataireId) => {
        const result = await Swal.fire({
            icon: 'question',
            title: 'Confirmation',
            text: 'Êtes-vous sûr de vouloir supprimer ce prestataire ?',
            confirmButtonColor:  '#022279', //'#ffc107',
            cancelButtonColor: '#d33',
            showCloseButton: true,
            showCancelButton: true,
            confirmButtonText: 'Continuer',
            cancelButtonText: 'Annuler',
        });

        if (result.isConfirmed) {
            axiosClient.delete(`/prestataire/${prestataireId}`)
                .then((res) => {
                    if (res.status == 204) {
                        store.dispatch('fetchPrestataire')
                            .then(() =>{
                                Swal.fire({
                                    icon: 'success',
                                    title: 'Le prestataire a été supprimé avec succès',
                                }); 
                            })
                    }
                }).catch(error => {
                    if(error.response.status == 403){
                        Swal.fire({
                            title: 'Attention',
                            text: 'Ce prestataire est lié à au moins un contrat. Il ne peut donc être supprimer',
                            icon: 'warning',
                            confirmButtonColor: '#022279', //'#ffc107',
                            confirmButtonText: 'OK'
                        });
                    }else{
                        Swal.fire({
                            title: 'Erreur',
                            text: 'Un problème est survenu lors de la suppresion du prestataire, vérifiez votre connexion internet puis réessayez',
                            icon: 'error',
                            confirmButtonColor: '#022279', //'#ffc107',
                            confirmButtonText: 'OK'
                        });
                    }
                });
        }
    };

    const updatePrestataire = async () => {
        const result = await Swal.fire({
            icon: 'question',
            title: 'Confirmation',
            text: 'Êtes-vous sûr de vouloir modifier ce prestataire ?',
            confirmButtonColor:  '#022279', //'#ffc107',
            cancelButtonColor: '#d33',
            showCloseButton: true,
            showCancelButton: true,
            confirmButtonText: 'Continuer',
            cancelButtonText: 'Annuler',
        });

        if (result.isConfirmed) {
            loading.value = true;
            const formData = new FormData();
            formData.append('nom', prestataireData.nom);
            formData.append('prenoms', prestataireData.prenoms);
            formData.append('raison_sociale', prestataireData.raison_sociale);
            formData.append('email', prestataireData.email);
            formData.append('telephone', prestataireData.telephone);
            formData.append('address', prestataireData.address);
            formData.append('_method', 'put');
            axiosClient.post(`/prestataire/${selectedPrestataire.value.id}`, formData)
                .then((res)=>{
                    console.log(res);
                    if (res.status == 201) {
                        $('#edit_prestataire').modal('hide'); 
                        Swal.fire({
                            title: 'Mise à jour réussie',
                            text: 'Le prestataire a été modifié avec succès',
                            icon: 'success',
                            confirmButtonColor: '#022279', //'#ffc107',
                            confirmButtonText: 'OK'
                        }).finally(()=>{
                            store.dispatch('fetchPrestataire');
                        })
                    }
                }).catch((error) => {
                    if (error.response.status == 422) {
                        errorMessage.value =  error.response.data.errors;
                    }else{
                        Swal.fire({
                            title:'Erreur',
                            text: 'Echec de la mise à jour du prestataire',
                            icon: 'error',
                            confirmButtonColor: '#022279', //'#ffc107',
                            confirmButtonText: 'OK'
                        });
                    }

                }).finally(() => {   
                    loading.value = false;    
                })
        }
    };

    const showContratsModal = (prestatair) => {
        selectedPrestataire.value = prestatair;
        selectedrestataireContrat.value = prestatair.contrats; 
        $('#contratModal').modal('show'); 
    };

    const showEditPrestataireModal = (prestataire) => {
        selectedPrestataire.value = prestataire
        prestataireData.nom = prestataire.nom;
        prestataireData.prenoms = prestataire.prenoms;
        prestataireData.raison_sociale = prestataire.raison_sociale;
        prestataireData.address = prestataire.address;
        prestataireData.email = prestataire.user.email;
        prestataireData.telephone = prestataire.telephone;
        $('#edit_prestataire').modal('show'); 
    };

    function goBack() {
        router.back();
    }

    function showPrestataire(prestataire){
    Swal.fire({
        imageUrl: `${ prestataire.photo ? BACK_URL + 'storage/photoProfile/' + prestataire.photo : '/../../../images/placeholder/placeholder-user.jpg'}`,
        imageWidth: 400,
        imageHeight: 400,
        showCloseButton: true,
        showConfirmButton: false,
        imageAlt: `${prestataire.photo ? BACK_URL + 'storage/photoProfile/' + prestataire.photo : '/../../../images/placeholder/placeholder-user.jpg'}`,
        title: `${prestataire.nom + ' ' + prestataire.prenoms}`,
    })

}
</script>

<template>
    <div>
        <div class="aon-admin-heading">
            <div class="d-flex justify-content-start align-items-center">
                <button class="btn btn-light btn-lg mr-2" type="button" @click="goBack()">
                    <i class="feather-arrow-left"></i>
                </button>
                <h3 class="text-center">PRESTATAIRES</h3>
            </div>
        </div>
        <div class="card aon-card">
            <div class="card-header aon-card-header">
                <div class="aon-card-header aon-blog-style-1 d-flex justify-content-between align-items-center flex-wrap" style="background-color: rgba(50, 50, 168, 0.2); border: 1px solid rgba(50, 50, 168, 0.5); border-radius: 10px;">
                    <div class="py-2">
                        <h4><i class="fa fa-list"></i> LISTE DES PRESTATAIRES</h4>
                    </div>
                    <div>
                        <button class="admin-button-secondry" data-toggle="modal" data-target="#add_prestataire" type="button">
                            <i class="fa fa-plus"></i>
                            AJOUTER PRESTATAIRE
                        </button>
                    </div> 
                </div>
            </div>
            <div class="card-body aon-card-body">
                <div class="pb-3 px-3 mb-4" style="background-color: rgba(50, 50, 168, 0.2); border: 1px solid rgba(50, 50, 168, 0.5); border-radius: 10px;">
                    <div class="py-3 d-flex justify-content-between align-items-center flex-wrap">
                        <h5>RECHERCHER UN PRESTATAIRE</h5>
                        <button class="btn btn-secondary" @click="clearFilters">Effacer les filtres</button>
                    </div>
                    <!-- Search inputs -->
                    <div class="row">
                        <div class="col-lg-4 form-group">
                            <input class="form-control" type="text" v-model="searchNom" placeholder="RECHERCHE PAR NOM">
                        </div>
                        <div class="col-lg-4 form-group">
                            <input class="form-control" type="text" v-model="searchPrenoms" placeholder="RECHERCHE PAR PRENOMS">
                        </div>
                        <div class="col-lg-4 form-group">
                            <input class="form-control" type="text" v-model="searchRaisonSociale" placeholder="RECHERCHE PAR RAISON SOCIALE">
                        </div>
                    </div>
                </div> 
                <div class="sf-bs-data-table">
                    <div class="table-responsive">
                        <table class="table table-striped table-bordered " style="width:100%">
                            <thead >
                                <tr class="bg-warning">
                                    <th class="text-center align-middle">N°</th>
                                    <th class="text-start align-middle">NOM & PRÉNOMS</th>
                                    <th class="text-start align-middle">RAISON SOCIALE</th>
                                    <th class="text-start align-middle">PHOTO</th>
                                    <th class="text-center align-middle">ADRESSE</th>
                                    <th class="text-center align-middle">TELEPHONE</th>
                                    <th class="text-center align-middle">EMAIL</th>
                                    <th class="text-center align-middle">ACTIONS</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-if="prestataire.length === 0">
                                    <td colspan="6" class="text-center">Pas de données disponibles</td>
                                </tr>
                                <tr v-for="(prestatair, index) in filteredPretataire" :key="index">
                                    <td class="text-center align-middle">{{ (currentPage- 1)*(itemsPerPage) + (index +1) }}</td>
                                    <td class="text-start align-middle">{{ prestatair.nom }} {{ prestatair.prenoms }}</td>
                                    <td class="text-start align-middle">{{ prestatair.raison_sociale }}</td>
                                    <td class="text-center align-middle">
                                        <a href="javascript:void(0)" @click="showPrestataire(prestatair)">
                                            <img class="company_logo aon-icon" :src="prestatair.photo ? BACK_URL + 'storage/photoProfile/' + prestatair.photo : '/../../../images/placeholder/placeholder-user.jpg'" alt="" style="height: 50px; width: 50px;">
                                        </a>
                                    </td>
                                    <td class="text-center align-middle">{{prestatair.address}}</td>
                                    <td class="text-center align-middle">{{prestatair.telephone}}</td>
                                    <td class="text-center align-middle">{{prestatair.user ? prestatair.user.email : 'NULL'}}</td>
                                    <td class="text-center align-middle"> 
                                        <button class="btn btn-primary m-1" type="button" @click="showContratsModal(prestatair)">
                                            <i class="fa fa-eye"></i>
                                        </button>
                                        <button class="btn btn-warning m-1" @click="showEditPrestataireModal(prestatair)" type="button">
                                            <i class="fa fa-edit"></i>
                                        </button>
                                        <button @click="deletePrestataire(prestatair.id)" class="btn btn-danger m-1" type="button">
                                            <i class="fa fa-trash-o"></i>
                                        </button>
                                    </td>
                                </tr>                                           
                            </tbody>
                        </table>
                    </div>
                </div>
                <nav aria-label="Page navigation">
                    <ul class="pagination justify-content-end"> 
                        <li class="page-item" :class="{ disabled: currentPage === 1 }">
                            <button class="page-link" @click="fetchPage(currentPage - 1)" :disabled="currentPage === 1">
                            Précédent
                            </button>
                        </li>
                        <li class="page-item" v-for="pageNumber in visiblePageNumbers" :key="pageNumber" :class="{ active: currentPage === pageNumber }">
                            <button class="page-link" @click="fetchPage(pageNumber)">
                            {{ pageNumber }}
                            </button>
                        </li>
                        <li class="page-item" :class="{ disabled: !hasNextPage }">
                            <button class="page-link" @click="fetchPage(currentPage + 1)" :disabled="!hasNextPage">
                                Suivant
                            </button>
                        </li>
                    </ul>
                </nav>
            </div>
            <!-- Modal services -->
            <div class="modal fade" id="contratModal" tabindex="-1" role="dialog" aria-labelledby="contratModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-lg" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title text-center" id="servicesModalLabel ">Liste des contrats de M/Mme {{ selectedPrestataire ? selectedPrestataire.nom : '' }} {{ selectedPrestataire ? selectedPrestataire.prenoms : '' }}</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <div v-if="selectedrestataireContrat.length > 0">
                                <table class="table table-striped table-bordered " style="width:100%">
                                    <thead >
                                        <tr class="bg-warning">
                                            <th class="text-center">N°</th>
                                            <th class="text-center">DATE DEBUT</th>
                                            <th class="text-center">DATE FIN</th>
                                            <th class="text-center">ACTIONS</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(contrat, index) in selectedrestataireContrat" :key="index">
                                            <td class="text-center">{{index+1}}</td>
                                            <td class="text-center">{{contrat.date_debut}}</td>
                                            <td class="text-center">{{ contrat.date_fin }}</td>
                                            <td class="text-center"> 
                                                <button class="btn btn-danger" type="button" >
                                                    <i class="fa fa-trash-o"></i>
                                                </button>
                                            </td>
                                        </tr>                                          
                                    </tbody>
                                </table>
                            </div>
                            <div v-else class="alert alert-warning m-auto text-center" role="alert">
                                <p class="fs-5 m-auto">Aucun contrat disponible. </p> 
                            </div>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" data-dismiss="modal">Fermer</button>
                        </div>
                    </div>
                </div>
            </div>

            <div class="modal fade content-admin-main" id="edit_prestataire" tabindex="-1" role="dialog" aria-hidden="true" data-backdrop="static" data-keyboard="false">
                <div class="modal-dialog model-w800" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLabel2">MODIFIER PRESTATAIRE</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <form @submit.prevent="handleSubmit" >
                            <div class="modal-body">
                                <div class="sf-md-padding">
                                    <div class="row">
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label>NOM <span class="text-danger">*</span></label>
                                                <div class="aon-inputicon-box">
                                                    <input class="form-control sf-form-control" name="nom" type="text" placeholder="NOM" v-model="prestataireData.nom">
                                                    <i class="aon-input-icon fa fa-user"></i>
                                                </div>
                                                <div class="text-danger" v-if="errorMessage.nom">{{ errorMessage.nom[0] }}</div>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label>PRENOMS <span class="text-danger">*</span></label>
                                                <div class="aon-inputicon-box">
                                                    <input class="form-control sf-form-control" name="prenoms" type="text" placeholder="PRENOMS" v-model="prestataireData.prenoms">
                                                    <i class="aon-input-icon fa fa-user"></i>
                                                </div>
                                                <div class="text-danger" v-if="errorMessage.prenoms">{{ errorMessage.prenoms[0] }}</div>
                                            </div>
                                        </div>
                                                
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label>EMAIL <span class="text-danger">*</span></label>
                                                <div class="aon-inputicon-box">
                                                    <input class="form-control sf-form-control" name="email"  type="text" placeholder="EMAIL" v-model="prestataireData.email">
                                                    <i class="aon-input-icon fa fa-envelope-o"></i>
                                                </div>
                                                <div class="text-danger" v-if="errorMessage.email">{{ errorMessage.email[0] }}</div>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label>TELEPHONE <span class="text-danger">*</span></label>
                                                <div class="aon-inputicon-box">
                                                    <input class="form-control sf-form-control" name="telephone" type="text" placeholder="TELEPHONE" v-model="prestataireData.telephone">
                                                    <i class="aon-input-icon fa fa-phone"></i>
                                                </div>
                                                <div class="text-danger" v-if="errorMessage.telephone">{{ errorMessage.telephone[0] }}</div>
                                            </div>
                                        </div>
                                    
                                        <div class="col-md-12">
                                            <div class="form-group">
                                                <label>RAISON SOCIALE <span class="text-danger">*</span></label>
                                                <div class="aon-inputicon-box">
                                                    <textarea class="form-control sf-form-control" name="raison_sociale" cols="20" rows="3" v-model="prestataireData.raison_sociale" ></textarea>
                                                </div>
                                                <div class="text-danger" v-if="errorMessage.raison_sociale">{{ errorMessage.raison_sociale[0] }}</div>
                                            </div>
                                        </div>

                                        <div class="col-md-12">
                                            <div class="form-group">
                                                <label>ADRESSE <span class="text-danger">*</span></label>
                                                <div class="aon-inputicon-box">
                                                    <textarea class="form-control sf-form-control" name="address" cols="20" rows="3" v-model="prestataireData.address"></textarea>
                                                </div>
                                                <div class="text-danger" v-if="errorMessage.address">{{ errorMessage.address[0] }}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="modal-footer">
                                <button type="button" class="admin-button" data-dismiss="modal">FERMER</button>
                                <button type="submit" @click="updatePrestataire()" class="admin-button" :disabled="loading">
                                    <i v-if="loading" class="fa fa-fw fa-spinner fa-spin mr-1"></i> 
                                    <i v-else class="fa fa-save"></i> ENREGISTRER LES MODIFICATIONS
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style>
</style>