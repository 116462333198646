<script>
import axios from '@/axios';
import { BACK_URL } from '@/constant';
import { reactive } from 'vue';
import { useStore } from 'vuex';
import axiosClient from '@/axios';
import Swal from 'sweetalert2';
import { useSwalSuccess, useSwalError } from '@/composables/alert';

export default {
    data() {
        return {
            store:useStore(),
            contracts: [],
            currentPage: 1,
            totalPages: 1,
            nom: '',
            prenoms: '',
            raison_sociale: '',
            BACK_URL,
            isObjetModalOpen: false,
            isContratModalOpen: false,
            selectedContratId: null,
            isEdition: false,
            isObjetSaving: false,
            isContratUpdating: false,
            isSearching: false,
            axiosClient,
            services: [],
            fileSelected : false,
            uploadProgress :0,

            objetData : reactive({
                intitule: '',
                prix_unitaire: '',
                niveau_priorite: '',
                contrat_id: '',
            }),

            contratData : reactive({
                references: '',
                date_debut: '',
                date_fin: '',
                termes_contrat: '',
                service_id: '',
                entreprise_id: '',
            })
        };
    },
    computed: {

        contratFormErrors(){
            return this.store.state.prestataireModule.contratErrors;
        },

        objetFormErrors(){
            return this.store.state.prestataireModule.objetErrors;
        },

        isContratCreated(){
            return this.store.state.prestataireModule.isContratCreated;
        },

        isObjetCreated(){
            return this.store.state.prestataireModule.isObjetCreated;
        },

        entreprises (){
            return this.store.state.entrepriseModule.entreprisesWithServices;
        },

        getServices (){
            if (!this.contratData.entreprise_id) return [];
                const selectedEntreprise = this.entreprises.find(ent => ent.id === this.contratData.entreprise_id);
            if (selectedEntreprise) {
              this.services = selectedEntreprise.services
              return this.services;
            }
            return [];
        }
    },

    created() {
        this.fetchContracts();
    },
    
    methods: {

        nouvelObjet(contratId){
            this.isObjetModalOpen = true;
            this.objetData.contrat_id = contratId;
        },

        nouveauContrat(){
            this.isContratModalOpen = true;
            this.store.dispatch('entrepriseModule/fetchEntreprisesWithServices')
        },

        closeContratModal(){
            this.isContratModalOpen = false;
            this.resetContratForm();
        },

        closeObjetModal(){
            this.isObjetModalOpen = false;
            this.resetObjetForm();
        },

        clearFilters(){
            this.nom = '';
            this.prenoms = '';
            this.raison_sociale = '';
            this.searchContracts();
        },

        resetObjetForm(){
            this.store.commit('prestataireModule/setObjetErrors', {});
            this.objetData.intitule = '',
            this.objetData.prix_unitaire = '',
            this.objetData.niveau_priorite = ''
            this.objetData.contrat_id = ''
        },

        resetContratForm(){
            this.store.commit('prestataireModule/setContratErrors', {});
            this.contratData.references = '',
            this.contratData.date_debut = '',
            this.contratData.date_fin = '',
            this.contratData.termes_contrat = '',
            this.contratData.service_id = '',
            this.contratData.entreprise_id = ''
        },

        termesInputChanged(e){
            const files = e.target.files
            if (!files || !files.length) return
            this.contratData.termes_contrat = files[0];
            this.fileSelected = true;

            // Simulation de chargement du fichier (remplacez cela par votre logique de chargement réel)
            let progress = 0;
              const interval = setInterval(() => {
                progress += 10;
                this.uploadProgress = progress;
                if (progress >= 100) {
                  clearInterval(interval);
                }
            }, 50);
        },

        async fetchContracts() {
            try {
                const params = {
                    nom: this.nom,
                    prenoms: this.prenoms,
                    raison_sociale :this.raison_sociale
                };

                const response = await axios.get('/contrats-pour-service', { params });
                if (response.status === 200 && response.data && response.data.service) {
                    this.contracts = response.data.service.data;
                    this.totalPages = response.data.service.last_page;
                } else {
                    console.error('Les données de contrats reçues ne sont pas valides :', response.data);
                }
            } catch (error) {
                console.error('Erreur lors de la récupération des contrats :', error);
            }
        },

        saveObjet() {
            this.isObjetSaving = true;
            const formData = new FormData();
            formData.append('intitule', this.objetData.intitule);
            formData.append('prix_unitaire', this.objetData.prix_unitaire);
            formData.append('niveau_priorite', this.objetData.niveau_priorite);
            formData.append('contrat_id', this.objetData.contrat_id);
            this.store.dispatch('prestataireModule/createObjet', formData)
            .then(()=>{
                if (this.isObjetCreated == true) {
                    this.closeObjetModal()
                    Swal.fire({
                        title: 'Enregistrement réussi',
                        text: 'Le nouvel objet a été enregistré avec succès',
                        icon: 'success',
                        confirmButtonColor: '#022279', //'#ffc107',
                        confirmButtonText: 'OK'
                    }).finally(()=>{
                       this.store.commit('prestataireModule/setIsObjetCreated', false);
                    });
                }
            }).catch((error) =>{
                Swal.fire({
                    title: 'Erreur',
                    text: 'Echec de l\'enregistrement de l\'objet',
                    icon: 'error',
                    confirmButtonColor: '#022279', //'#ffc107',
                    confirmButtonText: 'OK'
                });
            }).finally(() => {   
                this.isObjetSaving = false;   
            })
        },

        editContrat(contrat){
            this.selectedContratId = contrat.id;
            this.isEdition = true;
            this.store.dispatch('entrepriseModule/fetchEntreprisesWithServices');
            this.contratData.references = contrat.references
            this.contratData.date_debut = contrat.date_debut
            this.contratData.date_fin = contrat.date_fin
            this.contratData.entreprise_id = contrat.entreprise_id
            this.contratData.service_id = contrat.service_id
            this.contratData.prestataire_id = contrat.prestataire_id
            this.isContratModalOpen = true;
        },

        updateContrat(){
            Swal.fire({
            title: 'Êtes-vous sûr?',
            text: "Les modifications seront enregistées ensuite",
            icon: 'question',
            showCloseButton: true,
            showCancelButton: true,
            confirmButtonColor:  '#022279', //'#ffc107',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Continuer',
            cancelButtonText: 'Annuler'
            }).then((result) => {
                if (result.isConfirmed) {
                    if (this.selectedContratId != null) {
                        this.isContratUpdating = true;
                        const formData = new FormData();
                        formData.append('references', this.contratData.references);
                        formData.append('date_debut', this.contratData.date_debut);
                        formData.append('date_fin', this.contratData.date_fin);
                        formData.append('termes_contrat', this.contratData.termes_contrat);
                        formData.append('entreprise_id', this.contratData.entreprise_id);
                        formData.append('service_id', this.contratData.service_id);
                        formData.append('_method', 'put');
                        axiosClient.post(`/contrats/${this.selectedContratId}`, formData)
                            .then((res)=>{
                                if (res.status = 201) {
                                    this.closeContratModal();
                                    Swal.fire({
                                        title: 'Mise à jour réussie',
                                        text: 'Le contrat a été modifié avec succès',
                                        icon: 'success',
                                        confirmButtonColor: '#022279', //'#ffc107',
                                        confirmButtonText: 'OK'
                                    }).finally(()=>{
                                        this.fetchContracts()
                                    })
                                }
                            }).catch((error) => {
                                Swal.fire({
                                    title:'Erreur',
                                    text: 'Echec de la mise à jour du contrat',
                                    icon: 'error',
                                    confirmButtonColor: '#022279', //'#ffc107',
                                    confirmButtonText: 'OK'
                                });
                                if (error.response.status == 422) {
                                    this.store.commit('prestataireModule/setContratErrors', error.response.data.errors);
                                }
                            }).finally(() => {   
                                this.isContratUpdating = false;    
                            })
                    }
                }
            });
        },

        deleteContrat(id){
            Swal.fire({
            title: 'Êtes-vous sûr?',
            text: "Ce contrat sera supprimé ensuite!",
            icon: 'question',
            showCloseButton: true,
            showCancelButton: true,
            confirmButtonColor:'#022279', //'#ffc107',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Continuer',
            cancelButtonText: 'Annuler'
            }).then((result) => {
                if (result.isConfirmed) {
                    axiosClient.delete(`/contrats/${id}`)
                        .then((res) => {
                            if (res.status == 204) {
                                this.store.dispatch('prestataireModule/fetchContrats',{ enterpriseQuery: enterpriseQuery, serviceQuery: serviceQuery.value, referencesQuery: referencesQuery.value })
                                    .then(() =>{
                                        useSwalSuccess(`Le contrat a été supprimé avec succès`);
                                    })
                            }
                       }).catch(error => {
                            if(error.response.status == 403){
                                Swal.fire({
                                    title: 'Attention',
                                    text: 'Ce contrat contient au moins une tâche. Il ne peut donc être supprimé.',
                                    icon: 'warning',
                                    confirmButtonColor: '#022279', //'#ffc107',
                                    confirmButtonText: 'OK'
                                });
                            }else{
                                Swal.fire({
                                    title: 'Erreur',
                                    text: 'Un problème est survenu lors de la suppresion, vérifiez votre connexion internet puis réessayez',
                                    icon: 'error',
                                    confirmButtonColor: '#022279', //'#ffc107',
                                    confirmButtonText: 'OK'
                                });
                            }
                       });
                } 
            })   
        },

        searchContracts() {
            this.isSearching = true;
            this.currentPage = 1;
            this.fetchContracts()
            .finally(() => {
                this.isSearching = false;
            });
        },
        
        previousPage() {
            if (this.currentPage > 1) {
                this.currentPage--;
                this.fetchContracts();
            }
        },
        nextPage() {
            if (this.currentPage < this.totalPages) {
                this.currentPage++;
                this.fetchContracts();
            }
        },

        goBack(){
            this.$router.back();
        }
    }
}
</script>

<template>
    <div class="aon-admin-heading">
        <div class="d-flex justify-content-start align-items-center">
            <button class="btn btn-light btn-lg mr-2" type="button" @click="goBack()">
                <i class="feather-arrow-left"></i>
            </button>
            <h3 class="text-center">CONTRATS</h3>
        </div>
    </div>
    <div class="card aon-card">
        <div class="card-header aon-card-header">
                <div class="aon-card-header aon-blog-style-1 d-flex justify-content-between align-items-center flex-wrap" style="background-color: rgba(50, 50, 168, 0.2); border: 1px solid rgba(50, 50, 168, 0.5); border-radius: 10px;">
                    <div>
                        <h4><i class="fa fa-list"></i> LISTE DES CONTRATS</h4>
                    </div>
                </div>
            </div>
        <div class="card-body aon-card-body">
            <div class="pb-3 px-3 mb-4" style="background-color: rgba(50, 50, 168, 0.2); border: 1px solid rgba(50, 50, 168, 0.5); border-radius: 10px;">
                <div class="py-3 d-flex justify-content-between align-items-center flex-wrap">
                    <h5>RECHERCHER UN CONTRAT</h5>
                    <button class="btn btn-secondary" @click="clearFilters">Effacer les filtres</button>
                </div>
                <div class="row">
                    <div class="col-lg-4">
                        <div class="form-group">
                            <input type="text" class="form-control" placeholder="NOM PRESTATAIRE" v-model="nom" @input="searchContracts">
                        </div>
                    </div>
                    <div class="col-lg-4">
                        <div class="form-group">
                            <input type="text" class="form-control" placeholder="PRÉNOMS PRESTATAIRE" v-model="prenoms" @input="searchContracts">
                        </div>
                    </div>
                    <div class="col-lg-4">
                        <div class="form-group">
                            <input type="text" class="form-control" placeholder="RAISON SOCIALE" v-model="raison_sociale" @input="searchContracts">
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="isSearching" class="text-center align-middle">
                <i class="fa fa-2x fa-spinner fa-spin mr-2"></i> Filtrage en cours...
            </div>
        <div class="sf-bs-data-table mt-2">
            <div class="table-responsive">
                <table class="table table-striped table-bordered" style="width:100%">
                    <thead class="bg-warning">
                        <tr>
                            <th class="text-center align-middle" style="font-size: larger;">PRESTATAIRE</th>
                            <th class="text-center align-middle" style="font-size: larger;">DESCRIPTION</th>
                            <th class="text-center align-middle" style="font-size: larger;">STATUT</th>
                            <th class="text-center align-middle" style="font-size: larger;">ACTIONS</th>
                        </tr>
                    </thead>

                    <tbody>
                        <tr v-for="contract in contracts" :key="contract.id">
                            <td class="text-center align-middle">
                                <div class="job-comapny-logo">
                                    <img class="company_logo aon-icon" style="max-width: 100px; max-height: 100px;" :src="contract.prestataire.photo ? BACK_URL + 'storage/photoProfile/' + contract.prestataire.photo : '/../../../images/placeholder/placeholder-user.jpg'" alt="">
                                </div>
                            </td>
                            <td>
                                <div class="sf-booking-info-col">
                                    <div class="sf-booking-customer">
                                        <ul class="customer-info">
                                            <li>
                                                <div class="description-div-title"><strong>RAISON SOCIALE</strong></div>
                                                <strong class="seperator">:</strong> 
                                                <div class="description-div-value"><strong>{{ contract.prestataire.raison_sociale }}</strong></div>
                                            </li>
                                            <li>
                                                <div class="description-div-title"><strong>NOM & PRÉNOMS DU PRESTATAIRE</strong></div>
                                                <strong class="seperator">:</strong>
                                                <div class="description-div-value"><strong>{{ contract.prestataire.nom }} {{ contract.prestataire.prenoms }}</strong></div>
                                            </li>
                                            <li>
                                                <div class="description-div-title"><strong>NUMÉRO DU PRESTATAIRE</strong></div>
                                                <strong class="seperator">:</strong>
                                                <div class="description-div-value"><strong>{{ contract.prestataire.telephone }}</strong></div>
                                            </li>
                                        </ul>
                                    </div>
                                    <a type="button" class=" btn admin-button mr-2 my-1"
                                        :href="'/service/contrats/details/' + contract.id">
                                        <i class="fa fa-eye"></i>
                                        Voir plus
                                    </a>
                                    <button  type="button" class="btn admin-button-info mr-2 my-1" @click="nouvelObjet(contract.id)">
                                        <i class="fa fa-plus"></i> AJOUTER UN OBJET
                                    </button>
                                    <a type="button" class="btn admin-button-secondry my-1"
                                        :href="`/service/contrats/${contract.id}/taches`">
                                        <i class="fa fa-plus"></i>
                                        Ajouter une tâche
                                    </a>
                                </div>
                            </td>
                            <td class="text-center align-middle">
                                <div class="inner">
                                    <h3>
                                        <span v-if="contract.statut == 'actif'" class="badge badge-success">{{
                                            contract.statut }}</span>
                                        <span v-if="contract.statut == 'terminee'" class="badge badge-danger">{{
                                            contract.statut }}</span>
                                    </h3>
                                </div>
                            </td>
                            <td class="text-center align-middle">
                                <button @click="editContrat(contract)" type="button" class="btn btn-warning m-2" title="Éditer">
                                    <i class="fa fa-edit"></i>
                                </button> 
                                <button @click="deleteContrat(contract.id)" type="button" class="btn btn-danger" title="Supprimer">
                                    <i class="fa fa-trash"></i>
                                </button>
                            </td>
                        </tr>
                        <tr v-if="contracts.length === 0">
                            <td colspan="4" class="text-center align-middle">
                                <div class="alert alert-warning" role="alert">
                                    Aucun contrat retrouvé
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>

                <div class="d-flex justify-content-between align-items-center">
                    <button class="btn sm:btn-sm btn-secondary" @click="previousPage()">
                        <i class="feather-arrow-left"></i>
                        Précédent
                    </button>
                    Page {{ currentPage }} / {{ totalPages }}
                    <button class="btn sm:btn-sm btn-primary" @click="nextPage()">
                        Suivant
                        <i class="feather-arrow-right"></i>
                    </button>
                </div>

            </div>
        </div>
    </div>

            <!-- Modal add group-->
            <div
            v-if="isContratModalOpen" 
            style="
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 1000;
            background-color: rgba(0, 0, 0, 0.5);
            align-items: center;
            justify-content: center;">
            <div class="modal-dialog modal-lg  modal-dialog-centered modal-dialog-popout modal-dialog-scrollable" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title"> {{ !isEdition ? 'NOUVEAU CONTRAT' : 'MODIFIER CONTRAT'}}</h5>
                        <button type="button" class="close" @click="closeContratModal()" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <form id="contratForm" @submit.prevent=" isEdition ? updateContrat() : saveContrat()" enctype="multipart/form-data">
                        <div class="modal-body">
                            <div class="sf-md-padding">
                                <div class="row">
                                    <div class="col-lg-6"> 
                                        <div class="form-group">
                                            <label>Entreprise </label>
                                            <select class="form-control" name="entreprise" title="Entreprise" v-model="contratData.entreprise_id" :class="{'is-invalid':contratFormErrors.entreprise_id}">
                                                <option :value = null ></option>
                                               <option v-for="entreprise in entreprises" :key="entreprise.id" :value="entreprise.id">{{ entreprise.nom_entreprise }}</option>
                                           </select>
                                           <span v-if="contratFormErrors.entreprise_id" class="invalid-feedback">{{ contratFormErrors.entreprise_id[0]}}</span>
                                       </div>
                                    </div>

                                    <div class="col-lg-6"> 
                                        <div class="form-group">
                                            <label>Service</label>
                                            <select class="form-control" name="service" title="Service" v-model="contratData.service_id" :class="{'is-invalid':contratFormErrors.service_id}">
                                                <option :value = null ></option>
                                                <option v-for="service in getServices" :key="service.id" :value="service.id">
                                                    <img class='childcat-img' width='50' height='auto' :src="BACK_URL + 'storage/visa_resp/' + service.visa_resp">
                                                    {{ service.nom_service }}</option>
                                            </select>
                                            <span v-if="contratFormErrors.service_id" class="invalid-feedback">{{ contratFormErrors.service_id[0]}}</span>
                                        </div>
                                    </div>
                                    <div class="col-lg-6">
                                        <div class="form-group">
                                            <label>Date de début</label>
                                            <input type="date" class="form-control" v-model="contratData.date_debut"
                                                :class="{ 'is-invalid': contratFormErrors.date_debut }">
                                            <span v-if="contratFormErrors.date_debut" class="invalid-feedback">{{ contratFormErrors.date_debut[0] }}</span>
                                        </div>
                                    </div>
                                    <div class="col-lg-6">
                                        <div class="form-group">
                                            <label>Date de fin</label>
                                            <input type="date" class="form-control" v-model="contratData.date_fin"
                                                :class="{ 'is-invalid': contratFormErrors.date_fin }">
                                            <span v-if="contratFormErrors.date_fin" class="invalid-feedback">{{
                                                contratFormErrors.date_fin[0] }}</span>
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <div class="form-group">
                                            <label>Références du Contrat</label>
                                            <textarea class="form-control" rows="3" v-model="contratData.references" :class="{'is-invalid':contratFormErrors.references}"></textarea>  
                                            <span v-if="contratFormErrors.references" class="invalid-feedback">{{ contratFormErrors.references[0]}}</span>
                                        </div>
                                    </div>
                                    <!--<div class="col-12">
                                        <div class="form-group">
                                            <label>Objet du Contrat</label>
                                            <textarea class="form-control" rows="3" v-model="contratData.objet" :class="{'is-invalid':contratFormErrors.objet}"></textarea>
                                            <span v-if="contratFormErrors.objet" class="invalid-feedback">{{ contratFormErrors.objet[0]}}</span> 
                                        </div>
                                    </div>-->
                                    <div class="col-12">
                                        <div v-if="fileSelected">
                                            {{ contratData.termes_contrat.name }}
                                            <progress class="ml-2" max="100" :value="uploadProgress"></progress>
                                        </div>
                                        <div v-else>Aucun fichier sélectionné</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="modal-footer d-flex justify-content-between align-items-center">
                            <div>
                                <label for="termes-contrat" class="btn admin-button-secondry"><i class="fa fa-upload mr-1"></i>Joindre un fichier (max:1Mo)</label>
                                <input class="form-control" name="termes-contrat" type="file" id="termes-contrat" ref="fileInput" style="display: none" accept="image/*,.pdf,.docx,.doc" @change="termesInputChanged">
                            </div>
                            <div>
                                <button type="button" @click="closeContratModal()" class="btn btn-warning mr-2">Annuler</button>
                                <button v-if="isEdition" type="submit" form="contratForm" class="btn btn-warning" :disabled="isContratUpdating">
                                    <i v-if="isContratUpdating" class="fa fa-fw fa-spinner fa-spin mr-1"></i>
                                    <i v-else class="fa fa-save"></i>
                                    {{ isContratUpdating ? 'Enregistrement...' : 'Enregistrer les modifications' }}
                                </button>
                                <button v-else type="submit" form="contratForm" class="btn btn-warning" :disabled="isContratSaving">
                                    <i v-if="isContratSaving" class="fa fa-fw fa-spinner fa-spin mr-1"></i>
                                    <i v-else class="fa fa-save"></i>
                                    {{ isContratSaving ? 'Enregistrement...' : 'Enregistrer' }}
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>

    <div
        v-if="isObjetModalOpen" 
            style="
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 1000;
            background-color: rgba(0, 0, 0, 0.5);
            align-items: center;
            justify-content: center;">
            <div class="modal-dialog modal-lg  modal-dialog-centered modal-dialog-popout modal-dialog-scrollable" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">NOUVEL OBJET</h5>
                        <button type="button" class="close" @click="closeObjetModal()" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <form id="objetForm" @submit.prevent="saveObjet()" enctype="multipart/form-data">
                        <div class="modal-body">
                            <div class="sf-md-padding">
                                <div class="row">
                                    <div class="col-12">
                                        <div class="form-group">
                                            <label>Intitulé</label>
                                            <textarea class="form-control" rows="3" v-model="objetData.intitule" :class="{'is-invalid':objetFormErrors.intitule}"></textarea>  
                                            <span v-if="objetFormErrors.intitule" class="invalid-feedback">{{ objetFormErrors.intitule[0]}}</span>
                                        </div>
                                    </div>
                                    <div class="col-lg-6">
                                        <div class="form-group">
                                            <label>Prix Unitaire (FCFA)</label>
                                            <input type="number" class="form-control" v-model="objetData.prix_unitaire"
                                                :class="{ 'is-invalid': objetFormErrors.prix_unitaire }">
                                            <span v-if="objetFormErrors.prix_unitaire" class="invalid-feedback">{{ objetFormErrors.prix_unitaire[0] }}</span>
                                        </div>
                                    </div>
                                    <div class="col-lg-6">
                                        <div class="form-group">
                                            <label>Niveau de priorité</label>
                                            <select class="form-control" name="niveau-de-priorite" v-model="objetData.niveau_priorite" :class="{'is-invalid':objetFormErrors.niveau_priorite}">
                                                <option :value = null ></option>
                                                <option value="Normal">Normal</option>
                                                <option value="Urgent">Urgent</option>
                                                <option value="Critique">Critique</option>
                                            </select>
                                            <span v-if="objetFormErrors.niveau_priorite" class="invalid-feedback">{{ objetFormErrors.niveau_priorite[0]}}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="modal-footer d-flex justify-content-between align-items-center">
                            <div>
                                <button type="button" @click="closeObjetModal()" class="btn btn-warning mr-2">Annuler</button>
                                <button type="submit" form="objetForm" class="btn btn-warning" :disabled="isObjetSaving">
                                    <i v-if="isObjetSaving" class="fa fa-fw fa-spinner fa-spin mr-1"></i>
                                    <i v-else class="fa fa-save"></i>
                                    {{ isObjetSaving ? 'Enregistrement...' : 'Enregistrer' }}
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
.description-div-title{
    padding-top: 7px;
    padding-bottom: 7px;
    padding-left: 10px;
    padding-right: 10px;
    margin-right: 10px;
    font-size: large;
    background-color: rgba(50, 50, 168, 0.164); 
    border: 1px solid rgba(50, 50, 168, 0.5);
    border-radius: 10px;
    display: inline-block;
    
}

.description-div-title > strong{
    color: #022279;
    font-weight: bolder;
}

.description-div-value{
    display: inline-block;
    margin-left: 5px;    
}

.description-div-value > strong, .seperator{
    font-size: large;
}

progress {
  background-color: #022279;
}
</style>
