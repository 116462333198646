<template>
    <div class="page-wraper">
        <Header />
        <!-- Content -->
        <div class="page-content">
            
            <!-- Banner Area -->
            <div class="aon-page-benner-area">
              <div class="aon-page-banner-row" style="background-image: url(images/banner/job-banner.jpg)">
                <div class="sf-overlay-main" style="opacity:0.8; background-color:#022279;"></div>
                <div class="sf-banner-heading-wrap">
                  <div class="sf-banner-heading-area">
                    <div class="sf-banner-heading-large">A propos</div>
                    <div class="sf-banner-breadcrumbs-nav">
                      <ul class="list-inline">
                        <li><a href="/"> Accueil </a></li>
                        <li>A propos</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- Banner Area End -->        
                        <!-- Why Choose us -->
                        <section class="aon-why-choose2-area">
                <div class="container">

                    <div class="aon-why-choose2-box">
                        <div class="row">
                            <!-- COLUMNS LEFT -->
                            <div class="col-lg-6 col-md-12">
                                <div class="aon-why-choose-info">
                                    <!--Title Section Start-->
                                    <div class="section-head">
                                        <span class="aon-sub-title">Choisir</span>
                                        <h2 class="aon-title">Pourquoi nous choisir</h2>
                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                                    </div>
                                    <!--Title Section Start End-->

                                    <ul class="aon-why-choose-steps list-unstyled">
                                        <!-- COLUMNS 1 -->
                                        <li class="d-flex">
                                            <div class="aon-w-choose-left aon-icon-effect">
                                                <div class="aon-w-choose-icon"><i class="aon-icon"><img src="images/whychoose/3.png" alt=""></i></div>
                                            </div>
                                            <div class="aon-w-choose-right">
                                                <h4 class="aon-title">Construisez votre réputation en ligne</h4>
                                                <p>Suspendisse tincidunt rutrum ante. Vestibulum elementum ipsum sit amet turpis elementum lobortis.</p>
                                            </div>
                                        </li>

                                        <!-- COLUMNS 2 -->
                                        <li class="d-flex">
                                            <div class="aon-w-choose-left aon-icon-effect">
                                                <div class="aon-w-choose-icon"><i class="aon-icon"><img src="images/whychoose/2.png" alt=""></i></div>
                                            </div>
                                            <div class="aon-w-choose-right">
                                                <h4 class="aon-title">Suivez l'historique de vos tâches et prestations</h4>
                                                <p>Suspendisse tincidunt rutrum ante. Vestibulum elementum ipsum sit amet turpis elementum lobortis.</p>
                                            </div>
                                        </li>

                                        <!-- COLUMNS 3 -->
                                        <li class="d-flex">
                                            <div class="aon-w-choose-left aon-icon-effect">
                                                <div class="aon-w-choose-icon"><i class="aon-icon"><img src="images/whychoose/1.png" alt=""></i></div>
                                            </div>
                                            <div class="aon-w-choose-right">
                                                <h4 class="aon-title">Rencontrez de nouvelles entreprises</h4>
                                                <p>Suspendisse tincidunt rutrum ante. Vestibulum elementum ipsum sit amet turpis elementum lobortis.</p>
                                            </div>
                                        </li>
                                    </ul>

                                </div>
                            </div>
                            <!-- COLUMNS RIGHT -->
                            <div class="col-lg-6 col-md-12">
                                <div class="aon-why-choose2-line">
                                    <div class="aon-why-choose2-pic"></div>
                                </div>     
                        </div>
                        </div>
                    </div>
                    
                </div>
            </section>
            <!-- Why Choose us END -->              
        </div>
        <!-- Content END-->
        <Footer />

        <button class="scroltop"><span class="fa fa-angle-up  relative" id="btn-vibrate"></span></button>
    </div>
    <Auth />
</template>

<script>
import Header from "../components/Header.vue";
import Footer from "../components/Footer.vue";
import Auth from "../components/Auth.vue";

export default {
    components: { Header, Footer, Auth },
};
</script>

<style></style>