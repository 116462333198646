<script setup>
import { onMounted, reactive, ref } from 'vue';
import { useStore } from 'vuex';
import Swal from 'sweetalert2';
import axiosClient from '@/axios';

const store = useStore();

const loading = ref(false);
const errorMessage = ref('');

const handleCloseModal = () => {
  $('#add_services').modal('hide');
  resetForm(); 
};

const validatePasswordConfirmation = () => {
  return password.value === confirmpassword.value;
};

const resetForm = () => {
  nom_entreprise.value = '';
  telephone.value = '';
  logoFile.value = '';
  email.value = '';
  adresse.value = '';
  password.value = '';
  confirmpassword.value = '';
  errorMessage.value = '';
};

const role_id = ref(3);
const email = ref('');
const password = ref('');
const confirmpassword = ref('');

const nom_entreprise = ref('');
const logoFile = ref('');
const telephone = ref('');
const adresse = ref('');
const user_id = ref('');
const error = ref('');

const handleSubmit = async () => {
    loading.value = true;

    if (!validatePasswordConfirmation()) {
      errorMessage.value = 'Les mots de passe ne correspondent pas';
      loading.value = false;
      return;
    }
    const formData = new FormData();
    
    formData.append('email', email.value);
    formData.append('password', password.value);
    formData.append('role_id', role_id.value);
    formData.append('nom_entreprise', nom_entreprise.value);
    formData.append('logo', logoFile.value);
    formData.append('telephone', telephone.value);
    formData.append('adresse', adresse.value);
    formData.append('user_id', user_id.value);

    await axiosClient.post('/entreprises', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }).then((response)=>{
        if (response.status == 201) {
            handleCloseModal();
            Swal.fire({
                title: 'Entreprise créée avec succès',
                icon: 'success',
            }).finally(()=>{
                store.dispatch('fetchEntreprises');
            })
        }
    }).catch ((error) => {
        if (error.response.status == 422) {
            errorMessage.value = error.response.data.errors;
        }
    }).finally(() => {
        loading.value = false;
    })
};

const handleFileChange = (event) => {
    logoFile.value = event.target.files[0];
};


</script>
<template>
    <div class="modal fade content-admin-main" id="add_services" tabindex="-1" role="dialog" aria-hidden="true" data-backdrop="static" data-keyboard="false">
        <div class="modal-dialog model-w800" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel2">NOUVELLE ENTREPRISE</h5>
                    <button @click="handleCloseModal()" type="button" class="close" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <form @submit.prevent="handleSubmit" enctype="multipart/form-data">
                    <div class="modal-body">
                        <div class="sf-md-padding">
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="form-group">
                                        <div class="form-group">
                                            <label>RAISON SOCIALE <span class="text-danger">*</span></label>
                                            <div class="aon-inputicon-box"> 
                                                <input class="form-control sf-form-control" name="nom_entreprise" type="text" v-model="nom_entreprise">
                                                <i class="aon-input-icon fa fa-building-o"></i>
                                            </div>
                                            <div class="text-danger" v-if="errorMessage.nom_entreprise">{{ errorMessage.nom_entreprise[0] }}</div>
                                        </div>
                                    </div>
                                </div>
                                            
                                <div class="col-md-12">
                                    <div class="form-group">
                                        <label>LOGO ENTREPRISE <span class="text-danger">*</span></label>
                                        <div class="aon-inputicon-box"> 
                                            <input class="form-control sf-form-control" accept="image/*" type="file" name="visa_resp" @change="handleFileChange">
                                            <i class="aon-input-icon fa fa-building-o"></i>
                                            <div class="text-danger" v-if="errorMessage.logo">{{ errorMessage.logo[0] }}</div>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-md-12"> 
                                    <div class="form-group">
                                        <label>E MAIL <span class="text-danger">*</span></label>
                                        <div class="aon-inputicon-box"> 
                                            <input class="form-control sf-form-control" name="email" type="text" v-model="email">
                                            <i class="aon-input-icon fa fa-envelope"></i>
                                            <div class="text-danger" v-if="errorMessage.email">{{ errorMessage.email[0] }}</div>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-md-12"> 
                                    <div class="form-group">
                                        <label>TELEPHONE <span class="text-danger">*</span></label>
                                        <div class="aon-inputicon-box"> 
                                            <input class="form-control sf-form-control" name="telephone" type="number" v-model="telephone">
                                            <i class="aon-input-icon fa fa-phone"></i>
                                            <div class="text-danger" v-if="errorMessage.telephone">{{ errorMessage.telephone[0] }}</div>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-md-12">
                                    <div class="form-group">
                                            <div class="aon-inputicon-box"> 
                                                <label>ADRESSE <span class="text-danger">*</span></label>
                                                <textarea class="form-control" rows="5" v-model="adresse">
                                                </textarea>
                                                <div class="text-danger" v-if="errorMessage.adresse">{{ errorMessage.adresse[0] }}</div>
                                            </div>             
                                    </div>
                                </div>

                                <div class="col-md-12">
                                    <div class="form-group">
                                        <div class="form-group">
                                            <label>MOT DE PASSE <span class="text-danger">*</span></label>
                                            <div class="aon-inputicon-box"> 
                                                <input class="form-control sf-form-control" name="password" type="password" v-model=password>
                                                <i class="aon-input-icon fa fa-lock"></i>
                                            </div>
                                            <div class="text-danger" v-if="errorMessage.password">{{ errorMessage.password[0] }}</div>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-md-12">
                                    <div class="form-group">
                                        <div class="form-group">
                                            <label>CONFIRMER LE MOT DE PASSE <span class="text-danger">*</span></label>
                                            <div class="aon-inputicon-box"> 
                                                <input class="form-control sf-form-control" name="password" type="password" v-model="confirmpassword">
                                                <i class="aon-input-icon fa fa-lock"></i>
                                            </div>
                                            <div class="text-danger" v-if="!validatePasswordConfirmation()">Les mots de passe ne correspondent pas</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="admin-button" @click="handleCloseModal()">FERMER</button>
                        <button type="submit" class="admin-button" :disabled="loading">
                            <i v-if="loading" class="fa fa-fw fa-spinner fa-spin mr-1"></i> 
                            <i v-else class="fa fa-save"></i> ENREGISTRER
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<style>
</style>