import Swal from 'sweetalert2';

export function useSwalSuccess(message) {
    Swal.fire({
        toast: true,
        icon: "success",
        title: message,
        timer: 2000,
        timerProgressBar: true,
        position: "top-end",
        showConfirmButton: false,
    });
};

export function useSwalError(message) {
    Swal.fire({
        toast: true,
        icon: "error",
        title: message,
        timer: 2000,
        title: message,
        position: "top-end",
        showConfirmButton: false,
    });
};