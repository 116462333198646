<script>
import ListeAdminEntreprise from "./ListeAdminEntreprise.vue";
import AdminEditEntreprise from "./AdminEditEntreprise.vue";
import AdminAddEntreprise from "./AdminAddEntreprise.vue";
export default {
    components: {AdminAddEntreprise, AdminEditEntreprise, ListeAdminEntreprise}

}
</script>


<template>      
    <!-- Liste entreprise-->
    <ListeAdminEntreprise></ListeAdminEntreprise> 
    <!-- Modal add entreprise-->
    <AdminAddEntreprise></AdminAddEntreprise> 
    <!-- Modal edit entreprise-->
    <AdminEditEntreprise></AdminEditEntreprise>
            
</template>
<style>


</style>