<script setup>
import { reactive, computed, ref } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import {useSwalSuccess, useSwalError} from  '../../composables/alert'

    const store = useStore();
    const router = useRouter();
    const contratData =  ref({
        objet: null,
        references: null,
        service_id: null,
        entreprise_id: null,
        prestataire_id: 1,
    });

    const contratFormErrors = computed(() => {
        return store.state.prestataireModule.contratErrors;
    });

    const isContratCreated = computed(() => {
        return store.state.prestataireModule.isContratCreated;
    });

    function saveContrat() {
        store.dispatch('prestataireModule/createContrat', contratData.value)
        .then(()=>{
            if (isContratCreated.value == true) {
                useSwalSuccess('Votre nouveau contrat a été enregistré avec succès');
                resetContratForm();
                router.push('/prestataire/contrats')
            }
        }).catch((error) =>{
            useSwalError("Echec de l'enregistrement du contrat")
        }).finally(() => {            
        })
    }

    function resetContratForm(){
        contratData.value.objet = null,
        contratData.value.references = null,
        contratData.value.service_id = null,
        contratData.value.entreprise_id = null,
        contratData.value.prestataire_id = null
    }

</script>
  
<template>
  <div>
    <div class="aon-admin-heading">
        <h4>Mes Contrats > Nouveau</h4>
    </div>                           
    <div class="card aon-card">
        <div class="card-header aon-card-header d-flex justify-content-between align-items-center">
            <div >
                <h4><i class="fa fa-file-text"></i> NOUVEAU CONTRAT</h4>
            </div>
        </div>
        <div class="card-body aon-card-body">
            <div class="sf-md-padding">
                <div class="row">
                    <div class="col-md-6"> 
                        <div class="form-group">
                            <label>Sélectionner une Entreprise </label>
                            <select class="sf-select-box form-control sf-form-control bs-select-hidden form-control" name="entreprise" data-live-search="true" title="Entreprise" v-model="contratData.entreprise_id">
                                <option value="1" data-content="<img class='childcat-img' width='50' height='auto' src=/images/cat-thum/cat-1.jpg>
                                      <span class='childcat'>Entreprise 1</span>">Entreprise 1</option>
                                <option value="2" data-content="<img class='childcat-img' width='50' height='auto' src=/images/cat-thum/cat-2.jpg>
                                      <span class='childcat'>Entreprise 2</span>">Entreprise 2</option>
                                <option value="3" data-content="<img class='childcat-img' width='50' height='auto' src=/images/cat-thum/cat-3.jpg>
                                      <span class='childcat'>Entreprise 3</span>">Entreprise 3</option>
                            </select>
                        </div>
                    </div>

                    <div class="col-md-6"> 
                        <div class="form-group">
                            <label>Selectionner un Service</label>
                            <select class="sf-select-box form-control sf-form-control bs-select-hidden form-control" name="service" data-live-search="true" title="Service" v-model="contratData.service_id">
                                <option value="1">Service 1</option>
                                <option value="2">Service 2</option>
                                <option value="3">Service 3</option>
                            </select>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                          <label>Références du Contrat</label>
                            <textarea class="form-control" rows="3" v-model="contratData.references"></textarea>  
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                          <label>Objet du Contrat</label>
                            <textarea class="form-control" rows="3" v-model="contratData.objet"></textarea>  
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="admin-button">Annuler</button>
          <button type="button" @click="saveContrat()" class="admin-button">Enregistrer</button>
        </div>
    </div>
</div>
  
</template>
  
<style>
  
</style>