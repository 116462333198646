import axiosClient from "../axios";

const contactModule = {
    namespaced: true,
    
    state: {
        contact: [],
    },
    getters: {

        getContact(state) {
            return state.contact;
        },
    },

    actions: {
        async createMessage({ commit }, contact) {
            try {
                const response = await axiosClient.post('/contact-us', contact);
                const createMessage = response.data;
                commit('setContact', response.data);
                return createMessage;
            } catch (error) {
                console.error(error);
                throw error;
            }
        },

        async fetchContact({ commit }, {emailQuery, telephoneQuery, dateQuery}) {
            try {
                const response = await axiosClient.get(`/contact/messages?email=${emailQuery}&telephone=${telephoneQuery}&date=${dateQuery}`);
                commit('setContact', response.data.data);
                //console.log(response.data.data);
            } catch (error) {
                console.error(error);
            }
        },
    },

    mutations: {

        setContact(state, contact) {
            state.contact = contact;
        },

    },

};

export default contactModule;