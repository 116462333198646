<script setup>
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { ref, computed, onMounted, reactive } from "vue";
import Swal  from 'sweetalert2';
import { BACK_URL } from "@/constant";
import axiosClient from "@/axios";


    const store = useStore();
    const router = useRouter();
    const entreprise = computed(() => store.state.entreprise);
    const errorMessage = ref('');

    const currentPage = ref(1);
    const itemsPerPage = 5;

    const loading = ref(false);

    const searchNom = ref('');
    const searchAdresse = ref('');
    const searchTelephone = ref('');

    const entrepriseData = reactive({
        nom_entreprise : '',
        telephone : '',
        adresse : '',
        email : '',
        logo : '',
    })

    const fetchPage = async (page) => {
      await store.dispatch('fetchEntreprises', page);
      currentPage.value = page;
    };

    onMounted(() => {
      fetchPage(currentPage.value);
    });

    const selectedEntreprise = ref(null);
    const selectedEntrepriseServices = ref([]);

    const filteredEntreprises = computed(() => {
      if (!Array.isArray(entreprise.value)) return [];
      return entreprise.value.filter(entrepris => {
        const nomMatch = entrepris.nom_entreprise.toLowerCase().includes(searchNom.value.toLowerCase());
        const adresseMatch = entrepris.adresse.toLowerCase().includes(searchAdresse.value.toLowerCase());
        const telephoneMatch = entrepris.telephone.toLowerCase().includes(searchTelephone.value.toLowerCase());
        return nomMatch && adresseMatch && telephoneMatch;
      });
    });

    const totalDataItems = computed(() => filteredEntreprises.value.length);
    const totalPages = computed(() => Math.ceil(totalDataItems.value / itemsPerPage));

    const hasNextPage = computed(() => currentPage.value < totalPages.value);

    const visiblePageNumbers = computed(() => {
      const pages = [];
      for (let i = 1; i <= totalPages.value; i++) {
        pages.push(i);
      }
      return pages;
    });

    const clearFilters = () => {
      searchNom.value = '';
      searchAdresse.value = '';
      searchTelephone.value = '';
    };

    const deleteEntreprise = async (entrepriseId) => {
        const result = await Swal.fire({
            icon: 'question',
            title: 'Confirmation',
            text: 'Êtes-vous sûr de vouloir supprimer cette entreprise ?',
            confirmButtonColor:  '#022279', //'#ffc107',
            cancelButtonColor: '#d33',
            showCloseButton: true,
            showCancelButton: true,
            confirmButtonText: 'Oui',
            cancelButtonText: 'Annuler',
        });

        if (result.isConfirmed) {
            await axiosClient.delete(`/entreprise/${entrepriseId}`)
                .then((res) => {
                    if (res.status == 204) {
                        store.dispatch('fetchEntreprises')
                            .then(() =>{
                                Swal.fire({
                                    icon: 'success',
                                    title: 'L\'entreprise a été supprimé avec succès',
                                }); 
                            })
                    }
                }).catch(error => {
                    if(error.response.status == 403){
                        Swal.fire({
                            title: 'Attention',
                            text: 'Cette entreprise est liée à au moins un contrat. Elle ne peut donc être supprimer',
                            icon: 'warning',
                            confirmButtonColor: '#022279', //'#ffc107',
                            confirmButtonText: 'OK'
                        });
                    }else{
                        Swal.fire({
                            title: 'Erreur',
                            text: 'Un problème est survenu lors de la suppresion de l\'entreprise, vérifiez votre connexion internet puis réessayez',
                            icon: 'error',
                            confirmButtonColor: '#022279', //'#ffc107',
                            confirmButtonText: 'OK'
                        });
                    }
                });
        }
    }

    const updateEntreprise = async () => {
        const result = await Swal.fire({
            icon: 'question',
            title: 'Confirmation',
            text: 'Êtes-vous sûr de vouloir modifier cette entreprise ?',
            confirmButtonColor:  '#022279', //'#ffc107',
            cancelButtonColor: '#d33',
            showCloseButton: true,
            showCancelButton: true,
            confirmButtonText: 'Continuer',
            cancelButtonText: 'Annuler',
        });

        if (result.isConfirmed) {
            loading.value = true;
            const formData = new FormData();
            formData.append('email', entrepriseData.email);
            formData.append('nom_entreprise', entrepriseData.nom_entreprise);
            formData.append('telephone', entrepriseData.telephone);
            formData.append('adresse', entrepriseData.adresse);
            formData.append('_method', 'put');
            axiosClient.post(`/entreprise/${selectedEntreprise.value.id}`, formData)
                .then((res)=>{
                    console.log(res);
                    if (res.status == 201) {
                        $('#edit_services').modal('hide'); 
                        Swal.fire({
                            title: 'Mise à jour réussie',
                            text: 'L\' entreprise a été modifié avec succès',
                            icon: 'success',
                            confirmButtonColor: '#022279', //'#ffc107',
                            confirmButtonText: 'OK'
                        }).finally(()=>{
                            store.dispatch('fetchEntreprises');
                        })
                    }
                }).catch((error) => {
                    if (error.response.status == 422) {
                        errorMessage.value =  error.response.data.errors;
                    }else{
                        Swal.fire({
                            title:'Erreur',
                            text: 'Echec de la mise à jour de l\'entreprise',
                            icon: 'error',
                            confirmButtonColor: '#022279', //'#ffc107',
                            confirmButtonText: 'OK'
                        });
                    }

                }).finally(() => {   
                    loading.value = false;    
                })
        }
    };

    const showServicesModal = (entreprise) => {
      selectedEntreprise.value = entreprise;
      selectedEntrepriseServices.value = entreprise.services; 
      $('#servicesModal').modal('show'); 
    };

    const showEditServiceModal = (entreprise) => {
        selectedEntreprise.value = entreprise
        entrepriseData.nom_entreprise = entreprise.nom_entreprise;
        entrepriseData.email = entreprise.user.email;
        entrepriseData.telephone = entreprise.telephone;
        entrepriseData.adresse = entreprise.adresse;
        entrepriseData.logo = entreprise.logo;
        $('#edit_services').modal('show'); 
    };

    function goBack() {
        router.back();
    }

</script>
<template>
    <div>
        <div class="aon-admin-heading">
            <div class="d-flex justify-content-start align-items-center">
                <button class="btn btn-light btn-lg mr-2" type="button" @click="goBack()">
                    <i class="feather-arrow-left"></i>
                </button>
                <h3 class="text-center">ENTREPRISES</h3>
            </div>
        </div>
        <div class="card aon-card">
            <div class="card-header aon-card-header">
                <div class="aon-card-header aon-blog-style-1 d-flex justify-content-between align-items-center flex-wrap" style="background-color: rgba(50, 50, 168, 0.2); border: 1px solid rgba(50, 50, 168, 0.5); border-radius: 10px;">
                    <div class="py-2">
                        <h4><i class="fa fa-list"></i> LISTE DES ENTREPRISES</h4>
                    </div>
                    <div>
                        <button class="btn admin-button-secondry align-middle" type="button" data-toggle="modal" data-target="#add_services">
                            <i class="fa fa-plus"></i>
                            AJOUTER UNE ENTREPRISE
                        </button>
                    </div> 
                </div>
            </div>
            <div class="card-body aon-card-body">
                <div class="pb-3 px-3 mb-4" style="background-color: rgba(50, 50, 168, 0.2); border: 1px solid rgba(50, 50, 168, 0.5); border-radius: 10px;">
                    <div class="py-3 d-flex justify-content-between align-items-center flex-wrap">
                        <h5>RECHERCHER UNE ENTREPRISE</h5>
                        <button class="btn btn-secondary" @click="clearFilters">Effacer les filtres</button>
                    </div>
                    <!-- Search inputs -->
                    <div class="row">
                        <div class="col-lg-4 form-group">
                            <input class="form-control" type="text" v-model="searchNom" placeholder="RECHERCHE PAR NOM ENTREPRISE">
                        </div>
                        <div class="col-lg-4 form-group">
                            <input class="form-control" type="text" v-model="searchAdresse" placeholder="RECHERCHE PAR ADRESSE">
                        </div>
                        <div class="col-lg-4 form-group">
                            <input class="form-control" type="text" v-model="searchTelephone" placeholder="RECHERCHE PAR TELEPHONE">
                        </div>
                    </div>
                </div> 

                <div class="sf-bs-data-table">
                    <div class="table-responsive">
                        <table class="table table-striped table-bordered " style="width:100%">
                            <thead >
                                <tr class="bg-warning">
                                    <th class="text-center align-middle">N°</th>
                                    <th class="text-start align-middle">RAISON SOCIALE</th>
                                    <th class="text-center align-middle">LOGO</th>
                                    <th class="text-start align-middle">ADRESSE</th>
                                    <th class="text-center align-middle">TELEPHONE</th>
                                    <th class="text-center align-middle">EMAIL</th>
                                    <th class="text-center align-middle">ACTIONS</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-if="entreprise.length === 0">
                                    <td colspan="7" class="text-center align-middle">Pas de données disponibles</td>
                                </tr>
                                <tr v-for="(entrepris, index) in filteredEntreprises" :key="index">
                                    <td class="text-center align-middle">{{ index+1 }}</td>
                                    <td class="text-start align-middle">{{ entrepris.nom_entreprise }}</td>
                                    <td class="text-center align-middle">
                                        <div class="job-comapny-logo mr-2">
                                            <img class="company_logo aon-icon" :src="entrepris.logo ?  BACK_URL + 'storage/logo/' + entrepris.logo : '/../../../images/placeholder/placeholder-entreprise.jpeg'" alt="" style="height: 50px; width: 50px;">
                                        </div>
                                    </td>
                                    <td class="text-start align-middle">{{entrepris.adresse}}</td>
                                    <td class="text-center align-middle">{{entrepris.telephone}}</td>
                                    <td class="text-center align-middle">{{entrepris.user ? entrepris.user.email : 'NULL'}}</td>
                                    <td class="text-center align-middle"> 
                                        <button class="btn btn-danger m-1" type="button" @click="deleteEntreprise(entrepris.id)">
                                            <i class="fa fa-trash-o"></i>
                                        </button>
                                        <button class="btn btn-primary m-1" type="button" @click="showServicesModal(entrepris)">
                                            <i class="fa fa-eye"></i>
                                        </button>
                                        <button class="btn btn-warning m-1" @click="showEditServiceModal(entrepris)" type="button">
                                            <i class="fa fa-edit"></i>
                                        </button>
                                    </td>
                                </tr>                                          
                            </tbody>
                        </table>
                    </div>
                </div>
                <nav aria-label="Page navigation">
                    <ul class="pagination justify-content-end"> 
                        <li class="page-item" :class="{ disabled: currentPage === 1 }">
                            <button class="page-link" @click="fetchPage(currentPage - 1)" :disabled="currentPage === 1">
                                Précédent
                            </button>
                        </li>
                        <li class="page-item" v-for="pageNumber in visiblePageNumbers" :key="pageNumber" :class="{ active: currentPage === pageNumber }">
                            <button class="page-link" @click="fetchPage(pageNumber)">
                            {{ pageNumber }}
                            </button>
                        </li>
                        <li class="page-item" :class="{ disabled: !hasNextPage }">
                            <button class="page-link" @click="fetchPage(currentPage + 1)" :disabled="!hasNextPage">
                                Suivant
                            </button>
                        </li>
                    </ul>
                </nav>
            </div>
            <!-- Modal services -->
            <div class="modal fade" id="servicesModal" tabindex="-1" role="dialog" aria-labelledby="servicesModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-lg" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title text-center" id="servicesModalLabel ">LISTE DES SERVICES DE {{ selectedEntreprise ? selectedEntreprise.nom_entreprise : '' }}</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <div v-if="selectedEntrepriseServices.length > 0">
                                <table class="table table-striped table-bordered " style="width:100%">
                                    <thead >
                                        <tr class="bg-warning">
                                            <th class="text-center">N°</th>
                                            <th class="text-start">SERVICES</th>
                                            <th class="text-center">VISA</th>
                                            <th class="text-center">RESPONSABLE</th>
                                            <th class="text-center">ACTIONS</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(service, index) in selectedEntrepriseServices" :key="index">
                                            <td class="text-center">{{ index+1 }}</td>
                                            <td class="text-start">{{ service.nom_service }}</td>

                                            <td class="text-center">
                                                <img class="company_logo aon-icon" :src="BACK_URL + 'storage/visa_resp/' + service.visa_resp" alt="" style="height: 50px; width: 50px;">
                                            </td>
                                            <td class="text-center">{{service.resp_service }}</td>
                                            <td class="text-center"> 
                                                <button class="btn btn-danger" type="button" >
                                                    <i class="fa fa-trash-o"></i>
                                                </button>
                                            </td>
                                        </tr>                                          
                                    </tbody>
                                </table>
                            </div>
                            <div v-else class="alert alert-warning m-auto text-center" role="alert">
                                <p class="fs-5 m-auto">Aucun service disponible. </p> 
                            </div>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" data-dismiss="modal">Fermer</button>
                        </div>
                    </div>
                </div>
            </div>

            <div class="modal fade content-admin-main" id="edit_services" tabindex="-1" role="dialog" aria-hidden="true" data-backdrop="static" data-keyboard="false">
                <div class="modal-dialog model-w800" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLabel2">MODIFIER ENTREPRISE</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <form @submit.prevent="handleSubmit" enctype="multipart/form-data">
                            <div class="modal-body">
                                <div class="sf-md-padding">
                                    <div class="row">
                                        <div class="col-md-12">
                                            <div class="form-group">
                                                <div class="form-group">
                                                    <label>RAISON SOCIALE <span class="text-danger">*</span></label>
                                                    <div class="aon-inputicon-box"> 
                                                        <input class="form-control sf-form-control" name="nom_entreprise" type="text" v-model="entrepriseData.nom_entreprise">
                                                        <i class="aon-input-icon fa fa-building-o"></i>
                                                    </div>
                                                    <div class="text-danger" v-if="errorMessage.nom_entreprise">{{ errorMessage.nom_entreprise[0] }}</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-12"> 
                                            <div class="form-group">
                                                <label>E MAIL <span class="text-danger">*</span></label>
                                                <div class="aon-inputicon-box"> 
                                                    <input class="form-control sf-form-control" name="email" type="text" v-model="entrepriseData.email">
                                                    <i class="aon-input-icon fa fa-envelope"></i>
                                                    <div class="text-danger" v-if="errorMessage.email">{{ errorMessage.email[0] }}</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-12"> 
                                            <div class="form-group">
                                                <label>TELEPHONE <span class="text-danger">*</span></label>
                                                <div class="aon-inputicon-box"> 
                                                    <input class="form-control sf-form-control" name="telephone" type="number" v-model="entrepriseData.telephone">
                                                    <i class="aon-input-icon fa fa-phone"></i>
                                                    <div class="text-danger" v-if="errorMessage.telephone">{{ errorMessage.telephone[0] }}</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-12">
                                            <div class="form-group">
                                                <div class="aon-inputicon-box"> 
                                                    <label>ADRESSE <span class="text-danger">*</span></label>
                                                    <textarea class="form-control" rows="5" v-model="entrepriseData.adresse">
                                                    </textarea>
                                                    <div class="text-danger" v-if="errorMessage.adresse">{{ errorMessage.adresse[0] }}</div>
                                                </div>             
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="modal-footer">
                                <button type="button" class="admin-button" data-dismiss="modal">FERMER</button>
                                <button @click="updateEntreprise()" type="submit" class="admin-button" :disabled="loading">
                                    <i v-if="loading" class="fa fa-fw fa-spinner fa-spin mr-1"></i> 
                                    <i v-else class="fa fa-save"></i> ENREGISTRER LES MODIFICATIONS
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<style>

</style>