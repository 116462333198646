<script setup>
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { onMounted, ref } from "vue";
import Swal from 'sweetalert2';
import { BACK_URL } from "@/constant";
import axiosClient from "@/axios";

    const store = useStore();
    const router = useRouter();
    const previewPhoto = ref('')
    const isProfileUpdating = ref(false);
    const profileFormErrors = ref({});

    const admin = ref({
        username : '',
        email : '',
        avatar : '',
    }); 

    const getAdminUser = async () => {
        try {
            const response = await store.dispatch("getAdminUser");
            admin.value.username = response.username;
            admin.value.email = response.email;
            previewPhoto.value = response.avatar ? BACK_URL + 'storage/avatar/'+ response.avatar : '/images/user.jpg';
        } catch (error) {
            console.error("Erreur lors de la récupération des données de l'administrateur :", error);
            Swal.fire({
                icon: 'error',
                title: 'Erreur',
                text: 'Une erreur est survenue lors de la récupération des données de l\'administrateur.',
            });
        }
    };

    const handleAvatarUpload = (event) => {
        const file = event.target.files[0];
        admin.value.avatar = file;
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            previewPhoto.value = reader.result;
        };
    };

    const updateAdmin = async () => {
        Swal.fire({
        title: 'Êtes-vous sûr?',
        text: "Les modifications seront enregistées ensuite",
        icon: 'question',
        showCloseButton: true,
        showCancelButton: true,
        confirmButtonColor:  '#022279', //'#ffc107',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Continuer',
        cancelButtonText: 'Annuler'
        }).then((result) => {
            if (result.isConfirmed) {
                isProfileUpdating.value = true;
                const formData = new FormData();
                formData.append('username', admin.value.username);
                formData.append('email', admin.value.email);
                formData.append('avatar', admin.value.avatar); 
                formData.append('_method', 'put');
                axiosClient.post(`/admin-update`, formData)
                    .then((res)=>{
                        if (res.status == 201) {
                            Swal.fire({
                                title: 'Mise à jour réussie',
                                text: 'Votre profile été modifié avec succès',
                                icon: 'success',
                                showCloseButton: true,
                                confirmButtonColor: '#022279', //'#ffc107',
                                confirmButtonText: 'OK'
                            }).finally(()=>{
                                store.commit('setAdminUser', res.data.user);
                            })
                        }
                    }).catch((error) => {
                        if (error.response.status == 422) {
                            profileFormErrors.value = error.response.data.errors;
                        }else{
                            Swal.fire({
                                title:'Erreur',
                                text: 'L\'opération de mise à jour n\'a pas pu être effectuée',
                                icon: 'error',
                                showCloseButton: true,
                                confirmButtonColor: '#022279', //'#ffc107',
                                confirmButtonText: 'OK'
                            });
                        }
                    }).finally(() => {   
                        isProfileUpdating.value = false;    
                    })
            }
        });
    };

    onMounted(getAdminUser); 

    function goBack() {
        router.back();
    }
</script>

<template>
    <div class="aon-admin-heading">
        <div class="d-flex justify-content-start align-items-center">
            <button class="btn btn-light btn-lg mr-2" type="button" @click="goBack()">
                <i class="feather-arrow-left"></i>
            </button>
            <h3 class="text-center">PROFIL</h3>
        </div>
    </div>                
    <div class="card aon-card">
        <div class="card-header aon-card-header"><h4>INFORMATION SUR LE PROFIL</h4></div>
                    <div class="card-body aon-card-body">
                    	<div class="row">
                            <div class="col-xl-6">
                                <div class="aon-staff-avtar">
                                    <div class="aon-staff-avtar-header">
                                        <div class="aon-pro-avtar-pic">
                                            <img :src="previewPhoto" alt="Avatar">
                                            <button class="admin-button has-toltip">
                                                <i class="fa fa-camera"></i>
                                                <span class="header-toltip">Téléverser</span>
                                                <!-- Input pour télécharger l'avatar -->
                                                <input type="file" name="avatar" @change="handleAvatarUpload"  enctype="multipart/form-data">
                                            </button>
                                        </div>
                                    </div>
                                    <div class="aon-staff-avtar-footer">
                                        <h4 class="aon-staff-avtar-title">Téléverser une Image</h4>
                                        <ul>
                                            <li>Taille maximale du fichier: <span>1MB</span></li>
                                            <li>Extensions: <span>JPEG, PNG, PNG</span></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-6">
                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label>NOM UTILISATEUR</label>
                                            <div class="aon-inputicon-box"> 
                                                <input class="form-control sf-form-control" name="company_name" type="text"  v-model="admin.username">
                                                <i class="aon-input-icon fa fa-user"></i>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label>EMAIL</label>
                                            <div class="aon-inputicon-box"> 
                                                <input class="form-control sf-form-control" name="email" type="email"  v-model="admin.email">
                                                <i class="aon-input-icon fa fa-building-o"></i>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-12">
                                        <button class="btn admin-button" :disabled="isProfileUpdating" @click="updateAdmin()">
                                            <i v-if="isProfileUpdating" class="fa fa-fw fa-spinner fa-spin mr-1"></i>
                                            <i v-else class="fa fa-save"></i>
                                            {{ isProfileUpdating ? 'ENREGISTREMENT' : 'ENREGISTRER LES MODIFICATIONS' }}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>   
                
</template>
<style>
</style>