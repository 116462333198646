<script setup>
import { onMounted, reactive, ref } from 'vue';
import { useStore } from 'vuex';
import Swal from 'sweetalert2';
import axiosClient from '@/axios';

const store = useStore();

const loading = ref(false);
const errorMessage = ref('');

const handleCloseModal = () => {
  $('#add_prestataire').modal('hide'); 
};

const validatePasswordConfirmation = () => {
  return password.value === confirmpassword.value;
};


const resetForm = () => {
  nom.value = '';
  prenoms.value = '';
  telephone.value = '';
  raison_sociale.value = '';
  photo.value = '';
  previewPhoto.value = '';
  address.value = '';

  email.value = '';
  password.value = '';
  confirmpassword.value = '';
};

const role_id = ref(2);
const email = ref('');
const password = ref('');
const confirmpassword = ref('');

const nom = ref('');
const prenoms = ref('');
const telephone = ref('');
const raison_sociale = ref('');
const address = ref('');
const photo = ref('');
const user_id = ref('');
const error = ref('');

const previewPhoto = ref('');

const handleSubmit = async () => {
  loading.value = true;
    if (!validatePasswordConfirmation()) {
      errorMessage.value = 'Les mots de passe ne correspondent pas';
      return;
    }

    const userData = new FormData();
    userData.append('email', email.value);
    userData.append('password', password.value);
    userData.append('role_id', role_id.value);
    userData.append('nom', nom.value);
    userData.append('prenoms', prenoms.value);
    userData.append('telephone', telephone.value);
    userData.append('raison_sociale', raison_sociale.value);
    userData.append('photo', photo.value);
    userData.append('address', address.value);
    userData.append('user_id', user_id.value);
    

    await store.dispatch('createPrestataire', userData)
    .then((response) =>{
        if (response.status == 201) {
            Swal.fire({
                title: 'Prestataire créé avec succès',
                text: "Vous pouvez maintenant vous connecter.",
                icon: 'success',
                showCancelButton: true,
                confirmButtonText: 'Se connecter',
                cancelButtonText: 'Plus tard',
                }).then((result) => {
                    if (result.isConfirmed) {
                        $('a[data-toggle="tab"][href="#Upcoming"]').tab('show');
                        $('#login-signup-model').modal('show');
                    }
                }).finally(()=>{
                    resetForm();
                })
        }
    }).catch((error) => {
        if (error.response.status == 422) {
            errorMessage.value = error.response.data.errors;
            console.log(':',errorMessage);
            console.error(error);
        }else{
            Swal.fire({
                title:'Erreur',
                text: 'Echec de la création du compte',
                icon: 'error',
                confirmButtonColor: '#022279', //'#ffc107',
                confirmButtonText: 'OK'
            });
        }
    }).finally(() => {   
        loading.value = false;    
    })
    
}


const photoInputChanged = (e) =>{
    const files = e.target.files
    if (!files || !files.length) return
    const file = files[0]
    photo.value = file;
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => {
        previewPhoto.value = String(reader.result);
    }
}

</script>
<template>
    <div class="sf-tabs-content">
        <form class="aon-login-form" @submit.prevent="handleSubmit">
            <div class="row">
                <div class="col-12">
                    <div class="form-group">
                        <div class="profile-pic-bx">
                            <div class="rwmb-field rwmb-plupload_image-wrapper">
                                <div class="rwmb-input">
                                    <div style="position: relative;">
                                        <div class="drag-drop-inside text-center"> 
                                            <div class="m-tb20 team-user-avtar-pic">
                                                <img :src="photo ? previewPhoto : 'images/user.jpg'" alt="" style="max-width: 100px; max-height: 100px;">
                                            </div>
                                            <div>
                                                <label for="photo" class="btn admin-button-secondry">Choisir une image</label>
                                                <input class="form-control" name="photo" type="file" id="photo" ref="fileInput" style="display: none" accept="image/*" @change="photoInputChanged">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group">
                        <label>NOM <span class="text-danger">*</span></label>
                        <div class="aon-inputicon-box">
                            <input class="form-control sf-form-control" name="nom" type="text" placeholder="NOM" v-model="nom">
                            <i class="aon-input-icon fa fa-user"></i>
                        </div>
                        <div class="text-danger" v-if="errorMessage.nom">{{ errorMessage.nom[0] }}</div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group">
                        <label>PRENOMS <span class="text-danger">*</span></label>
                        <div class="aon-inputicon-box">
                            <input class="form-control sf-form-control" name="prenoms" type="text" placeholder="PRENOMS" v-model="prenoms">
                            <i class="aon-input-icon fa fa-user"></i>
                        </div>
                        <div class="text-danger" v-if="errorMessage.prenoms">{{ errorMessage.prenoms[0] }}</div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group">
                        <label>EMAIL <span class="text-danger">*</span></label>
                        <div class="aon-inputicon-box">
                            <input class="form-control sf-form-control" name="email"  type="text" placeholder="EMAIL" v-model="email">
                            <i class="aon-input-icon fa fa-envelope-o"></i>
                        </div>
                        <div class="text-danger" v-if="errorMessage.email">{{ errorMessage.email[0] }}</div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group">
                        <label>TELEPHONE <span class="text-danger">*</span></label>
                        <div class="aon-inputicon-box">
                            <input class="form-control sf-form-control" name="telephone" type="text" placeholder="TELEPHONE" v-model="telephone">
                            <i class="aon-input-icon fa fa-phone"></i>
                        </div>
                        <div class="text-danger" v-if="errorMessage.telephone">{{ errorMessage.telephone[0] }}</div>
                    </div>
                </div>

                <div class="col-md-12">
                    <div class="form-group">
                        <label>RAISON SOCIALE <span class="text-danger">*</span></label>
                        <div class="aon-inputicon-box">
                            <textarea class="form-control sf-form-control" name="raison_sociale" id="raison_sociale" cols="20" rows="3" v-model="raison_sociale" placeholder="RAISON SOCIALE"></textarea>
                        </div>
                        <div class="text-danger" v-if="errorMessage.raison_sociale">{{ errorMessage.raison_sociale[0] }}</div>
                    </div>
                </div>

                <div class="col-md-12">
                    <div class="form-group">
                        <label>ADRESSE <span class="text-danger">*</span></label>
                        <div class="aon-inputicon-box">
                            <textarea class="form-control sf-form-control" name="address" id="address" cols="20" rows="3" placeholder="ADRESSE" v-model="address"></textarea>
                        </div>
                        <div class="text-danger" v-if="errorMessage.address">{{ errorMessage.address[0] }}</div>
                    </div>
                </div>
                
                <div class="col-md-12">
                    <div class="form-group">
                        <label>MOT DE PASSE <span class="text-danger">*</span></label>
                        <div class="aon-inputicon-box">
                            <input class="form-control sf-form-control" name="password"  type="password" placeholder="MOT DE PASSE" v-model="password">
                            <i class="aon-input-icon fa fa-lock"></i>
                        </div>
                        <div class="text-danger" v-if="errorMessage.password">{{ errorMessage.password[0] }}</div>
                    </div>
                </div>

                <div class="col-md-12">
                    <div class="form-group">
                        <label>CONFIRMER LE MOT DE PASSE <span class="text-danger">*</span></label>
                        <div class="aon-inputicon-box">
                            <input class="form-control sf-form-control" name="password"  type="password" placeholder="CONFIRMER LE MOT DE PASSE" v-model="confirmpassword">
                            <i class="aon-input-icon fa fa-lock"></i>
                        </div>
                        <div class="text-danger" v-if="!validatePasswordConfirmation()">Les mots de passe ne correspondent pas</div>
                    </div>
                </div>

                
                <div class="col-md-12">
                    <button type="submit" class="site-button w-100" :disabled="loading">
                        <i v-if="loading" class="fa fa-fw fa-spinner fa-spin mr-1"></i> 
                        <i v-else class="feather-arrow-right"></i> INSCRIPTION
                    </button>
                </div>
            </div>
        </form>
    </div>
</template>

<style>

</style>