<script setup>
import store from '../store';
import { useRouter } from "vue-router";
import { ref,onMounted } from "vue";
import Swal from 'sweetalert2';

const router = useRouter();
const loading = ref(false);
const isPasswordReset = ref(false);

const resetForm = {
    password: "",
    password_confirm : "",
    token: "",
};

const errorMessage = ref({

});

async function resetPassword() {
  loading.value = true;
    await store.dispatch("RESET_PASSWORD", resetForm)
    .then((res) => {
        if(res.status == 200){
            isPasswordReset.value = true;
            Swal.fire({
                title: 'Votre mot de passe a été réinitialisé avec succès',
                icon: 'success',
                confirmButtonText: 'Connectez-vous',
                confirmButtonColor: '#022279', //'#ffc107',
            }).then((result) => {
                if (result.isConfirmed) {
                    router.push('/')
                }else{
                    resetForm.password = '';
                    resetForm.password_confirm = '';
                    resetForm.token = '';
                }
            })
        }
    }).catch((err) =>{
        if (err.response.status == 422) {
            errorMessage.value = err.response.data.error
        }
    }).finally(() =>{
        loading.value = false;
    })
}

onMounted(() => {
    resetForm.token = router.currentRoute.value.query.token
});

function clearFields() {
  resetForm.password = '';
  resetForm.password_confirm = '';
  resetForm.token = '';
}

</script>

<template>
    <div class="container d-flex justify-content-center align-items-center" style="height: 100vh;">
            <div class="aon-blog-style-1 mx-2 col-md-7" style="border-radius: 10px;">
                <div class="row">
                    <div class="col-md-5 bg-light p-0 image-container">
                        <img src="images/forgot_password_illustration.jpg" alt="">           
                    </div>          
                    <div class="col-md-7 p-0">
                        <div class="card aon-card">
                            <div class="card-header aon-card-header"><h5>RENSEIGNEZ VOTRE NOUVEAU MOT DE PASSE</h5></div>
                            <div class="card-body aon-card-body">
                                <form class="aon-login-form" @submit.prevent="resetPassword()">
                                    <div class="row">
                                        <div class="col-12 form-group">
                                            <div class="input-group mb-1">
                                                <input type="password" class="form-control" name="mot-de-passe" placeholder="Mot de passe"  v-model="resetForm.password">
                                                <div class="input-group-append">
                                                    <div class="input-group-text">
                                                        <span class="fa fa-lock"></span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div v-if="errorMessage.password" class="alert alert-danger fade show" role="alert">
                                                {{ errorMessage.password[0]}}
                                            </div>
                                        </div>

                                        <div class="col-12 form-group">
                                            <div class="input-group mb-3">
                                                <input type="password" class="form-control" name="confirmer-otconfirmer-de-passe" placeholder="Confirmer mot de passe"  v-model="resetForm.password_confirm">
                                                <div class="input-group-append">
                                                    <div class="input-group-text">
                                                        <span class="fa fa-lock"></span>
                                                    </div>
                                                </div>
                                            </div>
                                            <span v-if="errorMessage.password_confirm" class="text-danger">{{ errorMessage.password_confirm[0]}}</span>
                                        </div>

                                        <div class="col-12 form-group">
                                            <button type="submit" class="btn admin-button btn-block" :disabled="loading">
                                              <i v-if="loading" class="fa fa-fw fa-spinner fa-spin mr-1"></i> 
                                              ENVOYER
                                            </button>
                                        </div>
                                    </div>
                                </form>
                                <p class="mt-3 mb-1">
                                    <span class="mr-4"><router-link :to="{name : 'forgot'}">Retour</router-link></span>
                                    <span><router-link :to="{name : 'accueil'}">Connectez-vous</router-link></span>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    </div>
</template>

<style scoped>
.image-container {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    background-color: transparent
}

.image-container img {
    flex-shrink: 0;
    min-width: 100%;
    min-height: 100%
}
</style>