<script setup>
import store from '../store';
import { useRouter } from "vue-router";
import { ref } from "vue";
import Swal from 'sweetalert2';

const router = useRouter();
const loading = ref(false);
const unhautized = ref('');

const user = {
  email: "",
  password: "",
};

const errorMessage = ref({
  email: '',
  password: ''
});

async function login() {
  loading.value = true;
  try {
    await store.dispatch("login", user);
    const userRole = store.getters.getUserRole;
    if (userRole === "prestataire") {
      router.push('/prestataire');
    } else if (userRole === "entreprise") {
      router.push('/entreprise');
    } else if (userRole === "admin") {
      router.push('/admin');
    } else if (userRole === "service") {
      router.push('/service');
    } else {
      router.push('/');
    }
    $('#login-signup-model').modal('hide');
    clearFields(); // Réinitialiser les champs de connexion
  } catch (error) {
    errorMessage.value = error.response.data.error;
    unhautized.value = error.response.data.message;
    if (unhautized.value) {

    Swal.fire({
        icon: 'error',
        title: 'Erreur',
        text: 'Veillez vérifier votre mot de passe ou votre adresse email.',
      }).then((result) => {
        if (result.isConfirmed) {
          window.location.reload(); 
        }
      });
    }

  } finally {
    loading.value = false;
  }
}

function toForgotPage(){
  $('#login-signup-model').modal('hide');
  router.push('/forgot-password');
}

function clearFields() {
  user.email = '';
  user.password = '';
}

</script>

<template>
  <div  class="tab-pane active" id="Upcoming">
    <div class="sf-tabs-content">
        <form class="aon-login-form" @submit.prevent="login">
            <div class="row">
              <div class="text-danger" v-if="unhautized">Veillez vérifier votre mot de passe ou votre adresse email.</div>
                <div class="col-md-12">
                    <div class="form-group">
                        <div class="aon-inputicon-box">
                            <input class="form-control sf-form-control" name="email" id="email" type="email" placeholder="Entrer votre adresse mail"  v-model="user.email" >
                            <i class="aon-input-icon fa fa-user"></i>
                            <div class="text-danger" v-if="errorMessage.email">{{ errorMessage.email[0] }}</div>
                        </div>
                    </div>
                </div>
                <div class="col-md-12">
                    <div class="form-group">
                        <div class="aon-inputicon-box">
                            <input class="form-control sf-form-control" name="password" type="password" placeholder="Entrer votre mot de passe" v-model="user.password">
                            <i class="aon-input-icon fa fa-lock"></i>
                            <div class="text-danger" v-if="errorMessage.password">{{ errorMessage.password[0] }}</div>
                        </div>
                    </div>
                </div>
                <div class="col-md-12">
                    <div class="form-group d-flex aon-login-option justify-content-between">
                        <div class="aon-login-opleft">
                            <div class="checkbox sf-radio-checkbox">
                            </div>
                        </div>
                        <!-- END Step 1 -->
                        <div class="aon-login-opright">
                            <a href="#" @click="toForgotPage()">Mot de passe oublié</a>
                        </div>
                        
                    </div>
                </div>
                <div class="col-md-12">
                    <button type="submit" class="site-button w-100" :disabled="loading">
                      <i v-if="loading" class="fa fa-fw fa-spinner fa-spin mr-1"></i> 
                      <i v-else class="feather-arrow-right"></i>
                      CONNEXION
                    </button>
                </div>
            </div>
        </form>
    </div>
  </div>
  
</template>

<style>

</style>