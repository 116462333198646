import { createRouter, createWebHistory } from "vue-router";
import Acceuil from "../views/Acceuil.vue";
import Service from "../views/Service.vue";
import Contact from "../views/Contact.vue";
import Profile from "../views/Profile.vue";
import Apropos from "../views/Apropos.vue";

//ADMIN'
import AdminComponent from "../views/Admin/AdminComponent.vue";
import ListeAdmin from "../views/Admin/ListeAdmin.vue";
import AdminMessage from "../views/Admin/AdminMessage.vue";
import AdminProfil from "../views/Admin/AdminProfil.vue";
import AdminSetting from "../views/Admin/AdminSetting.vue";
import AdminStatistiques from '../views/Admin/AdminStatistiques.vue'

//ENTREPRISE
import EntrepriseComponent from "../views/Entreprise/EntrepriseComponent.vue";
import ListEntreprise from "@/views/Entreprise/ListEntreprise.vue";
import PrestataireComponent from "../views/Prestataire/PrestataireComponent.vue";
import EntrepriseStatistiques from '../views/Entreprise/EntrepriseStatistiques.vue'
import EntrepriseProfile from '../views/Entreprise/EntrepriseProfile.vue'

// RESPONSABLE
import ResponsableComponent from "../views/Responsable/ResponsableComponent.vue";
import ResponsableContrat from "../views/Responsable/ResponsableContrat.vue";
import ResponsableDetailsContrat from "../views/Responsable/ResponsableDetailsContrat.vue";
import ResponsableListeTache from "../views/Responsable/ResponsableListeTache.vue";
import ResponsableDetailsTache from "../views/Responsable/ResponsableDetailsTache.vue";
import ResponsableContratTaches from "../views/Responsable/ResponsableContratTaches.vue";
import ServicePrestataires from '../views/Responsable/ServicePrestataires.vue'
import ServiceStatistiques from "../views/Responsable/ServiceStatistiques.vue";
import ServiceProfile from "../views/Responsable/ServiceProfile.vue";
import ServicePrestataireContrats from '../views/Responsable/ServicePrestataireContrats.vue'

//DASHBOARDS
import AdminDashboadComponent from "../components/AdminDashboad/AdminDashboadComponent.vue";
import PrestataireDashboardComponent from "../components/PrestataireDashboard/PrestataireDashboardComponent.vue";
import EntrepriseDashboardComponent from "@/components/EntrepriseDashboard/EntrepriseDashboardComponent.vue";
import ResponsableServiceDashboardComponent from "@/components/ResponsableServiceDashboard/ResponsableServiceDashboardComponent.vue";

//PRESTATAIRE
import PrestataireLayout from "../layouts/PrestataireLayout.vue";
import Contrat from "../views/Contrat/Contrat.vue";
import TacheAll from "../views/Tache/TacheAll.vue";
import NouveauContrat from "../views/Contrat/NouveauContrat.vue";
import DetailsContrat from "../views/Contrat/DetailsContrat.vue";
import DetailsTaches from "../views/Contrat/DetailsTaches.vue";
import Taches from "../views/Contrat/Taches.vue";
import PrestataireStatistiques from '../views/Prestataire/PrestataireStatistiques.vue';
import PrestataireProfile from "../views/Prestataire/PrestataireProfile.vue";

import ForgotPassword from "../views/ForgotPassword.vue";
import ResetPassword from "../views/ResetPassword.vue";

import Swal from 'sweetalert2';
import store from "../store";

const routes = [
  {
    path: "/forgot-password",
    name: "forgot",
    component: ForgotPassword,
    meta :{
      guest : true
    }
  },

  {
    path: "/reset-password",
    name: "reset",
    component: ResetPassword,
    meta :{
      guest : true
    }
  },

  {
    path: "/",
    name: "accueil",
    component: Acceuil,
  },


  {
    path: "/service",
    name: "service",
    component: Service,
  },

  {
    path: "/contact",
    name: "contact",
    component: Contact,
  },

  {
    path: "/a-propos",
    name: "a-propos",
    component: Apropos,
  },

  {
    path: "/prestataire",
    redirect: "/prestataire",
    name: "prestataire",
    component: PrestataireLayout,
    meta: { requiresAuth: true, requiredRoles: ['prestataire'] },
    children: [
      {
        path: "/prestataire",
        name: "prestataire",
        component: PrestataireDashboardComponent,
      },

      { 
        path: "/prestataire/contrats", 
        name: "contrat", 
        component: Contrat
      },

      {
        path: "/prestataire/contrats/nouveau",
        name: "nouveau-contrat",
        component: NouveauContrat,
      },

      {
        path: "/prestataire/contrats/:contratId/details",
        name: "details-contrat",
        component: DetailsContrat,
        props: true,
      },

      {
        path: "/prestataire/taches",
        name: "all-taches",
        component: TacheAll,
      },

      {
        path: "/contrats/:contratId/taches",
        name: "taches-par-contrat",
        component: Taches,
        props: true,
      },

      {
        path: "/contrats/detailstaches/:id",
        name: "contrat-detailstaches",
        component: DetailsTaches,
      },

      {
        path: "/prestataire/statistiques",
        name: "pres-statistiques",
        component: PrestataireStatistiques,
      },

      { 
        path: "/prestataire/profile", 
        name: "profile", 
        component: PrestataireProfile
      },

    ],
  },

  {
    path: "/admin",
    redirect: "/admin",
    name: "admin",
    component: AdminDashboadComponent,
    meta: { requiresAuth: true, requiredRoles: ['admin'] },
    children: [
      { path: "/admin", name: "admin", component: AdminComponent },
      {
        path: "/list_entreprise",
        name: "list_entreprise",
        component: ListeAdmin,
      },
      {
        path: "/list_prestataire",
        name: "list_prestataire",
        component: PrestataireComponent,
      },
      {
        path: "/admin_message",
        name: "admin_message",
        component: AdminMessage,
      },
      {
        path: "/admin_profil",
        name: "admin_profil",
        component: AdminProfil,
      },
      
      {
        path: "/admin_settings",
        name: "admin_settings",
        component: AdminSetting,
      },

      {
        path: "/admin/statistiques",
        name: "adm-statistiques",
        component: AdminStatistiques,
      },

    ],
  },

  {
    path: "/entreprise",
    redirect: "/entreprise",
    name: "entreprise",
    component: EntrepriseDashboardComponent,
    meta: { requiresAuth: true, requiredRoles: ['entreprise'] },
    children: [
      {
        path: "/entreprise",
        name: "entreprise",
        component: EntrepriseComponent,
      },
      {
        path: "/list_responsable",
        name: "list_responsable",
        component: ListEntreprise,
      },
      {
        path: "/entreprise/statistiques",
        name: "entre-statistiques",
        component: EntrepriseStatistiques,
      },
      
      {
        path: "/entreprise/profile", 
        name: "entre-profile", 
        component: EntrepriseProfile
      }
    ],
  },

// ROUTE RESPONSABLES SERVICES

  {
    path: "/service",
    redirect: "/service",
    name: "service",
    component: ResponsableServiceDashboardComponent,
    meta: { requiresAuth: true, requiredRoles: ['service'] },
    children: [

      { path: "/service", name: "service", component: ResponsableComponent },

      {
        path: "/service/taches",
        name: "service-taches",
        component: ResponsableListeTache,
        
      },
      {
        path: "/service/contrats/:contratId/taches",
        name: "service-contrat-taches",
        component: ResponsableContratTaches,
        props:true,
      },
      {
        path: "/service/contrats",
        name: "service-contrat",
        component: ResponsableContrat,
      },
      {
        path: "/service/contrats/details/:id",
        name: "service-contrat-details",
        component: ResponsableDetailsContrat,
      },
    
      {
        path: "/service/taches/details/:id",
        name: "service-taches-details",
        component: ResponsableDetailsTache,
      },

      {
        path: "/service/statistiques",
        name: "serv-statistiques",
        component: ServiceStatistiques,
      },

      {

        path: "/service/prestataires",
        name: "serv-prestataires",
        component: ServicePrestataires,
      },
    
      {
        path: "/serv-prestataire/:prestataireId/contrats",
        name: "serv-prestataire-contrats",
        component: ServicePrestataireContrats,
        props: true
      },

      { 
        path: "/service/profile", 
        name: "serv-profile", 
        component:  ServiceProfile
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  const isAuthenticated = store.state.user.token;
  const userRole = store.state.user.role ? store.state.user.role : null;
  if (to.meta.requiresAuth && !isAuthenticated) {
      next('/');
  } else if (to.meta.requiresAuth && isAuthenticated) {

      if (to.meta.requiredRoles && (!userRole || !to.meta.requiredRoles.includes(userRole.designation))) {
          Swal.fire({
              icon: 'error',
              title: 'Erreur',
              text: 'Page Introuvable.',
              confirmButtonColor: '#0665d0',
          });
      } else {
          next();
      }
  } else if (to.meta.isGuest && isAuthenticated) {
      next('/');
  } else {
      next();
  }
});

export default router;
