<script>
import { useStore } from "vuex";
import { computed, onMounted, ref } from "vue";
import { useRouter } from 'vue-router';

export default {
    setup() {
        const store = useStore();
        const router = useRouter();
        const contact = computed(() => store.state.contactModule.contact);
        const activeItem = ref('/admin'); 

        const setActive = (item) => {
            activeItem.value = item;
            console.log("Setting active item to:", activeItem.value);
        };
        onMounted(() => {
            store.dispatch('contactModule/fetchContact',{emailQuery: '', telephoneQuery: '', dateQuery: ''});  
        });
       
        return {
            contact,
            activeItem,
            setActive,
            navigateTo(route) {
                router.push(route);
            }
        };
    },
};
</script>

<template>
        <nav id="sidebar-admin-wraper">
            <div class="admin-nav">
                    <ul class="">
                        <li :class="{ active: activeItem === '/admin' }">
                            <router-link to="/admin"><i class="fa fa-dashboard"></i><span class="admin-nav-text">TABLEAU DE BORD</span></router-link>
                        </li>
                        <li :class="{ active: activeItem === '/list_entreprise' }">
                            <router-link to="/list_entreprise"><i class="fa fa-building"></i><span class="admin-nav-text">ENTREPRISES</span></router-link>
                        </li>
                        <li :class="{ active: activeItem === '/list_prestataire' }">
                            <router-link to="/list_prestataire"><i class="fa fa-id-card-o"></i><span class="admin-nav-text">PRESTATAIRES</span></router-link>
                        </li>
                        <li :class="{ active: activeItem === '/admin_statistiques' }">
                            <router-link to="/admin/statistiques"><i class="fa fa-bar-chart"></i><span class="admin-nav-text">STATISTIQUES</span></router-link>
                        </li>
                        <li :class="{ active: activeItem === '/admin_message' }">
                            <router-link to="/admin_message"><i class="fa fa-envelope-o"></i><span class="admin-nav-text">MESSAGES</span><span class="admin-nav-label">{{ contact ? contact.length : 0 }}</span></router-link>
                        </li>
                        <li :class="{ active: activeItem === '/admin_profil' }">
                            <router-link to="/admin_profil"><i class="fa fa-user-circle-o"></i><span class="admin-nav-text">PROFIL</span></router-link>
                        </li>
                    </ul>
            </div>  
        </nav>
</template>

<style>

</style>