<script>
import axios from '@/axios';
import axiosClient from '@/axios';
import moment from 'moment';

export default {
    data() {
        return {
            tacheDetails: null,
        };
    },
    mounted() {
        this.fetchTacheDetails();
    },
    methods: {

        async fetchTacheDetails() {
            try {
                const response = await axios.get(`/taches/${this.$route.params.id}`);
                this.tacheDetails = response.data.tache;
            } catch (error) {
                console.error('Erreur lors de la récupération des détails de la tâche :', error);
            }
        },

        formatDate(date) {
            return moment(date).format('YYYY-MM-DD [à] HH:mm:ss');
        },

        async downloadTacheRapport(rapportPath) {
            await axiosClient.get(`/download-tache-rapport?path=${rapportPath}` , {responseType: 'blob'})
              .then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', rapportPath);
                document.body.appendChild(link);
                link.click();
              }).catch(error => {
                console.error('Erreur lors du téléchargement du fichier :', error);
              }).finally(() => {

              });
        },

        goBack(){
            this.$router.back();
        }
    },
};
</script>

<template>
    <div class="aon-admin-heading">
        <div class="d-flex justify-content-start align-items-center">
            <button class="btn btn-light btn-lg mr-2" type="button" @click="goBack()">
                <i class="feather-arrow-left"></i>
            </button>
            <h3 class="text-center">PRESTATION > DÉTAILS</h3>
        </div>
    </div>
    <div class="page-content bg-white" v-if="tacheDetails">
        <div class="sf-job-benner sf-overlay-wrapper">
            <div class="banner-job-row">
                <div class="sf-overlay-main" style="opacity:0;"></div>
                <div class="sf-banner-job-heading-wrap">
                    <div class="sf-banner-job-heading-area">
                        <ul class="sf-banner-job-dutation">
                            <div class="row">
                                <div class="col-lg-12 col-md-12">
                                    <h3 class="text-center">Description</h3>
                                    <p>{{ tacheDetails.description }} </p>
                                </div>
                            </div>
                            <li>
                                <i class="fa fa-hourglass-o"></i> Statut :
                                <span
                                    :class="{ 'badge badge-success': tacheDetails.statut === 'terminee', 'badge badge-primary': tacheDetails.statut === 'en cours', 'badge badge-info': tacheDetails.statut === 'planifiee' }">{{
                                        tacheDetails.statut }}</span>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <div class="section-content">
            <div class="container">
                <div class="sf-job-details-fileds sf-job-details-fileds-two">
                    <ul class="job-listing-meta meta">
                        <li class="location">
                            <div class="sf-job-meta-info">
                                <h5 class="job-meta-title">Début:</h5>
                                <span class="job-meta-text text-red">{{ tacheDetails.date_debut }}</span>
                            </div>
                        </li>
                        <li>
                            <div class="sf-job-meta-info">
                                <h5 class="job-meta-title">Validateur:</h5>
                                <span class="job-meta-text text-red">{{ tacheDetails.operateur_tache }}</span>
                            </div>
                        </li>
                        <li class="job-type hourly">
                            <div class="sf-job-meta-info">
                                <h5 class="job-meta-title">Rapport:</h5>
                                <span v-if="tacheDetails.rapport" class="job-meta-text">
                                    <button class="btn  admin-button job-meta-text" @click="downloadTacheRapport(tacheDetails.rapport)">
                                        <i class=" fa fa-download text-success"></i> Télécharger Rapport
                                    </button>
                                </span>
                                <span v-else class="job-meta-text text-red">Aucun rapport renseigné</span>
                            </div>
                        </li>

                        <li class="location">
                            <div class="sf-job-meta-info">
                                <h5 class="job-meta-title">Fin:</h5>
                                <span class="job-meta-text text-red">{{ tacheDetails.date_fin }}</span>
                            </div>
                        </li>
                        <li>
                            <div class="sf-job-meta-info">
                                <h5 class="job-meta-title">Fonction:</h5>
                                <span class="job-meta-text text-red">{{ tacheDetails.fonction_operateur }}</span>
                            </div>
                        </li>
                        <li class="date-posted">
                            <div class="sf-job-meta-info">
                                <h5 class="job-meta-title">Créé le :</h5>
                                <span class="job-meta-text text-red">{{ formatDate(tacheDetails.created_at) }}</span>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>
  
<style></style>
  