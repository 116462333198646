<script>
import HeaderEntreprise from "./HeaderEntreprise.vue"
import SideBarEntreprise from "./SideBarEntreprise.vue"
import { useStore, mapGetters } from "vuex";
import { computed, onMounted, ref } from "vue";
import { useRouter } from "vue-router";
import Swal from 'sweetalert2';
export default {
    setup() {
        const store = useStore();
        const router = useRouter();

        const connectedEntreprise = store.getters.getEntrepriseUser
        const userRole = store.getters.getUserRole;
        const isLoggedIn = store.getters.isLoggedIn;

        const entreprise = computed(() => store.state.entrepriseUser);

        onMounted(async () => {
            try {
                await store.dispatch("getEntrepriseUser");
            } catch (error) {
                console.error("Erreur lors de la récupération des données de l'entreprise :", error);
            }
        });

        function showLogoutConfirmation() {
            Swal.fire({
                title: 'Êtes-vous sûr de vouloir vous déconnecter ?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Oui',
                cancelButtonText: 'Non',
                confirmButtonColor: '#e04f1a',
                cancelButtonColor: '#ffb600'

            }).then((result) => {
                if (result.isConfirmed) {
                    logout();
                }
            });
        }



        function logout() {
            store.dispatch("logout").then(() => {
                router.push('/'); 
            });
        }

        return {
            user: computed(() => store.state.user.data),
            showLogoutConfirmation,
            logout,
            userRole,
            isLoggedIn,
            entreprise,
            connectedEntreprise,
        };
    },
    components: {
        HeaderEntreprise,
        SideBarEntreprise, 
    }

}
</script>

<template>
  
    <div class="page-wraper">
        <HeaderEntreprise></HeaderEntreprise> 
                 
      
      <!-- Sidebar Holder -->
      <SideBarEntreprise></SideBarEntreprise>
      
  
      <!-- Page Content Holder -->
      <div id="content">
          <div class="content-admin-main">
            <div class="admin-top-area d-flex flex-wrap justify-content-between m-b30 align-items-center">
                <div class="admin-left-area">
                    <a class="nav-btn-admin d-flex justify-content-between align-items-center" id="sidebarCollapse">
                        <span class="nav-btn-text">Menu</span>
                        <span class="fa fa-reorder"></span>
                    </a>
                </div>
                <div class="admin-right-area">
                    <div class="pro-pic-info-wrap d-flex">
                        <!--<h4>Bienvenue dans votre compte entreprise: {{ entreprise.nom_entreprise }}</h4>-->
                        <h4>Bienvenue {{ connectedEntreprise.nom_entreprise}}</h4>
                        <span class="feather-icon has-toltip" @click="showLogoutConfirmation">
                            <i class="feather-power"></i>
                        </span>
                    </div>
                </div>
            </div>  
            <router-view/>
          </div>       
        </div>
    </div>       
</template>
  
<style>
  
</style>