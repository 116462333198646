import axiosClient from "../axios";

const serviceModule = {
    namespaced: true,
    
    state: {

        servicePrestataires: [],
        prestataireContrats: [],

    },
    

    getters: {

        getServicePrestataires(state) {
            return state.servicePrestataires;
        },

        getPrestataireContrats(state) {
            return state.prestataireContrats;
        },

    },

    actions: {

        async fetchPrestatairesByService({ commit }, {nomQuery, prenomsQuery, raisonSocialeQuery}) {
            try {
                const response = await axiosClient.get(`/service_prestataires?nom=${nomQuery}&prenoms=${prenomsQuery}&raison=${raisonSocialeQuery}`);
                const servicePrestataires = response.data;
                commit('setServicePrestataire', servicePrestataires);
            } catch (error) {
                console.error(error);
            }
        },

        async fetchPrestataireContrats({ commit }, { prestataireId, referencesQuery, statutQuery}) {
            try {
                const response = await axiosClient.get(`/service/prestataires/${prestataireId}/contrats?references=${referencesQuery}&statut=${statutQuery}`);
                const prestataireContrats = response.data;
                commit('setPrestataireContrats', prestataireContrats);
            } catch (error) {
                console.error(error);
            }
        },

    },

    mutations: {

        setServicePrestataire(state, servicePrestataires) {
            state.servicePrestataires = servicePrestataires;
        },

        setPrestataireContrats(state, prestataireContrats){
            state.prestataireContrats = prestataireContrats;
        }

    },

};

export default serviceModule;