<script>
import Register from '../views/Register.vue';
import Login from '../views/Login.vue';
export default {
    components: {Register, Login}

}
</script>


<template> 

  <div class="modal fade" id="login-signup-model">

    <div class="modal-dialog" >

        <div class="modal-content" >

            <button type="button" class="close aon-login-close" data-dismiss="modal" aria-label="Close">

                <span aria-hidden="true">×</span>

            </button>

            <div class="modal-body">
                <div class="sf-custom-tabs sf-custom-new aon-logon-sign-area p-3">

                    <ul class="nav nav-tabs nav-table-cell">

                        <li><a data-toggle="tab" href="#Upcoming" class="active">CONNEXION</a></li>

                        <li><a data-toggle="tab" href="#Past">INSCRIPTION</a></li>

                    </ul>
                    <div class="tab-content">
                       
                        <Login></Login>
                       
                        <!--Sign up Form-->
                        <div id="Past" class="tab-pane">
                            <Register></Register> 
                        </div>

                    </div>
            </div>

        </div>

    </div>

    </div>

</div>

</template>

<style>

</style>