<script setup>
import { computed } from 'vue';
import { useStore } from 'vuex';
import { BACK_URL } from '@/constant';
    
    const store = useStore()
    const connectedService = computed(() =>
        store.getters.getServiceUser
    );

</script>

<template>
    <header id="header-admin-wrap" class="header-admin-fixed">
      
      <!-- Header Start -->
      <div id="header-admin">
          <div class="container">
              
              <!-- Left Side Content -->
              <div class="header-left">
                  
                  <div class="my-account-logo">
                      <a href="/"><img src="/images/logo-dark.png" alt=""></a>
                  </div>
                  
              </div>
              <!-- Left Side Content End -->
              
              <!-- Right Side Content -->
              <div class="header-right">
                  
                  <div class="header-menu">
                      <!-- NAV Toggle Button -->
                      <button id="mobile-side-drawer" data-target=".header-nav" data-toggle="collapse" type="button" class="navbar-toggler collapsed">
                          <span class="sr-only">Toggle navigation</span>
                          <span class="icon-bar icon-bar-first"></span>
                          <span class="icon-bar icon-bar-two"></span>
                          <span class="icon-bar icon-bar-three"></span>
                      </button> 

                      <!-- MAIN Vav -->
                      <div class="nav-animation header-nav navbar-collapse collapse d-flex justify-content-start">

                          <ul class=" nav navbar-nav">
                              <li class="has-child current-menu-item">
                                <a href="/">ACCEUIL</a>
                              </li>
                              <li>
                                <a href="/a-propos">A PROPOS</a>                             
                              </li>
                              <li>
                                <a href="/contact">CONTACTEZ-NOUS</a>                                                               
                              </li>
                      
                          </ul>

                      </div>
                  </div>
                  
                  <ul class="header-widget-wrap">
                    
                      <li class="header-widget">
                          <div class="aon-admin-messange sf-toogle-btn">
                              <span class="feather-user-pic"><img :src="connectedService.visa_resp ? BACK_URL + 'storage/visa_resp/'+ connectedService.visa_resp : '/images/user.jpg'" alt=""/></span>
                          </div>
                          <div class="ws-toggle-popup popup-tabs-wrap-section user-welcome-area">
                              <ul class="user-welcome-list">
                                  <li><strong>Welcome , <span class="site-text-primary">Alica Noory</span></strong></li>
                                  <li><a href="#"><i class="feather-sliders"></i> Dashboard</a></li>
                                  <li><a href="#"><i class="feather-file"></i> Add Listing</a></li>
                                  <li><a href="#"><i class="feather-settings"></i> Setting</a></li>
                                  <li><a href="#"><i class="feather-log-out"></i> Log Out</a></li>
                              </ul>
                          </div>
                      </li>
                      

                  </ul>
              </div>
              <!-- Right Side Content End -->
  
          </div>
      </div>
      <!-- Header End -->
  
    </header> 
</template>

<style>
</style>