<script setup>
import { useStore } from 'vuex';
import { computed, ref, onMounted, watch, reactive} from "vue"
import { useRouter } from 'vue-router';
import axiosClient from '@/axios';
import { BACK_URL } from '@/constant';
import { useSwalSuccess, useSwalError } from '@/composables/alert';
import Swal from 'sweetalert2';

const store = useStore();
const router = useRouter();
const perPage = 5;
const isTacheModalOpen = ref(false);
const isTableLoading = ref(false);
const isSearching = ref(false);
const isEdition = ref(false);
const selectedTacheId = ref(null);
const dateDebutQuery = ref('');
const dateFinQuery = ref('');
const statutQuery = ref('');
const isTacheSaving = ref(false);
const isTacheUpdating = ref(false);
const currentPage = ref(1);

const tacheData = reactive({
    description: "",
    date_debut: "",
    date_fin: "",
    operateur_tache: "",
    fonction_operateur: "",
    objet_id: "",
    rapport: "",
});

const prestataireTaches = computed(() => {
    return store.state.tacheModule.prestataireTaches;
});

const objets = computed(() => 
    store.state.prestataireModule.tacheContratObjets
);

const tacheFormErrors = computed(() => {
    return store.state.tacheModule.tacheErrors;
});


const paginatedGroupedTaches = computed(() => {
    const startIndex = (currentPage.value - 1) * perPage;
    const endIndex = startIndex + perPage;
    return Object.keys(prestataireTaches.value).slice(startIndex, endIndex).reduce((obj, key) => {
      obj[key] = prestataireTaches.value[key];
      return obj;
    }, {});
});

const totalPages = computed(() => Math.ceil(Object.keys(prestataireTaches.value).length / perPage));

function previousPage() {
    if (currentPage.value > 1) {
        currentPage.value--;
    }
}

function nextPage() {
    if (currentPage.value < totalPages.value) {
        currentPage.value++;
    }
}

function editTache(tache) {
    store.dispatch('prestataireModule/fetchTacheContratObjets', tache.id)
    .then(()=>{
        selectedTacheId.value = tache.id;
        isEdition.value = true;
        tacheData.description = tache.description;
        tacheData.date_debut = tache.date_debut;
        tacheData.date_fin = tache.date_fin;
        tacheData.operateur_tache = tache.operateur_tache;
        tacheData.fonction_operateur = tache.fonction_operateur;
        tacheData.objet_id = tache.objet_id;
        isTacheModalOpen.value = true;
    })
}

function shortenText(name, maxLength = 100) {
    if (name.length > maxLength) {
        return name.substring(0, maxLength - 3) + '...';
    }
        return name;
}

function updateTache() {
    Swal.fire({
        title: 'Êtes-vous sûr?',
        text: "Les modifications seront enregistées ensuite",
        icon: 'question',
        showCloseButton: true,
        showCancelButton: true,
        confirmButtonColor: '#022279', //'#ffc107',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Continuer',
        cancelButtonText: 'Annuler'
    }).then((result) => {
        if (result.isConfirmed) {
            if (selectedTacheId.value != null) {
                isTacheUpdating.value = true;
                const formData = new FormData();
                formData.append('description', tacheData.description);
                formData.append('date_debut', tacheData.date_debut);
                formData.append('date_fin', tacheData.date_fin);
                formData.append('operateur_tache', tacheData.operateur_tache);
                formData.append('fonction_operateur', tacheData.fonction_operateur);
                formData.append('objet_id', tacheData.objet_id);
                formData.append('rapport', tacheData.rapport);
                formData.append('_method', 'put');
                axiosClient.post(`/taches/${selectedTacheId.value}`, formData)
                    .then((res) => {
                        if (res.status = 201) {
                            useSwalSuccess('La tâche a été modifiée avec succès');
                            resetTacheForm();
                            closeTacheModal();
                            store.dispatch('tacheModule/fetchTachesByPrestataire', { dateDebutQuery: dateDebutQuery.value, dateFinQuery: dateFinQuery.value, statutQuery: statutQuery.value})
                        }
                    }).catch((error) => {
                        if (error.response.status == 422) {
                            store.commit('tacheModule/setTacheErrors', error.response.data.errors);
                        }
                    }).finally(() => {
                        isTacheUpdating.value = false;
                    })
            }
        }
    });
}

async function relancerValidation(taskId) {
    await axiosClient.put(`/tache/${taskId}/relancervalidation`)
        .then((response)=>{
            if (response.status === 201) {
                Swal.fire({
                    title: 'Succès',
                    text: 'Votre demande de validation à bien été envoyée au service',
                    icon: 'success',
                    confirmButtonColor: '#022279', //'#ffc107',
                    confirmButtonText: 'OK'
                });
            }
        }).catch ((error) => {
            console.error('Erreur lors de la relance de l\'exécution de la tâche :', error);
        })
}

const rapportInputChanged = (e) =>{
    const files = e.target.files
    if (!files || !files.length) return
      tacheData.rapport = files[0];
}

function deleteTache(id) {
    Swal.fire({
        title: 'Êtes-vous sûr?',
        text: "Cette tache sera supprimée ensuite!",
        icon: 'question',
        showCloseButton: true,
        showCancelButton: true,
        confirmButtonColor: '#022279', //'#ffc107',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Continuer',
        cancelButtonText: 'Annuler'
    }).then((result) => {
        if (result.isConfirmed) {
            axiosClient.delete(`/taches/${id}`)
                .then((res) => {
                    if (res.status == 204) {
                        store.dispatch('tacheModule/fetchTachesByPrestataire', { dateDebutQuery: dateDebutQuery.value, dateFinQuery: dateFinQuery.value, statutQuery: statutQuery.value,})
                            .then(() => {
                                useSwalSuccess(`La tâche a été supprimée avec succès`);
                            })
                    } else if (res.status == 203) {
                        Swal.fire({
                            title: 'Erreur',
                            text: 'Cette tâche ne peut être supprimé.',
                            icon: 'error',
                            confirmButtonColor: '#022279',
                            confirmButtonText: 'OK'
                        });
                    }
                }).catch(error => {
                    Swal.fire({
                        title: 'Erreur',
                        text: 'Un problème est survenu lors de la suppresion, vérifiez votre connexion internet puis réessayez',
                        icon: 'error',
                        confirmButtonColor: '#022279',
                        confirmButtonText: 'OK'
                    });
                });
        }
    })
}

function closeTacheModal() {
    isTacheModalOpen.value = false;
    isEdition.value = false;
    resetTacheForm();
}

function resetTacheForm() {
    store.commit('tacheModule/setTacheErrors', {});
    tacheData.description = "";
    tacheData.date_debut = "";
    tacheData.date_fin = "";
    tacheData.operateur_tache = "";
    tacheData.fonction_operateur = "";
    tacheData.objet_id = null;
    tacheData.rapport = "";
}

function goBack() {
    router.back();
}

 async function downloadRapport(rapportPath) {
    await axiosClient.get(`/download-tache-rapport?path=${rapportPath}` , {responseType: 'blob'})
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', rapportPath);
        document.body.appendChild(link);
        link.click();
      }).catch(error => {
        console.error('Erreur lors du téléchargement du fichier :', error);
      }).finally(() => {

      });
}

watch(dateDebutQuery, async (newValue) => {
    isSearching.value = true
    await store.dispatch('tacheModule/fetchTachesByPrestataire', { 
     dateDebutQuery: dateDebutQuery.value, dateFinQuery: dateFinQuery.value, statutQuery: statutQuery.value
    }).then(()=>{
        isSearching.value = false;
    })
});

watch(dateFinQuery, async (newValue) => {
    isSearching.value = true
    await store.dispatch('tacheModule/fetchTachesByPrestataire', { 
     dateDebutQuery: dateDebutQuery.value, dateFinQuery: dateFinQuery.value, statutQuery: statutQuery.value
    }).then(()=>{
        isSearching.value = false;
    })
});

watch(statutQuery, async (newValue) => {
    isSearching.value = true
    await store.dispatch('tacheModule/fetchTachesByPrestataire', { 
     dateDebutQuery: dateDebutQuery.value, dateFinQuery: dateFinQuery.value, statutQuery: statutQuery.value
    }).then(()=>{
        isSearching.value = false;
    })
});

onMounted(async () => {
    isTableLoading.value = true
    await store.dispatch('tacheModule/fetchTachesByPrestataire', { 
        dateDebutQuery: dateDebutQuery.value, dateFinQuery: dateFinQuery.value, statutQuery: statutQuery.value
    }).then(()=>{
        isTableLoading.value = false;
        const response =  axiosClient.get('prestataire-tache-stats')
        console.log(response.data);
    })

});

</script>

<template>
    <div>
        <div class="aon-admin-heading">
            <div class="d-flex justify-content-start align-items-center">
                <button class="btn btn-light btn-lg mr-2" type="button" @click="goBack()">
                    <i class="feather-arrow-left"></i>
                </button>
                <h3 class="text-center">MES PRESTATIONS</h3>
            </div>
        </div>
        <div class="card aon-card">
            <div class="card-header aon-card-header">
                <div class="aon-card-header aon-blog-style-1 d-flex justify-content-between align-items-center flex-wrap" style="background-color: rgba(50, 50, 168, 0.2); border: 1px solid rgba(50, 50, 168, 0.5); border-radius: 10px;">
                    <div>
                        <h4><i class="fa fa-list"></i> LISTE DES PRESTATIONS</h4>
                    </div>
                </div>
            </div>
            
            <div class="card-body aon-card-body">
                <div class="pb-3 px-3 mb-4" style="background-color: rgba(50, 50, 168, 0.2); border: 1px solid rgba(50, 50, 168, 0.5); border-radius: 10px;">
                    <div class="py-3 d-flex justify-content-between align-items-center flex-wrap">
                        <h5>RECHERCHER UNE PRESTATION</h5>
                        <button class="btn btn-secondary" @click="clearFilters">Effacer les filtres</button>
                    </div>
                    <div class="row">
                        <div class="col-lg-4">
                            <div class="form-group">
                                <label>DU</label>
                                <input type="date" class="form-control" v-model="dateDebutQuery">
                            </div>
                        </div>
                        <div class="col-lg-4">
                            <div class="form-group">
                                <label>AU</label>
                                <input type="date" class="form-control" v-model="dateFinQuery">
                            </div>
                        </div>
                        <div class="col-lg-4">
                            <div class="form-group">
                                <label>STATUT</label>
                                <select class="form-control" v-model="statutQuery">
                                    <option :value = "''" >Tout</option>
                                    <option :value = "'en cours'" >En cours</option>
                                    <option :value="'terminee'">Terminée</option>
                                    <option :value="'planifiee'">Planifiée</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-if="isSearching" class="text-center align-middle mb-2">
                    <i class="fa fa-2x fa-spinner fa-spin mr-2"></i> Recherche en cours...
                </div>
                <div v-if="prestataireTaches != ''">
                    <div v-for="(group, date) in paginatedGroupedTaches" :key="date" class="mt-3">
                        <h3>{{ date }}</h3><hr>
                        <div class="row">
                            <div v-for="(tache, index) in group" :key="index" class="col-lg-6">
                                <div class="aon-post-jobsCol mba-bdr-15">
                                    <div class="aon-post-jobs2 aon-icon-effect" :class="{'finished-task': tache.statut == 'terminee', 'pending-task': tache.statut == 'en cours', 'scheduled-task': tache.statut == 'planifiee'}">
                                        <div class="job-comapny-logo mr-2">
                                            <img class="company_logo aon-icon" :src="BACK_URL + 'storage/logo/' + tache.contrat.entreprise.logo" alt="">
                                        </div>
                                        <p style="color: #000000; font-weight: 800;" class="text-start">
                                            {{ tache.date_debut }} au {{ tache.date_fin }}<br />
                                            <span v-if="tache.objet.niveau_priorite == 'normal'" class="badge badge-secondary mr-2">Normal</span>
                                            <span v-if="tache.objet.niveau_priorite == 'urgent'" class="badge badge-warning mr-2">Urgent</span>
                                            <span v-if="tache.objet.niveau_priorite == 'critique'" class="badge badge-danger mr-2">Critique</span>
                                            <span v-if="tache.statut == 'en cours'" class="badge badge-primary">En cours</span>
                                            <span v-if="tache.statut == 'terminee'" class="badge badge-success">Terminée</span>
                                            <span v-if="tache.statut == 'planifiee'" class="badge badge-info">Planifiée</span>
                                        </p>
                                        <div class="text-start py-3">
                                            <div style="color: #000000; overflow: hidden; font-size: larger; display: flex; align-items: center;">
                                                {{ shortenText(tache.description) }}
                                            </div>
                                        </div>
                                        <div class="d-flex justify-content-between align-items-center flex-wrap">
                                            <div class="mt-2">
                                                <a type="button" class="btn admin-button mr-1"
                                                    :href="'/contrats/detailstaches/' + tache.id">
                                                    <i class="fa fa-eye"></i>
                                                    Détails
                                                </a>
                                            </div>
                                            <div class="mt-2">
                                                <div v-if="tache.statut == 'terminee'" class="job-comapny-logo mr-1">
                                                    <img class="company_logo aon-icon" :src="BACK_URL + 'storage/visa_resp/' + tache.contrat.service.visa_resp" alt="">
                                                </div>
                                                <button v-else-if="tache.statut == 'planifiee'" class="btn admin-button-info">
                                                    TÂCHE EXÉCUTÉE
                                                </button>
                                                <button v-else type="button" class="btn admin-button-secondry" @click="relancerValidation(tache.id)">
                                                    RELANCER LA DEMANDE DE VALIDATION
                                                </button>
                                            </div>
                                        </div>
                                        <div class="dropdown action-dropdown dropdown-left">
                                            <button class="action-button gray dropdown-toggle" type="button" data-toggle="dropdown"
                                                aria-expanded="true"><i class="fa fa-ellipsis-v"></i></button>
                                            <ul class="dropdown-menu">
                                                <li><a @click="editTache(tache)" href="javascript:;"><i class="feather-edit text-primary"></i> Editer</a></li>
                                                <li><a @click="deleteTache(tache.id)" href="javascript:;"><i class="feather-trash text-danger"></i> Supprimer</a></li>
                                                <li v-if="tache.rapport != null" class="mx-1">
                                                    <button class="btn btn-sm btn-light" @click="downloadRapport(tache.rapport)">
                                                        <i class=" fa fa-download text-success"></i> Télécharger Rapport
                                                    </button>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr>
                    <div class="d-flex justify-content-between align-items-center">
                        <button class="btn admin-button sm:btn-sm" @click="previousPage" :disabled="currentPage === 1">
                            <i class="feather-arrow-left"></i>
                            Précédent
                        </button>
                        {{ currentPage }} sur {{ totalPages }} pages
                        <button class="btn admin-button sm:btn-sm" @click="nextPage" :disabled="currentPage === totalPages">
                            Suivant
                            <i class="feather-arrow-right"></i>
                        </button>
                    </div>
                </div>
                <div v-else>
                    <div v-if="isTableLoading" class="text-center">
                        <i class="fa fa-2x fa-spinner fa-spin mr-2"></i> Chargement
                    </div>
                    <div v-else class="my-2 alert alert-warning">
                        Aucune tâche retrouvée.
                    </div>
                </div>
            </div>
        </div>

        <div v-if="isTacheModalOpen" style="
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 1000;
            background-color: rgba(0, 0, 0, 0.5);
            align-items: center;
            justify-content: center;">
            <div class="modal-dialog modal-lg  modal-dialog-centered modal-dialog-popout modal-dialog-scrollable"
                role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel2">{{ !isEdition ? 'NOUVELLE TÂCHE' : 'MODIFIER TÂCHE'}}</h5>
                        <button @click="closeTacheModal()" type="button" class="close" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>

                    <form id="tacheForm" method="post" @submit.prevent="saveTache()" enctype="multipart/form-data">
                    <div class="modal-body">
                        <div class="sf-md-padding">
                            <div class="row">
                                    <div class="col-lg-6">
                                        <div class="form-group">
                                            <label>Date de début</label>
                                            <input type="date" class="form-control" v-model="tacheData.date_debut"
                                                :class="{ 'is-invalid': tacheFormErrors.date_debut }">
                                            <span v-if="tacheFormErrors.date_debut" class="invalid-feedback">{{ tacheFormErrors.date_debut[0] }}</span>
                                        </div>
                                    </div>
                                    <div class="col-lg-6">
                                        <div class="form-group">
                                            <label>Date de fin</label>
                                            <input type="date" class="form-control" v-model="tacheData.date_fin"
                                                :class="{ 'is-invalid': tacheFormErrors.date_fin }">
                                            <span v-if="tacheFormErrors.date_fin" class="invalid-feedback">{{
                                                tacheFormErrors.date_fin[0] }}</span>
                                        </div>
                                    </div>
                                    <div class="col-lg-6">
                                        <div class="form-group">
                                            <label>Opérateur de la tâche</label>
                                            <input type="text" class="form-control" v-model="tacheData.operateur_tache"
                                                :class="{ 'is-invalid': tacheFormErrors.operateur_tache }">
                                            <span v-if="tacheFormErrors.operateur_tache" class="invalid-feedback">{{
                                                tacheFormErrors.operateur_tache[0] }}</span>
                                        </div>
                                    </div>
                                    <div class="col-lg-6">
                                        <div class="form-group">
                                            <label>Fonction de l'opérateur</label>
                                            <input type="text" class="form-control" v-model="tacheData.fonction_operateur"
                                                :class="{ 'is-invalid': tacheFormErrors.fonction_operateur }">
                                            <span v-if="tacheFormErrors.fonction_operateur" class="invalid-feedback">{{
                                                tacheFormErrors.fonction_operateur[0] }}</span>
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <div class="form-group">
                                            <label>Objet de la tâche</label>
                                            <select class="form-control" name="objet" v-model="tacheData.objet_id" :class="{'is-invalid':tacheFormErrors.objet_id}">
                                                <option :value = null ></option>
                                                <option v-for="objet in objets" :key="objet.id" :value="objet.id">
                                                    {{ objet.intitule }}</option>
                                            </select> 
                                            <span v-if="tacheFormErrors.objet_id" class="invalid-feedback">{{ tacheFormErrors.objet_id[0]}}</span>
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <div class="form-group">
                                            <label>Description de la tâche</label>
                                            <textarea class="form-control" rows="3" v-model="tacheData.description" :class="{'is-invalid':tacheFormErrors.description}"></textarea>  
                                            <span v-if="tacheFormErrors.description" class="invalid-feedback">{{ tacheFormErrors.description[0]}}</span>
                                        </div>
                                    </div>
                                </div>
                        </div>
                    </div>
                    <div class="modal-footer d-flex justify-content-between align-items-center">
                        <div>
                            <label for="tache-fichier" @click="capturePhoto" class="btn admin-button-secondry"><i class="fa fa-upload mr-1"></i>Joindre un rapport</label>
                            <input class="form-control" type="file" id="tache-fichier" ref="fileInput" style="display: none" accept=".pdf,.xlsx,.docx,.doc" @change="rapportInputChanged">
                        </div>
                        <div>
                            <button type="button" @click="closeTacheModal()" class="btn btn-warning mr-2">Annuler</button>
                            <button v-if="isEdition" type="button" @click="updateTache()" class="btn btn-warning" :disabled="isTacheUpdating">
                                <i v-if="isTacheUpdating" class="fa fa-fw fa-spinner fa-spin mr-1"></i>
                                <i v-else class="fa fa-save"></i>
                                {{ isTacheUpdating ? 'Enregistrement...' : 'Enregistrer les modifications' }}
                            </button>
                            <button v-else type="submit" form="tacheForm" class="btn btn-warning" :disabled="isTacheSaving">
                                <i v-if="isTacheSaving" class="fa fa-fw fa-spinner fa-spin mr-1"></i>
                                <i v-else class="fa fa-save"></i>
                                {{ isTacheSaving ? 'Enregistrement...' : 'Enregistrer' }}
                            </button>
                        </div>
                    </div>
                </form>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
.finished-task{
    background-color: rgba(50, 168, 103, 0.164);
}

.pending-task{
   background-color: rgba(50, 50, 168, 0.164);
    
}

.scheduled-task{
    background-color: rgba(168, 152, 50, 0.164);
}
</style>
