import axiosClient from "../axios";

const prestataireModule = {
    namespaced: true,
    
    state: {
        contrats: [],
        contratDetails: {},
        contratPrestations: [],
        isContratCreated: false,
        isObjetCreated: false,
        contratErrors: {},
        objetErrors: {},
        taches: [],
        details: [],
        rapportItems: 0,
        objets: [],
        tacheContratObjets: [],
        servicePrestataires: [],
        entrepriseServices: [],
        adminEntreprises: [],
    },
    

    getters: {

        getContrats(state) {
            return state.contrats;
        },

        getContratDetails(state){
            return state.contratDetails;
        },

        getContratPrestatoins(state) {
            return state.contratPrestatoins;
        },

        getTaches(state) {
            return state.taches;
        },

        getDetailsTaches(state) {
            return state.details;
        },

        getRapportItem(state){
            return state.rapportItem;
        },

        getContratObjets(state){
            return state.objets;
        },

        getTacheContratObjetsObjets(state){
            return state.tacheContratObjets;
        },
        getServicePrestataires(state){
            return state.servicePrestataires;   
        },
        getEntrepriseServices(state){
            return state.entrepriseServices;   
        },
        getAdminEntreprises(state){
            return state.adminEntreprises;   
        }
    },

    actions: {

        async fetchContrats({ commit }, { enterpriseQuery, serviceQuery, referencesQuery }) {
            try {
                const params = {
                    ent: enterpriseQuery,
                    serv: serviceQuery,
                    ref: referencesQuery
                };
                const response = await axiosClient.get(`/prestataire-contrats`, {params: params});
                const contrats = response.data;
                //console.log(contrats);
                commit('setContrats', contrats);
            } catch (error) {
                console.error(error);
            }
        },

        async createContrat({ commit }, contratData) {
            await axiosClient.post(`/contrats`, contratData)
                .then((res) => {
                    if (res.status) {
                        if (res.status == 201) {
                            commit('setIsContratCreated', true)
                        }
                    } else {
                        commit('setIsContratCreated', false)
                        if (res.response.status == 422) {
                            const contratErrors = res.response.data.errors
                            console.log(contratErrors)
                            commit('setContratErrors', contratErrors);
                        } else if (res.response.status == 500) {
                            const message = res;
                            console.log(message)
                        }
                    }
                }).catch((error) => {
                    //console.log(error)
                    if (error.response.status == 422) {
                        commit('setContratErrors', error.response.data.errors);
                    }
                }).finally(() => {})
        }, 

        async createObjet({ commit }, objetData) {
            await axiosClient.post(`/objets`, objetData)
                .then((res) => {
                    if (res.status) {
                        if (res.status == 201) {
                            commit('setIsObjetCreated', true)
                        }
                    } else {
                        commit('setIsObjetCreated', false)
                        if (res.response.status == 422) {
                            const objetErrors = res.response.data.errors
                            console.log(objetErrors)
                            commit('setObjetErrors', objetErrors);
                        } else if (res.response.status == 500) {
                            const message = res;
                            console.log(message)
                        }
                    }
                }).catch((error) => {
                    if (error.response.status == 422) {
                        commit('setObjetErrors', error.response.data.errors);
                    }
                }).finally(() => {})
        },
        
        async fetchContratDetails({ commit }, contratId ) {
            try {
                const response = await axiosClient.get(`/contrats-prestataire/${contratId}`);
                const contratDetails = response.data;
                commit('setContratDetails', contratDetails);
            } catch (error) {
                console.error(error);
            }
        },

        async fetchContratPrestations({ commit }, contratId ) {
            try {
                const response = await axiosClient.get(`/contrats/${contratId}/prestations`);
                const contratPrestations = response.data;
                commit('setContratPrestations', contratPrestations);
            } catch (error) {
                console.error(error);
            }
        },

        async fetchTachesByContratId({ commit }, contratId) {
            try {
                const response = await axiosClient.get(`/contrats/${contratId}/taches`);
                const taches = response.data;
                commit('setTaches', taches);
            } catch (error) {
                console.error(error);
            }
        },

        async fetchTacheDetails({ commit }, tacheId) {
            try {
                const response = await axiosClient.get(`/taches/${tacheId}`);
                const details = response.data;
                commit('setDetailsTaches', details);
            } catch (error) {
                console.error(error);
            }
        },

        async fetchRapportItem({ commit }) {
            try {
                const response = await axiosClient.get(`/rapport-item`);
                const rapportItem = response.data;
                commit('setRapportItem', rapportItem);
            } catch (error) {
                console.error(error);
            }
        },

        async fetchContratObjets({ commit }, contratId) {
            try {
                const response = await axiosClient.get(`/contrat_objects/${contratId}`);
                commit('setContratObjets', response.data);
            } catch (error) {
                console.error(error);
            }
        },

        async fetchTacheContratObjets({ commit }, tacheId) {
            try {
                const response = await axiosClient.get(`/tache_contrat_objects/${tacheId}`);
                commit('setTacheContratObjets', response.data);
            } catch (error) {
                console.error(error);
            }
        },

        async fetchServicePrestataires({commit}){
            try {
                const response = await axiosClient.get(`/service_prestataires`)
                commit('setServicePrestataires', response.data)
            } catch (error) {
                console.error(error);
            }
        },

        async fetchEntrepriseServices({commit}){
            try {
                const response = await axiosClient.get(`/entreprise_services`)
                commit('setEntrepriseServices', response.data)
            } catch (error) {
                console.error(error);
            }
        },

        async fetchAdminEntreprises({commit}){
            try {
                const response = await axiosClient.get(`/admin_entreprises`)
                commit('setAdminEntreprises', response.data)
            } catch (error) {
                console.error(error);
            }
        }
    },

    mutations: {

        setContrats(state, contrats) {
            state.contrats = contrats;
        },

        setContratDetails(state, contratDetails) {
            state.contratDetails = contratDetails;
        },

        setContratPrestations(state, contratPrestations) {
            state.contratPrestations = contratPrestations;
        },

        setContratErrors(state, contratErrors) {
            state.contratErrors = contratErrors;
        },

        setObjetErrors(state, objetErrors) {
            state.objetErrors = objetErrors;
        },
        
        setIsContratCreated(state, isContratCreated) {
            state.isContratCreated = isContratCreated;
        },

        setIsObjetCreated(state, isObjetCreated) {
            state.isObjetCreated = isObjetCreated;
        },

        setTaches(state, taches) {
            state.taches = taches;
        },

        setDetailsTaches(state, details) {
            state.details = details;
        },

        setRapportItem(state, item) {
            state.rapportItem = item;
        },

        setContratObjets(state, payload){
            state.objets = payload;
        },

        setTacheContratObjets(state, payload){
            state.tacheContratObjets = payload;
        },

        setServicePrestataires(state, payload){
            state.servicePrestataires = payload;
        },

        setEntrepriseServices(state, payload){
            state.entrepriseServices = payload;
        },

        setAdminEntreprises(state, payload){
            state.adminEntreprises = payload;
        }
    },

};

export default prestataireModule;