import axiosClient from "../axios";

const entrepriseModule = {
  namespaced: true,
  state: {
    entreprisesWithServices: [],
  },

  getters: {

    getEntreprisesWithServices(state){
      return state.entreprisesWithServices;
    }

  },
 
  actions: {

    async fetchEntreprisesWithServices({ commit }) {
      try {
          const response = await axiosClient.get(`/entrps-with-servs`);
          commit('setEntreprisesWithServices', response.data);
      } catch (error) {
          console.error(error);
      }
    },

  },

  mutations: {

    setEntreprisesWithServices(state, payload){
      state.entreprisesWithServices = payload;
    }

  },
 
};

export default entrepriseModule;
