<script>
import { useStore, mapGetters } from "vuex";
import { computed, onMounted, ref, watch } from "vue";
import { useRouter } from "vue-router";
import Swal from 'sweetalert2';
import { BACK_URL } from "@/constant";

export default {
  setup() {
        const store = useStore();
        const router = useRouter();
        const actualRoute = ref(null)

        const connectedPrestataire = computed(() =>
            store.getters.getPrestataireUser
        );

        const userRole = store.getters.getUserRole;
        const isLoggedIn = store.getters.isLoggedIn;

        function showLogoutConfirmation() {
            Swal.fire({
                title: 'Êtes-vous sûr de vouloir vous déconnecter ?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Oui',
                cancelButtonText: 'Non',
                confirmButtonColor: '#e04f1a',

            }).then((result) => {
                if (result.isConfirmed) {
                    logout();
                }
            });
        }

        onMounted(() => {
            if (router.currentRoute.value.name == "accueil") {
                actualRoute.value = 1
            }else if (router.currentRoute.value.name  == "a-propos"){
                actualRoute.value = 2
            }else if (router.currentRoute.value.name == "contact"){
                actualRoute.value = 3
            }else{
                
            }

        });
        watch(router.currentRoute, ()=>{
            if (router.currentRoute.value.name = "accueil") {
                actualRoute.value = 1
            }else if (router.currentRoute.value.name  = "a-propos"){
                actualRoute.value = 2
            }else if (router.currentRoute.value.name ="contact"){
                actualRoute.value = 3
            }else{
                
            }
        })


        function logout() {
            store.dispatch("logout").then(() => {
                router.push({
                    name: "acceuil",
                });
            });
        }
        
        return {
            user: computed(() => store.state.user.data),
            showLogoutConfirmation,
            logout,
            BACK_URL,
            connectedPrestataire,
            userRole,
            isLoggedIn,
            actualRoute
        };
    },
}
</script>

<template>
      <header id="header-admin-wrap" class="header-admin-fixed">
      
      <!-- Header Start -->
      <div id="header-admin">
          <div class="container">
              
              <!-- Left Side Content -->
              <div class="header-left">
                  
                  <div class="my-account-logo">
                      <a href="/"><img src="/images/logo-dark.png" alt=""></a>
                  </div>
                  
              </div>
              <!-- Left Side Content End -->
              
              <!-- Right Side Content -->
              <div class="header-right">
                  
                  <div class="header-menu">
                      <!-- NAV Toggle Button -->
                      <button id="mobile-side-drawer" data-target=".header-nav" data-toggle="collapse" type="button" class="navbar-toggler collapsed">
                          <span class="sr-only">Toggle navigation</span>
                          <span class="icon-bar icon-bar-first"></span>
                          <span class="icon-bar icon-bar-two"></span>
                          <span class="icon-bar icon-bar-three"></span>
                      </button> 

                      <!-- MAIN Vav -->
                      <div class="nav-animation header-nav navbar-collapse collapse d-flex justify-content-start">
                          <ul class="nav navbar-nav">
                              <li :class="{'has-child current-menu-item' : actualRoute == 1}">
                                <a href="/">ACCEUIL</a>
                              </li>
                              <li :class="{'has-child current-menu-item' : actualRoute == 2}">
                                <a href="/a-propos">A PROPOS</a>                             
                              </li>
                              <li :class="{'has-child current-menu-item' : actualRoute == 3}">
                                <a href="/contact">CONTACTEZ-NOUS</a>                                                               
                              </li>
                          </ul>

                      </div>
                  </div>
                  
                  <ul class="header-widget-wrap">
                      <li class="header-widget">
                          <div class="aon-admin-messange sf-toogle-btn">
                              <span class="feather-user-pic"><img :src="connectedPrestataire.photo ? BACK_URL + 'storage/photoProfile/'+ connectedPrestataire.photo : '/images/user.jpg'" alt=""/></span>
                          </div>
                          <div class="ws-toggle-popup popup-tabs-wrap-section user-welcome-area">
                              <ul class="user-welcome-list">
                                  <li><strong>Welcome , <span class="site-text-primary">Alica Noory</span></strong></li>
                                  <li><a href="#"><i class="feather-sliders"></i> Dashboard</a></li>
                                  <li><a href="#"><i class="feather-file"></i> Add Listing</a></li>
                                  <li><a href="#"><i class="feather-settings"></i> Setting</a></li>
                                  <li><a href="#"><i class="feather-log-out"></i> Log Out</a></li>
                              </ul>
                          </div>
                      </li>
                      

                  </ul>
              </div>
              <!-- Right Side Content End -->
  
          </div>
      </div>
      <!-- Header End -->
    </header>
</template>

<style>

</style>