import axiosClient from "../axios";

const tacheModule = {
    namespaced: true,
    
    state: {
        taches: [],
        prestataireTaches: [],
        tacheDetails: {},
        isTacheCreated: false,
        tacheErrors: {},
    },
    

    getters: {

        getTaches(state) {
            return state.taches;
        },

        getPrestataireTaches(state) {
            return state.prestataireTaches;
        },

        getTacheDetails(state){
            return state.tacheDetails;
        },
    },

    actions: {
        async createTache({ commit }, tacheData) {
            await axiosClient.post(`/taches`, tacheData)
                .then((res) => {
                    console.log(res)
                    if (res.status) {
                        if (res.status == 201) {
                            commit('setIsTacheCreated', true)
                        }
                    } else {
                        commit('setIsTacheCreated', false)
                        if (res.response.status == 422) {
                            const tacheErrors = res.response.data.errors
                            //console.log(tacheErrors)
                            commit('setTacheErrors', tacheErrors);
                        } else if (res.response.status == 500) {
                            const message = res;
                            console.log(message)
                        }
                    }
                }).catch((error) => {
                    console.log(error)
                    if (error.response.status == 422) {
                        commit('setTacheErrors', error.response.data.errors);
                    }
                }).finally(() => {})
        },

        async scheduleTache({ commit }, tacheData) {
            await axiosClient.post(`/schedule-tache`, tacheData)
                .then((res) => {
                    console.log(res)
                    if (res.status) {
                        if (res.status == 201) {
                            commit('setIsTacheCreated', true)
                        }
                    } else {
                        commit('setIsTacheCreated', false)
                        if (res.response.status == 422) {
                            const tacheErrors = res.response.data.errors
                            //console.log(tacheErrors)
                            commit('setTacheErrors', tacheErrors);
                        } else if (res.response.status == 500) {
                            const message = res;
                            console.log(message)
                        }
                    }
                }).catch((error) => {
                    console.log(error)
                    if (error.response.status == 422) {
                        commit('setTacheErrors', error.response.data.errors);
                    }
                }).finally(() => {})
        }, 

        async fetchTachesByContratId({ commit }, {dateDebutQuery, dateFinQuery, statutQuery, contratId}) {
            try {
                const response = await axiosClient.get(`/contrats/${contratId}/taches?dateDebut=${dateDebutQuery}&dateFin=${dateFinQuery}&statut=${statutQuery}`);
                const taches = response.data;
                //console.log(taches);
                commit('setTaches', taches);
            } catch (error) {
                console.error(error);
            }
        },

        async fetchTachesByPrestataire({ commit }, {dateDebutQuery, dateFinQuery, statutQuery}) {
            try {
                const response = await axiosClient.get(`/prestataire-taches?dateDebut=${dateDebutQuery}&dateFin=${dateFinQuery}&statut=${statutQuery}`);
                const prestataireTaches = response.data;
                commit('setPrestataireTaches', prestataireTaches);
            } catch (error) {
                console.error(error);
            }
        },

        async fetchTacheDetails({ commit }, tacheId) {
            try {
              const response = await axiosClient.get(`/taches-prestataire/${tacheId}`);
              const tacheDetails = response.data;
              commit('setDetailsTaches', tacheDetails);
              return tacheDetails;
            } catch (error) {
              console.error(error);
              throw error;
            }
        },
    },

    mutations: {

        setTaches(state, taches) {
            state.taches = taches;
        },

        setPrestataireTaches(state, prestataireTaches) {
            state.prestataireTaches = prestataireTaches;
        },

        setTacheErrors(state, tacheErrors) {
            state.tacheErrors = tacheErrors;
        },
        
        setIsTacheCreated(state, isTacheCreated) {
            state.isTacheCreated = isTacheCreated;
        },

        setDetailsTaches(state, tacheDetails) {
            state.tacheDetails = tacheDetails;
        },
    },

};

export default tacheModule;