<template>
    <div class="page-wraper">
        <Header />
        <div class="page-content">
            <div class="aon-page-benner-area">
                <div class="aon-page-banner-row" style="background-image: url(images/banner/job-banner.jpg)">

                    <div class="sf-overlay-main" style="opacity:0.8; background-color:#022279;"></div>

                    <div class="sf-banner-heading-wrap">

                        <div class="sf-banner-heading-area">

                            <div class="sf-banner-heading-large">Contactez-nous</div>
                            <div class="sf-banner-breadcrumbs-nav">
                                <ul class="list-inline">
                                    <li><a href="/"> Accueil </a></li>
                                    <li>Contactez-nous</li>
                                </ul>
                            </div>

                        </div>

                    </div>

                </div>

            </div>

            <div class="aon-contact-area">

                <div class="container">

                    <div class="section-head text-center">

                        <h2 class="sf-title">CONTACT</h2>

                    </div>
                    <div class="section-content">
                        <div class="sf-contact-info-wrap">
                            <div class="row">
                                <div class="col-lg-4 col-md-6">

                                    <div class="sf-contact-info-box">

                                        <div class="sf-contact-icon">

                                            <span><img src="images/contact-us/1.png" alt=""></span>

                                        </div>

                                        <div class="sf-contact-info">

                                            <h4 class="sf-title">Localisation</h4>

                                            <p>Aïdjedo</p>

                                        </div>

                                    </div>

                                </div>
                                <div class="col-lg-4 col-md-6">

                                    <div class="sf-contact-info-box">

                                        <div class="sf-contact-icon">

                                            <span><img src="images/contact-us/2.png" alt=""></span>

                                        </div>

                                        <div class="sf-contact-info">

                                            <h4 class="sf-title">E-mail</h4>

                                            <p>contact@katufai.com</p>

                                        </div>

                                    </div>

                                </div>
                                <div class="col-lg-4 col-md-6">

                                    <div class="sf-contact-info-box">

                                        <div class="sf-contact-icon">

                                            <span><img src="images/contact-us/3.png" alt=""></span>

                                        </div>

                                        <div class="sf-contact-info">

                                            <h4 class="sf-title">Télephone</h4>

                                            <p>+229 97724561</p>
                                        </div>

                                    </div>

                                </div>

                            </div>

                        </div>

                        <div class="sf-contact-form-wrap">
                            <div class="sf-contact-form">

                                <div class="sf-con-form-title text-center">

                                    <h2 class="m-b30">LAISSER NOUS UN MESSAGE</h2>

                                </div>

                                <form @submit.prevent="handleSubmit">
                                    <div class="row">
                                        <!-- COLUMNS 2 -->
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <input type="text" name="email" placeholder="Email" class="form-control" v-model="email">
                                            </div>
                                            <div class="text-danger" v-if="errorMessage.email">{{ errorMessage.email[0] }}</div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <input type="text" name="phone" placeholder="Téléphone" class="form-control" v-model="telephone">
                                            </div>
                                            <div class="text-danger" v-if="errorMessage.telephone">{{ errorMessage.telephone[0] }}</div>
                                        </div>
                                        <div class="col-md-12">
                                            <div class="form-group">
                                                <input type="text" name="subject" placeholder="Sujet" class="form-control" v-model="sujet" >
                                            </div>
                                            <div class="text-danger" v-if="errorMessage.sujet">{{ errorMessage.sujet[0] }}</div>
                                        </div>
                                        <div class="col-md-12">
                                            <div class="form-group">
                                                <textarea name="message" placeholder="Message" class="form-control" v-model="message"></textarea>
                                            </div>
                                            <div class="text-danger" v-if="errorMessage.sujet">{{ errorMessage.sujet[0] }}</div>
                                        </div>
                                        <div class="col-md-12">
                                            <div class="g-recaptcha" data-sitekey="6LfcCr0eAAAAAN2P-3cJJC1StgxbUWvwELbMVjnp"></div>
                                        </div>
                                    </div>

                                    <div class="sf-contact-submit-btn">
                                        <button class="site-button" type="submit" :disabled="loading">
                                            <i v-if="loading" class="fa fa-fw fa-spinner fa-spin mr-1"></i> 
                                            <i v-else class="feather-arrow-right"></i> Envoyer
                                        </button>
                                    </div>
                                </form>

                            </div>
                        </div>

                    </div>
                </div>

            </div>
        </div>
        <Footer />

        <button class="scroltop"><span class="fa fa-angle-up  relative" id="btn-vibrate"></span></button>
    </div>
    <Auth />
</template>

<script setup>
import Header from "../components/Header.vue";
import Footer from "../components/Footer.vue";
import Auth from "../components/Auth.vue";

import {ref } from 'vue';
import { useStore } from 'vuex';
import Swal from 'sweetalert2';

const store = useStore();

const loading = ref(false);
const errorMessage = ref('');

const resetForm = () => {
  email.value = '';
  message.value = '';
  telephone.value = '';
  sujet.value = '';
};

const email = ref('');
const message = ref('');
const telephone = ref('');
const sujet = ref('');
const error = ref('');

const handleSubmit = async () => {
  loading.value = true;
  try {
    const contactData = {
      email: email.value,
      message: message.value,
      sujet: sujet.value,
      telephone: telephone.value,
    };
    const response =  await store.dispatch('contactModule/createMessage', contactData);

    Swal.fire({
      title: 'Message avec succès',
      icon: 'success',
    });

    resetForm();
  } catch (error) {
    errorMessage.value = error.response.data.errors;
    console.log(':',errorMessage);
    console.error(error);
  } finally {
    loading.value = false;
  }
};

</script>

<style>
</style>