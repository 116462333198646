import axios from "axios";
import store from "./store";
import router from "./router";

const axiosClient = axios.create({
    //baseURL: `http://localhost:8000/api`
    baseURL: `https://katufai.com/back/public/api`  
})
  

axiosClient.interceptors.request.use(config => {
  config.headers.Authorization = `Bearer ${store.state.user.token}`
  return config;
})

//axiosClient.interceptors.response.use(response => {
//    return response;
//}, error => {
//    if (error.response.status === 401) {
//        sessionStorage.removeItem('TOKEN')
//        router.push({ name: 'login' })
//    } else if (error.response.status === 404) {
//        router.push({ name: 'login' })
//    }
//    return error;
//})

export default axiosClient;