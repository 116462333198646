<script>
import HeaderResponsable from "./HeaderResponsable.vue"
import SideBarResponsable from "./SideBarResponsable.vue"
import { useStore, mapGetters } from "vuex";
import { computed, onMounted, ref } from "vue";
import { useRouter } from "vue-router";
import Swal from 'sweetalert2';
export default {
    setup() {
        const store = useStore();
        const router = useRouter();

        const connectedService = store.getters.getServiceUser
        const userRole = store.getters.getUserRole;
        const isLoggedIn = store.getters.isLoggedIn;
        
        const service = computed(() => store.state.servicesUser);
        onMounted(async () => {
            try {
                await store.dispatch("getServicesUser");
            } catch (error) {
                console.error("Erreur lors de la récupération des données de service :", error);
            }
        });

        function showLogoutConfirmation() {
            Swal.fire({
                title: 'Êtes-vous sûr de vouloir vous déconnecter ?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Oui',
                cancelButtonText: 'Non',
                confirmButtonColor: '#e04f1a',
                cancelButtonColor: '#ffb600'

            }).then((result) => {
                if (result.isConfirmed) {
                    logout();
                }
            });
        }



        function logout() {
            store.dispatch("logout").then(() => {
                router.push('/'); 
            });
        }

        return {
            user: computed(() => store.state.user.data),
            showLogoutConfirmation,
            logout,
            userRole,
            isLoggedIn,
            service,
            connectedService
        };
    },
    components: {
        HeaderResponsable,
        SideBarResponsable, 
    }

}
</script>

<template>
  
    <div class="page-wraper">
        <HeaderResponsable></HeaderResponsable> 
                 
      
      <!-- Sidebar Holder -->
      <SideBarResponsable></SideBarResponsable>
      
  
      <!-- Page Content Holder -->
      <div id="content">
          <div class="content-admin-main">
            <div class="admin-top-area d-flex flex-wrap justify-content-between m-b30 align-items-center">
                <div class="admin-left-area">
                    <a class="nav-btn-admin d-flex justify-content-between align-items-center" id="sidebarCollapse">
                        <span class="nav-btn-text">Menu</span>
                        <span class="fa fa-reorder"></span>
                    </a>
                </div>
                <div class="admin-right-area">
                    <div class="pro-pic-info-wrap d-flex">
                        <!--<h4>Bienvenue {{ service.resp_service }}, ({{ service.nom_service }})</h4>-->
                        <h4>Bienvenue {{ connectedService.resp_service}}</h4>
                        <span class="feather-icon has-toltip" @click="showLogoutConfirmation">
                            <i class="feather-power"></i>
                        </span>
                    </div>
                </div>
            </div>  
            <router-view/>
          </div>       
        </div>
  
    </div>       
  
  
</template>
  
<style>
  
</style>