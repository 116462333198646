<script setup>
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { ref, watch, onMounted } from 'vue';
   
    const store = useStore();
    const router = useRouter();
    const actualRoute = ref(null); 

    onMounted(() => {
        if (router.currentRoute.value.name.match('entreprise')) {
            actualRoute.value = 1
        }else if (router.currentRoute.value.name.match('responsable')) {
            actualRoute.value = 2
        }else if (router.currentRoute.value.name.match('statistiques')){
            actualRoute.value = 3
        }else if (router.currentRoute.value.name.match('profile')){
            actualRoute.value = 4
        }else{
                
        }

    });

    watch(router.currentRoute, ()=>{
      if (router.currentRoute.value.name.match('entreprise')) {
            actualRoute.value = 1
        }else if (router.currentRoute.value.name.match('responsable')) {
            actualRoute.value = 2
        }else if (router.currentRoute.value.name.match('statistiques')){
            actualRoute.value = 3
        }else if (router.currentRoute.value.name.match('profile')){
            actualRoute.value = 4
        }else{
                
        }
    })
</script>

<template>
        <nav style="background-color: #e9eef1;" id="sidebar-admin-wraper">
          <div class="admin-nav">
                <ul class="">
                  <li :class="{'active': actualRoute == 1}">
                    <a href="/entreprise"><i class="fa fa-dashboard"></i><span class="admin-nav-text">TABLEAU DE BORD</span></a>
                  </li>
                  <li :class="{'active': actualRoute == 2}">
                    <a href="/list_responsable"><i class="fa fa-cogs"></i><span class="admin-nav-text">SERVICES</span></a>
                  </li>
                  <li :class="{'active': actualRoute == 3}">
                    <a href="/entreprise/statistiques"><i class="fa fa-bar-chart"></i><span class="admin-nav-text">Statistiques</span></a>
                  </li>
                  <li :class="{'active': actualRoute == 4}">
                      <a href="/entreprise/profile"><i class="fa fa-user-circle-o"></i><span class="admin-nav-text">Profil</span></a>
                  </li>                                 
                </ul>
          </div>  
        </nav>
</template>

<style scoped>
  .admin-nav ul > li > a {padding:20px 25px; font-size:14px; font-weight:500; color: #3e556b; position:relative; display:block; text-transform:uppercase; border-top:1px solid #e9eef1; border-bottom:1px solid #e9eef1; }

  .admin-nav ul li a > i {font-size:18px; margin-right:10px; color:#3e556b; vertical-align: middle; }

  .admin-nav ul li.active a > i {font-size:18px; margin-right:10px; color:#f7fafc; vertical-align: middle; }

  .admin-nav ul > li.active > a { color: #f7fafc; background-color:#022279; border-top:1px solid #e9eef1; border-bottom:1px solid #e9eef1; }

  .admin-nav ul > li.active > a:after { content:""; position:absolute; top:20%; left:0px; width:4px; height:60%; background-color:#022279;}

  .admin-nav ul > li:hover > a { color: #022279; background-color:#f7fafc; border-top:1px solid #e9eef1; border-bottom:1px solid #e9eef1; }

  .admin-nav ul li:hover a > i {font-size:18px; margin-right:10px; color:#022279; vertical-align: middle; }

  .admin-nav ul > li:hover > a:after { content:""; position:absolute; top:20%; left:0px; width:4px; height:60%; background-color:#022279;}
</style>