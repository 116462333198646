<script>
import axios from '../../axios';

export default {
    data() {
        return {
            contrats: {
                inactif: 0,
                actif: 0,
                terminee: 0,
            },
            taches: {
                terminee: 0,
                en_cours: 0
            },
            entreprises: {
                nombre_total: 0,
            },
            prestataires: {
                nombre_total: 0,
            },
        };
    },
    mounted() {
        
        axios.get(`/admin-stat-contrat`)
            .then(response => {
                this.contrats = response.data.contrats;
            })
            .catch(error => {
                console.error('Erreur lors de la récupération des données des contrats : ', error);
            });

        axios.get(`/admin-nombre-entreprise`)
            .then(response => {
                this.entreprises.nombre_total = response.data.nombre_total;
            })
            .catch(error => {
                console.error('Erreur lors de la récupération du nombre total de entreprises : ', error);
            });

        axios.get(`/admin-nombre-prestataire`)
            .then(response => {
                this.prestataires.nombre_total = response.data.nombre_total;
            })
            .catch(error => {
                console.error('Erreur lors de la récupération du nombre total de prestataires : ', error);
            });

        axios.get(`/admin-stat-tache`)
            .then(response => {
                this.taches = response.data.taches;
            })
            .catch(error => {
                console.error('Erreur lors de la récupération des données des tâches : ', error);
            });
    }
};
</script>


<template>
    <div class="row">
        <div class="col-xl-12 col-lg-12 m-b30 break-1300">
            <div class="card aon-card">
                <div class="card-body aon-card-body">
                    <div class="row">
                        <div class="col-lg-3 m-b30">
                            <div class="panel panel-default ser-card-default">
                                <div class="panel-body ser-card-body ser-orange p-a30">
                                    <div class="ser-card-title">ENTREPRISES</div>
                                    <div class="ser-card-amount">{{ entreprises.nombre_total }} </div>
                                    <div class="ser-card-table">
                                        <div class="ser-card-right">
                                            <div class="ser-card-icon"><i class="glyph-icon flaticon-money-3"></i></div>
                                        </div>
                                    </div>
                                    <span class="ser-card-circle-bg"></span>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 m-b30">
                            <div class="panel panel-default ser-card-default">
                                <div class="panel-body ser-card-body ser-blue p-a30">
                                    <div class="ser-card-title">PRESTATAIRES</div>
                                    <div class="ser-card-amount">{{ prestataires.nombre_total }}</div>
                                    <div class="ser-card-table">
                                        <div class="ser-card-right">
                                            <div class="ser-card-icon"><i class="glyph-icon flaticon-calendar"></i></div>
                                        </div>
                                    </div>
                                    <span class="ser-card-circle-bg"></span>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 m-b30">
                            <div class="panel panel-default ser-card-default">
                                <div class="panel-body ser-card-body ser-yellow p-a30">
                                    <div class="ser-card-title">CONTRATS EN COURS</div>
                                    <div class="ser-card-amount">{{ contrats.actif }}</div>
                                    <div class="ser-card-table">
                                        <div class="ser-card-right">
                                            <div class="ser-card-icon"><i class="glyph-icon flaticon-wallet"></i></div>
                                        </div>
                                    </div>
                                    <span class="ser-card-circle-bg"></span>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 m-b30">
                            <div class="panel panel-default ser-card-default">
                                <div class="panel-body ser-card-body ser-blue p-a30">
                                    <div class="ser-card-title">CONTRATS TERMINÉS</div>
                                    <div class="ser-card-amount">{{ contrats.terminee }}</div>
                                    <div class="ser-card-table">
                                        <div class="ser-card-right">
                                            <div class="ser-card-icon"><i class="glyph-icon flaticon-calendar"></i></div>
                                        </div>
                                    </div>
                                    <span class="ser-card-circle-bg"></span>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="row">

        <div class="col-12">
            <div class="card aon-card">
                <div class="card-header aon-card-header aon-card-header2">
                    <h4><i class="feather-pie-chart"></i> Statistiques des tâches</h4>
                </div>
                <div class="card-body aon-card-body">
                    <div>
                        <ul class="list-unstyled">
                            <li class="py-3">
                                <div class="d-flex align-items-center">
                                    <div class="avatar-xs rounded-circle m-r10">
                                        <i class="feather-check-circle"></i>
                                    </div>
                                    <div class="flex-grow-1">
                                        <p class="text-muted mb-2">Terminée(s)</p>
                                        <div class="progress progress-sm animated-progess">
                                            <div class="progress-bar bg-success" role="progressbar"
                                                :style="{ width: taches.terminee + '%' }" :aria-valuenow="taches.terminee"
                                                aria-valuemin="0" aria-valuemax="100">
                                            </div>
                                        </div>
                                    </div>


                                </div>
                            </li>
                            <li class="py-3">
                                <div class="d-flex align-items-center">
                                    <div class="avatar-xs rounded-circle m-r10">
                                        <i class="feather-calendar"></i>
                                    </div>
                                    <div class="flex-grow-1">
                                        <p class="text-muted mb-2">En attente(s)</p>
                                        <div class="progress progress-sm animated-progess">
                                            <div class="progress-bar bg-warning" role="progressbar"
                                                :style="{ width: taches.en_cours + '%' }" :aria-valuenow="taches.enCours"
                                                aria-valuemin="0" aria-valuemax="100">
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </li>
                        </ul>
                    </div>
                    <hr>
                    <div class="text-center">
                        <div class="row aon-states-row">
                            <div class="col-6">
                                <div class="mt-2">
                                    <p class="text-muted mb-2">Terminée(s)</p>
                                    <h5 class="font-size-16 mb-0">{{ taches.terminee }} </h5>
                                </div>
                            </div>
                            <div class="col-4">
                                <div class="mt-2">
                                    <p class="text-muted mb-2">En attente(s)</p>
                                    <h5 class="font-size-16 mb-0"> {{ taches.en_cours }}</h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

  
<style></style>