import axiosClient from "../axios";

const statistiqueModule = {
  namespaced: true,
  state: {
    prestataire_statistiques: {},
    service_statistiques: {},
    entreprise_statistiques:{},
    admin_statistiques:{},
  },

  getters: {

    getPrestataireStatistique(state){
      return state.prestataire_statistiques;
    },

    getServiceStatistique(state){
      return state.service_statistiques;
    },

    getEntrepriseStatistique(state){
      return state.entreprise_statistiques;
    },

    getAdminStatistique(state){
      return state.admin_statistiques;
    }

  },
 
  actions: {

    async fetchPrestataireStatistique({ commit }) {
      try {
          const response = await axiosClient.get(`/prestataire-tache-stats`);
          //console.log(response.data);
          commit('setPrestataireStatistique', response.data);
      } catch (error) {
          console.error(error);
      }
    },

    async fetchServiceStatistique({ commit }, prestataireId) {
      try {
          const params = {
            prestataireId: prestataireId
          };
          const response = await axiosClient.get(`/service-tache-stats`,{params: params});
          //console.log(response.data);
          commit('setServiceStatistique', response.data);
      } catch (error) {
          console.error(error);
      }
    },

    async fetchEntrepriseStatistique({ commit }, serviceId) {
      try {
        const params = {
          serviceId: serviceId
        };
          const response = await axiosClient.get(`/entreprise-tache-stats`,{params: params});
          //console.log(response.data);
          commit('setEntrepriseStatistique', response.data);
      } catch (error) {
          console.error(error);
      }
    },

    async fetchAdminStatistique({ commit }, entrepriseId) {
      try {
        const params = {
          entrepriseId: entrepriseId
        };
          const response = await axiosClient.get(`/admin-tache-stats`,{params: params});
          //console.log(response.data);
          commit('setAdminStatistique', response.data);
      } catch (error) {
          console.error(error);
      }
    },

  },

  mutations: {

    setPrestataireStatistique(state, payload){
      state.prestataire_statistiques = payload;
    },

    setServiceStatistique(state, payload){
      state.service_statistiques = payload;
    },

    setEntrepriseStatistique(state, payload){
      state.entreprise_statistiques = payload;
    },

    setAdminStatistique(state, payload){
      state.admin_statistiques = payload;
    }

  },
 
};

export default statistiqueModule;
