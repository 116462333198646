<script>
import ListePrestataire from "./ListePrestataire.vue";
import AddPrestataire from "./AddPrestataire.vue";
export default {
    components: {ListePrestataire, AddPrestataire}

}
</script>

<template>              
    <!-- Liste Prestataire-->
    <ListePrestataire></ListePrestataire> 
    <AddPrestataire></AddPrestataire>
            
</template>
<style>


</style>