<script>
import { useStore } from "vuex";
import { ref, computed, onMounted, reactive } from "vue";
import { BACK_URL } from "@/constant";
import axiosClient from '@/axios';
import Swal from 'sweetalert2';

export default {
    
    setup() {
        const store = useStore();
        const services = computed(() => store.state.services);
        const errorMessage = ref('');
        const loading = ref(false);
        const selectedService = ref(null);

        const currentPage = ref(1);
        const itemsPerPage = 20;

        const searchNom = ref('');
        const searchResponsable = ref('');

        const serviceData = reactive({
            nom_service : '',
            resp_service : '',
            telephone : '',
            description : '',
            email : '',
        })

        const fetchPage = async (page) => {
            await store.dispatch('fetchService', page);
            currentPage.value = page;
        };

        onMounted(() => {
            fetchPage(currentPage.value);
        });

        const filteredServices = computed(() => {
            if (!Array.isArray(services.value)) return [];
                return services.value.filter(service => {
                    const nomMatch = service.nom_service.toLowerCase().includes(searchNom.value.toLowerCase());
                    const ResponsableMatch = service.resp_service.toLowerCase().includes(searchResponsable.value.toLowerCase());
                    return nomMatch && ResponsableMatch;
            });
        });

        const totalDataItems = computed(() => filteredServices.value.length);
        const totalPages = computed(() => Math.ceil(totalDataItems.value / itemsPerPage));
        const hasNextPage = computed(() => currentPage.value < totalPages.value);
        const visiblePageNumbers = computed(() => {
            const pages = [];
            for (let i = 1; i <= totalPages.value; i++) {
                pages.push(i);
            }
            return pages;
        });

        const clearFilters = () => {
            searchNom.value = '';
            searchResponsable.value = '';
        };

        function goBack() {
            router.back();
        }

        function showEditServiceModal(service) {
            selectedService.value = service;
            serviceData.nom_service = service.nom_service;
            serviceData.resp_service = service.resp_service;
            serviceData.email = service.user.email;
            serviceData.telephone = service.telephone;
            serviceData.description = service.description;
            $('#edit_service').modal('show');
        };

        async function deleteService(serviceId) {
            const result = await Swal.fire({
            icon: 'question',
            title: 'Confirmation',
            text: 'Êtes-vous sûr de vouloir supprimer ce service ?',
            confirmButtonColor:  '#022279', //'#ffc107',
            cancelButtonColor: '#d33',
            showCloseButton: true,
            showCancelButton: true,
            confirmButtonText: 'Continuer',
            cancelButtonText: 'Annuler',
        });

        if (result.isConfirmed) {
           await axiosClient.delete(`/services/${serviceId}`)
                .then((res) => {
                    if (res.status == 204) {
                        store.dispatch('fetchService', currentPage.value)
                            .then(() =>{
                                Swal.fire({
                                    icon: 'success',
                                    title: 'Le service a été supprimé avec succès',
                                }); 
                            })
                    }
                }).catch(error => {
                    if(error.response.status == 403){
                        Swal.fire({
                            title: 'Attention',
                            text: 'Ce service est lié à au moins un contrat. Il ne peut donc être supprimer',
                            icon: 'warning',
                            confirmButtonColor: '#022279', //'#ffc107',
                            confirmButtonText: 'OK'
                        });
                    }else{
                        Swal.fire({
                            title: 'Erreur',
                            text: 'Un problème est survenu lors de la suppresion du service, vérifiez votre connexion internet puis réessayez',
                            icon: 'error',
                            confirmButtonColor: '#022279', //'#ffc107',
                            confirmButtonText: 'OK'
                        });
                    }
                });
            }
        };

        const updateService = async () => {
        const result = await Swal.fire({
            icon: 'question',
            title: 'Confirmation',
            text: 'Êtes-vous sûr de vouloir modifier ce service ?',
            confirmButtonColor:  '#022279', //'#ffc107',
            cancelButtonColor: '#d33',
            showCloseButton: true,
            showCancelButton: true,
            confirmButtonText: 'Continuer',
            cancelButtonText: 'Annuler',
        });

        if (result.isConfirmed) {
            loading.value = true;
            const formData = new FormData();
            formData.append('nom_service', serviceData.nom_service);
            formData.append('resp_service', serviceData.resp_service);
            formData.append('email', serviceData.email);
            formData.append('telephone', serviceData.telephone);
            formData.append('description', serviceData.description);
            formData.append('_method', 'put');
            axiosClient.post(`/services/${selectedService.value.id}`, formData)
                .then((res)=>{
                    console.log(res);
                    if (res.status == 201) {
                        $('#edit_service').modal('hide'); 
                        Swal.fire({
                            title: 'Mise à jour réussie',
                            text: 'Le Service a été modifié avec succès',
                            icon: 'success',
                            confirmButtonColor: '#022279', //'#ffc107',
                            confirmButtonText: 'OK'
                        }).finally(()=>{
                            store.dispatch('fetchService', currentPage.value);
                        })
                    }
                }).catch((error) => {
                    if (error.response.status == 422) {
                        errorMessage.value =  error.response.data.errors;
                    }else{
                        Swal.fire({
                            title:'Erreur',
                            text: 'Echec de la mise à jour du service',
                            icon: 'error',
                            confirmButtonColor: '#022279', //'#ffc107',
                            confirmButtonText: 'OK'
                        });
                    }

                }).finally(() => {   
                    loading.value = false;    
                })
        }
    };

        return {
            services: filteredServices,
            currentPage,
            itemsPerPage,
            fetchPage,
            serviceData,
            hasNextPage,
            visiblePageNumbers,
            searchNom,
            searchResponsable,
            errorMessage,
            selectedService,
            loading,
            clearFilters,
            showEditServiceModal,
            deleteService,
            updateService,
            goBack,
            BACK_URL,
        };
    },
};
</script>
<template>
<div class="card aon-card">
    <div class="card-header aon-card-header">
        <div class="aon-card-header aon-blog-style-1 d-flex justify-content-between align-items-center flex-wrap" style="background-color: rgba(50, 50, 168, 0.2); border: 1px solid rgba(50, 50, 168, 0.5); border-radius: 10px;">
            <div class="py-2">
                <h4><i class="fa fa-cogs"></i> LISTE DES SERVICES</h4>
            </div>
            <div>
                <button class="admin-button" data-toggle="modal" data-target="#add_service_entreprise" type="button">
                    <i class="fa fa-plus"></i>
                    AJOUTER SERVICE
                </button>
            </div>
        </div>
    </div>
    <div class="card-body aon-card-body">
        <div class="pb-3 px-3 mb-4" style="background-color: rgba(50, 50, 168, 0.2); border: 1px solid rgba(50, 50, 168, 0.5); border-radius: 10px;">
            <div class="pt-3 pb-1 d-flex justify-content-between align-items-center flex-wrap">
                <h5>RECHERCHER UN SERVICE</h5>
            </div>
            <div class="row align-items-center">
                <div class="col-lg-5 form-group">
                    <input class="form-control" type="text" v-model="searchNom" placeholder="RECHERCHE PAR NOM SERVICE">
                </div>
                <div class="col-lg-5 form-group">
                    <input class="form-control" type="text" v-model="searchResponsable" placeholder="RECHERCHE PAR RESPONSABLE">
                </div>
                <div class="col-lg-2 form-group text-center align-middle">
                    <button class="btn btn-secondary" @click="clearFilters">Effacer les filtres</button>
                </div>
            </div>
        </div> 
        <div class="sf-bs-data-table">
            <div class="table-responsive">
                <table class="table table-striped table-bordered" style="width:100%">
                    <thead >
                        <tr class="bg-warning">
                            <th class="text-center align-middle">N°</th>
                            <th class="text-start align-middle">SERVICES</th>
                            <th class="text-start align-middle">RESPONSABLE</th>
                            <th class="text-center align-middle">VISA</th>
                            <th class="text-center align-middle">ACTIONS</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-if="services.length === 0">
                            <td colspan="6" class="text-center align-middle">Pas de données disponibles</td>
                        </tr>
                        <tr v-for="(service, index) in services" :key="index">
                            <td class="text-center align-middle">{{ (currentPage- 1)*(itemsPerPage) + (index +1) }}</td>
                            <td class="text-start align-middle">{{ service ? service.nom_service: 'NULL'}}</td>
                            <td class="text-start align-middle">{{service.resp_service}}</td>
                            <td class="text-center align-middle">
                                <div class="job-comapny-logo mr-2">
                                    <img class="company_logo aon-icon" :src="BACK_URL + 'storage/visa_resp/' + service.visa_resp" alt="" style="height: 50px; width: 50px;">
                                </div>
                            </td>
                            
                            <td class="text-center align-middle">
                                <button @click="showEditServiceModal(service)" class="btn btn-warning m-1" type="button">
                                    <i class="fa fa-edit"></i>
                                </button>
                                <button @click="deleteService(service.id)" class="btn btn-danger m-1" type="button">
                                    <i class="fa fa-trash-o"></i>
                                </button>
                            </td>
                        </tr>                                           
                    </tbody>
                </table>
            </div>
        </div>
        <nav aria-label="Page navigation">
            <ul class="pagination justify-content-end"> 
            <li class="page-item" :class="{ disabled: currentPage === 1 }">
                <button class="page-link" @click="fetchPage(currentPage - 1)" :disabled="currentPage === 1">
                    Précédent
                </button>
            </li>
            <li class="page-item" v-for="pageNumber in visiblePageNumbers" :key="pageNumber" :class="{ active: currentPage === pageNumber }">
                <button class="page-link" @click="fetchPage(pageNumber)">
                {{ pageNumber }}
                </button>
            </li>
            <li class="page-item" :class="{ disabled: !hasNextPage }">
                <button class="page-link" @click="fetchPage(currentPage + 1)" :disabled="!hasNextPage">
                    Suivant
                </button>
            </li>
            </ul>
        </nav>
    </div>
    <div class="modal fade content-admin-main" id="edit_service" tabindex="-1" role="dialog" aria-hidden="true" data-backdrop="static" data-keyboard="false">
                <div class="modal-dialog model-w800" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLabel2">MODIFIER SERVICE</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <form @submit.prevent="handleSubmit" enctype="multipart/form-data">
                    <div class="modal-body">
                        <div class="sf-md-padding">
                            <div class="row">
                                <div class="col-md-12"> 
                                    <div class="form-group">
                                        <label>NOM DE LA DIRECTION <span class="text-danger">*</span></label>
                                        <div class="aon-inputicon-box"> 
                                            <input class="form-control sf-form-control" name="nom_service" type="text" v-model="serviceData.nom_service">
                                            <i class="aon-input-icon fa fa-user"></i>
                                            <div class="text-danger" v-if="errorMessage.nom_service">{{ errorMessage.nom_service[0] }}</div>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-md-12"> 
                                    <div class="form-group">
                                        <label>RESPONSABLE DIRECTION <span class="text-danger">*</span></label>
                                        <div class="aon-inputicon-box"> 
                                            <input class="form-control sf-form-control" name="resp_service" type="text" v-model="serviceData.resp_service">
                                            <i class="aon-input-icon fa fa-user"></i>
                                            <div class="text-danger" v-if="errorMessage.resp_service">{{ errorMessage.resp_service[0] }}</div>
                                        </div>
                                    </div>
                                </div>
                                                
                                <div class="col-md-12"> 
                                    <div class="form-group">
                                        <label>E MAIL <span class="text-danger">*</span></label>
                                        <div class="aon-inputicon-box"> 
                                            <input class="form-control sf-form-control" name="email" type="email" v-model="serviceData.email">
                                            <i class="aon-input-icon fa fa-envelope"></i>
                                            <div class="text-danger" v-if="errorMessage.email">{{ errorMessage.email[0] }}</div>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-md-12"> 
                                    <div class="form-group">
                                        <label>TELEPHONE <span class="text-danger">*</span></label>
                                        <div class="aon-inputicon-box"> 
                                            <input class="form-control sf-form-control" name="telephone" type="number" v-model="serviceData.telephone">
                                            <i class="aon-input-icon fa fa-phone"></i>
                                            <div class="text-danger" v-if="errorMessage.telephone">{{ errorMessage.telephone[0] }}</div>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-md-12">
                                    <div class="form-group">
                                        <div class="aon-inputicon-box"> 
                                            <label>DESCRIPTION</label>
                                            <textarea class="form-control" rows="5" name="description" v-model="serviceData.description"></textarea>
                                            <div class="text-danger" v-if="errorMessage.description">{{ errorMessage.description[0] }}</div>
                                        </div>             
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="admin-button" data-dismiss="modal">FERMER</button>
                        <button @click="updateService()" type="submit" class="admin-button" :disabled="loading">
                            <i v-if="loading" class="fa fa-fw fa-spinner fa-spin mr-1"></i> 
                            <i v-else class="fa fa-save"></i> ENREGISTRER
                        </button>
                    </div>
                </form>
                    </div>
                </div>
            </div>
</div>
</template>

<style>
</style>