<script>
import Header from "@/components/PrestataireDashboard/Header.vue";
import SideBar from "@/components/PrestataireDashboard/SideBar.vue";
import { useStore, mapGetters } from "vuex";
import { computed, onMounted, ref } from "vue";
import { useRouter } from "vue-router";
import Swal from 'sweetalert2';
export default {
    setup() {
        const store = useStore();
        const router = useRouter();

        const connectedPrestataire = store.getters.getPrestataireUser
        const userRole = store.getters.getUserRole;
        const isLoggedIn = store.getters.isLoggedIn;

        const prestataire = computed(() => store.state.prestataireUser);
        onMounted(async () => {
            try {
                await store.dispatch("getPrestataireUser");
            } catch (error) {
                console.error("Erreur lors de la récupération des données de prestataire :", error);
            }
        });

        function showLogoutConfirmation() {
            Swal.fire({
                title: 'Êtes-vous sûr de vouloir vous déconnecter ?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Oui',
                cancelButtonText: 'Non',
                confirmButtonColor: '#e04f1a',
                cancelButtonColor: '#ffb600'

            }).then((result) => {
                if (result.isConfirmed) {
                    logout();
                }
            });
        }



        function logout() {
            store.dispatch("logout").then(() => {
                router.push('/'); 
            });
        }

        return {
            user: computed(() => store.state.user.data),
            showLogoutConfirmation,
            logout,
            userRole,
            isLoggedIn,
            prestataire,
            connectedPrestataire,
        };
    },
    components: {
        Header,
        SideBar, 
    }

}
</script>

<template>
  
    <div class="page-wraper">
        <Header></Header> 
                 
      
      <!-- Sidebar Holder -->
      <SideBar></SideBar>
      
  
      <!-- Page Content Holder -->
      <div id="content">
          <div class="content-admin-main">
            <div class="admin-top-area d-flex flex-wrap justify-content-between m-b30 align-items-center" style="background-color: #e9eef1;">
                <div class="admin-left-area">
                    <a class="nav-btn-admin d-flex justify-content-between align-items-center" id="sidebarCollapse">
                        <span class="nav-btn-text">Menu</span>
                        <span class="fa fa-reorder"></span>
                    </a>
                </div>
                <div class="admin-right-area">
                    <div class="pro-pic-info-wrap d-flex">
                        <!--<h4>Bienvenue {{ prestataire.nom }} {{ prestataire.prenoms }} ,({{ userRole }})</h4>-->
                        <h4>Bienvenue {{ connectedPrestataire.raison_sociale }}</h4>
                        <span class="feather-icon has-toltip" @click="showLogoutConfirmation">
                            <i class="feather-power"></i>
                        </span>
                    </div>
                </div>
            </div>  
            <router-view></router-view>
          </div>       
        </div>
    </div>       
</template>
  
<style>
  
</style>