<script setup>
import { useStore } from 'vuex';
import { computed, ref, onMounted, watch} from "vue"
import { useRouter } from 'vue-router';
import Chart from 'chart.js/auto';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import Swal from 'sweetalert2';

const store = useStore();
const router = useRouter();
const isNeverVisible = ref(false)
const isPrinting = ref(false);

const entrepriseQuery = ref(null);
const isSearching = ref(false);

const statistiques = computed(() => {
    return store.state.statistiqueModule.admin_statistiques || {};
});

const adminEntreprises = computed(() => {
    return store.state.prestataireModule.adminEntreprises || [];
});

const isStatisticsDataReady = computed(()=>{
    return Object.keys(statistiques.value).length > 0
})

const tachesPlanifieesCanvas = ref(null);
const tachesNormalesCanvas = ref(null);
const tachesUrgentesCanvas = ref(null);
const tachesCritiquesCanvas = ref(null);
const tachesPlanifieesCanvasForPrint = ref(null);
const tachesNormalesCanvasForPrint = ref(null);
const tachesUrgentesCanvasForPrint = ref(null);
const tachesCritiquesCanvasForPrint = ref(null);

let chart1 = null;
let chart2 = null;
let chart3 = null;
let chart4 = null;
let chart1ForPrint = null;
let chart2ForPrint = null;
let chart3ForPrint = null;
let chart4ForPrint = null;


const moisNom = (moisNumero) => {
  const moisNoms = {
    1: 'Janv',
    2: 'Févr',
    3: 'Mars',
    4: 'Avr',
    5: 'Mai',
    6: 'Juin',
    7: 'Juill',
    8: 'Août',
    9: 'Sept',
    10: 'Oct',
    11: 'Nov',
    12: 'Déc'
  };
  return moisNoms[moisNumero];
};

function getSelectedEntrepriseName() {
    const entreprise = adminEntreprises.value.find(entreprise => entreprise.id === entrepriseQuery.value);
    return entreprise ? entreprise.nom_entreprise : '';
}

function displayCharts(){
    const tachesPlanifieesChartData = {
        labels: Object.keys(statistiques.value.taches_planifiees).map(mois => moisNom(Number(mois))),
        datasets: [
            {
                label: 'Tâches planifiées',
                data: Object.values(statistiques.value.taches_planifiees).map(taches => taches.nombre_taches),
                backgroundColor: 'rgba(255, 206, 64, 0.5)',
                borderColor: 'rgba(255, 206, 64, 1)',
                borderWidth: 1
            },
            {
                label: 'Tâches planifiées réalisées',
                data: Object.values(statistiques.value.taches_planifiees_realisees).map(taches => taches.nombre_taches),
                backgroundColor: 'rgba(0, 255, 170, 0.5)',
                borderColor: 'rgba(0, 255, 170, 1)',
                borderWidth: 1
            }
        ]
    };

    const tachesNormalesChartData = {
        labels: Object.keys(statistiques.value.taches_priorite_normale_realisees).map(mois => moisNom(Number(mois))),
        datasets: [
            {
                label: 'Tâches normales réalisées',
                data: Object.values(statistiques.value.taches_priorite_normale_realisees).map(taches => taches.nombre_taches),
                backgroundColor: 'rgba(135, 206, 235, 0.5)',
                borderColor: 'rgba(135, 206, 235, 1)',
                borderWidth: 1
            },
        ]
    };

    const tachesUrgentesChartData = {
        labels: Object.keys(statistiques.value.taches_priorite_urgente_realisees).map(mois => moisNom(Number(mois))),
        datasets: [
            {
                label: 'Tâches urgentes réalisées',
                data: Object.values(statistiques.value.taches_priorite_urgente_realisees).map(taches => taches.nombre_taches),
                backgroundColor: 'rgba(255, 159, 64, 0.5)',
                borderColor: 'rgba(255, 159, 64, 1)',
                borderWidth: 1
            },
        ]
    };

    const tacheCritiquesChartData = {
        labels: Object.keys(statistiques.value.taches_priorite_critique_realisees).map(mois => moisNom(Number(mois))),
        datasets: [
            {
                label: 'Tâches critiques réalisées',
                data: Object.values(statistiques.value.taches_priorite_critique_realisees).map(taches => taches.nombre_taches),
                backgroundColor: 'rgba(255, 0, 0, 0.5)',
                borderColor: 'rgba(255, 0, 0, 1)',
                borderWidth: 1
            },
        ]
    };

    const chartOptions = {
        scales: {
            y: {
                beginAtZero: true
            }
        }
    };

    const ctx = tachesPlanifieesCanvas.value.getContext('2d');
    chart1 = new Chart(ctx, {
        type: 'bar',
        data: tachesPlanifieesChartData,
        options: chartOptions
    });

    const ctx3 = tachesNormalesCanvas.value.getContext('2d');
    chart2 = new Chart(ctx3, {
        type: 'bar',
        data: tachesNormalesChartData,
        options: chartOptions
    });

    const ctx4 = tachesUrgentesCanvas.value.getContext('2d');
    chart3 = new Chart(ctx4, {
        type: 'bar',
        data: tachesUrgentesChartData,
            options: chartOptions
    });

    const ctx5 = tachesCritiquesCanvas.value.getContext('2d');
    chart4 = new Chart(ctx5, {
        type: 'bar',
        data: tacheCritiquesChartData,
        options: chartOptions
    });
}

function displayChartsForPrint(){
    const tachesPlanifieesChartDataForPrint = {
        labels: Object.keys(statistiques.value.taches_planifiees).map(mois => moisNom(Number(mois))),
        datasets: [
            {
                label: 'Tâches planifiées',
                data: Object.values(statistiques.value.taches_planifiees).map(taches => taches.nombre_taches),
                backgroundColor: 'rgba(255, 206, 64, 0.5)',
                borderColor: 'rgba(255, 206, 64, 1)',
                borderWidth: 1
            },
            {
                label: 'Tâches planifiées terminées',
                data: Object.values(statistiques.value.taches_planifiees_realisees).map(taches => taches.nombre_taches),
                backgroundColor: 'rgba(0, 255, 170, 0.5)',
                borderColor: 'rgba(0, 255, 170, 1)',
                borderWidth: 1
            }
        ]
    };

    const tachesNormalesChartDataForPrint = {
        labels: Object.keys(statistiques.value.taches_priorite_normale_realisees).map(mois => moisNom(Number(mois))),
        datasets: [
            {
                label: 'Tâches normales réalisées',
                data: Object.values(statistiques.value.taches_priorite_normale_realisees).map(taches => taches.nombre_taches),
                backgroundColor: 'rgba(135, 206, 235, 0.5)',
                borderColor: 'rgba(135, 206, 235, 1)',
                borderWidth: 1
            },
        ]
    };

    const tachesUrgentesChartDataForPrint = {
        labels: Object.keys(statistiques.value.taches_priorite_urgente_realisees).map(mois => moisNom(Number(mois))),
        datasets: [
            {
                label: 'Tâches urgentes réalisées',
                data: Object.values(statistiques.value.taches_priorite_urgente_realisees).map(taches => taches.nombre_taches),
                backgroundColor: 'rgba(255, 159, 64, 0.5)',
                borderColor: 'rgba(255, 159, 64, 1)',
                borderWidth: 1
            },
        ]
    };

    const tacheCritiquesChartDataForPrint = {
        labels: Object.keys(statistiques.value.taches_priorite_critique_realisees).map(mois => moisNom(Number(mois))),
        datasets: [
            {
                label: 'Tâches critiques terminées',
                data: Object.values(statistiques.value.taches_priorite_critique_realisees).map(taches => taches.nombre_taches),
                backgroundColor: 'rgba(255, 0, 0, 0.5)',
                borderColor: 'rgba(255, 0, 0, 1)',
                borderWidth: 1
            },
        ]
    };

    const chartOptions = {
        scales: {
            y: {
                beginAtZero: true
            }
        }
    };

    const ctx = tachesPlanifieesCanvasForPrint.value.getContext('2d');
    chart1ForPrint =  new Chart(ctx, {
        type: 'bar',
        data: tachesPlanifieesChartDataForPrint,
        options: chartOptions
    });

    const ctx3 = tachesNormalesCanvasForPrint.value.getContext('2d');
    chart2ForPrint = new Chart(ctx3, {
        type: 'bar',
        data: tachesNormalesChartDataForPrint,
        options: chartOptions
    });

    const ctx4 = tachesUrgentesCanvasForPrint.value.getContext('2d');
    chart3ForPrint = new Chart(ctx4, {
        type: 'bar',
        data: tachesUrgentesChartDataForPrint,
        options: chartOptions
    });

    const ctx5 = tachesCritiquesCanvasForPrint.value.getContext('2d');
    chart4ForPrint = new Chart(ctx5, {
        type: 'bar',
        data: tacheCritiquesChartDataForPrint,
        options: chartOptions
    });
}

function destroyCharts(){
    if (chart1) {
        chart1.destroy()
    }

    if (chart2) {
        chart2.destroy()
    }

    if (chart3) {
        chart3.destroy()
    }

    if (chart4) {
        chart4.destroy()
    }

    if (chart1ForPrint) {
        chart1ForPrint.destroy()
    }

    if (chart2ForPrint) {
        chart2ForPrint.destroy()
    }

    if (chart3ForPrint) {
        chart3ForPrint.destroy()
    }

    if (chart4ForPrint) {
        chart4ForPrint.destroy()
    }
}

function formatDate(date) {
    return new Date(date).toLocaleDateString('fr-FR');
}

function shortenText(name, maxLength = 100) {
    if (name.length > maxLength) {
        return name.substring(0, maxLength - 3) + '...';
    }
        return name;
}

async function readyToPrint() {
    isPrinting.value = true;
    const mainContainer = document.getElementById('main-container');
      const pdf = new jsPDF();

      // Position initiale pour le contenu PDF
      let pdfY = 10;

      // Capturer chaque élément HTML et l'ajouter au PDF
      for (const container of mainContainer.children) {
        const canvas = await html2canvas(container);
        const imageData = canvas.toDataURL('image/png');

        const imgProps = pdf.getImageProperties(imageData);
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;

        if (pdfY + pdfHeight > pdf.internal.pageSize.getHeight()) {
          pdf.addPage();
          pdfY = 10;
        }

        pdf.addImage(imageData, 'PNG', 10, pdfY, pdfWidth - 20, pdfHeight);
        pdfY += pdfHeight + 10; // Ajouter un espacement entre les éléments
      }

      // Télécharger le PDF
      isNeverVisible.value = false;
      isPrinting.value = false;
      pdf.save('STATISTIQUES_'+ getSelectedEntrepriseName() + '.pdf');
}

async function imprimerStatistiques() {
    isNeverVisible.value = true;
    Swal.fire({
        title: 'Confirmation',
        text: 'Êtes-vous sûr de vouloir imprimer les stataistiques ?',
        icon: 'question',
        showCancelButton: true,
        showCloseButton: true,
        confirmButtonColor: '#022279',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Continuer',
        cancelButtonText: 'Annuler',
    }).then((result) => {
      if (result.isConfirmed) {
        readyToPrint();
      }else{
        isNeverVisible.value = false;
      }
    }).finally(() => {
    
    });
}

function goBack() {
    router.back();
}

watch(entrepriseQuery, async (newValue) => {
    destroyCharts();
    isSearching.value = true
    store.dispatch('statistiqueModule/fetchAdminStatistique', { 
        entrepriseId: entrepriseQuery.value
    }).then(()=>{
        isSearching.value = false;
        displayCharts();
        displayChartsForPrint();
    })
});

onMounted(async () => {
    await store.dispatch('prestataireModule/fetchAdminEntreprises')
    .then(() =>{

    })
});
 
</script>

<template>
    <div>
        <div class="aon-admin-heading">
            <div class="d-flex justify-content-start align-items-center">
                <button class="btn btn-light btn-lg mr-2" type="button" @click="goBack()">
                    <i class="feather-arrow-left"></i>
                </button>
                <h3 class="text-center">
                    STATISTIQUES
                </h3>
            </div>
        </div>
        <div class="card aon-card">
            <div class="card-header aon-card-header ">
                <div class="aon-card-header aon-blog-style-1 d-flex justify-content-between align-items-center flex-wrap" style="background-color: rgba(50, 50, 168, 0.2); border: 1px solid rgba(50, 50, 168, 0.5); border-radius: 10px;">
                    <div class="py-2">
                        <h4><i class="fa fa-bar-chart"></i> STATISTIQUES</h4>
                    </div>
                    <form class="d-flex justify-content-between align-items-center flex-wrap my-1">
                        <div class="form-group sf-news-l-form m-1">
                            <select class="form-control" v-model="entrepriseQuery">
                                <option :value = null disabled selected>Sélectionnez une entreprise</option>
                                <option v-for="entreprise in adminEntreprises" :key="entreprise.id" :value="entreprise.id">
                                    {{ entreprise.nom_entreprise }}
                                </option>
                            </select>
                        </div>
                        <button v-if="isStatisticsDataReady" class="btn btn-lg admin-button-secondry m-1" type="button" @click="imprimerStatistiques()" :disabled="isPrinting">
                            <i v-if="isPrinting" class="fa fa-fw fa-spinner fa-spin mr-1"></i>
                            <i v-else class="fa fa-print"></i>
                            {{ isPrinting ? 'Impression en cours...' : 'Imprimer' }}
                        </button>
                    </form>
                </div>
            </div>
            
            <div class="card-body aon-card-body">
                <div v-if="isStatisticsDataReady" class="row" >
                    <div class="col-lg-6 mt-2">

                        <div class="aon-blog-style-1" style="border: 1px solid rgba(135, 206, 235, 0.5); border-radius: 10px; padding: 20px 10px; margin: 0px 5px; background-color: rgba(50, 50, 168, 0.04);">
                            <div class="py-2 text-center align-middle">
                                <h5 style="background-color: rgba(135, 206, 235, 0.2); padding: 10px; border: 1px solid rgba(135, 206, 235, 0.5); border-radius: 10px;">TÂCHES NORMALES</h5>
                            </div>
                            <div class="mt-3">
                                <canvas ref="tachesNormalesCanvas"></canvas>
                            </div>
                            <div class="table-responsive mt-3" style="display: flex; flex-direction: column; justify-content: flex-end;" >
                                <table class="table table-striped table-bordered" style="width:100%;">
                                    <thead style="background-color: rgba(135, 206, 235, 0.5);">
                                        <tr>
                                            <th class="align-middle">MOIS</th>
                                            <th v-for="(statistique, mois) in statistiques.taches_priorite_normale_realisees" :key="mois" class="text-center align-middle">{{ moisNom(Number(mois)) }}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td class="align-middle" style="white-space: nowrap;">TÂCHES NORMALES RÉALISÉES</td>
                                            <td v-for="(taches, mois) in statistiques.taches_priorite_normale_realisees" :key="mois" class="text-center align-middle">
                                              {{ taches.nombre_taches }}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                                                
                        <div class="mt-4 aon-blog-style-1" style="border: 1px solid rgba(255, 0, 0, 0.5); border-radius: 10px; padding: 20px 10px; margin: 0px 5px; background-color: rgba(50, 50, 168, 0.04);">
                            <div class="py-2 text-center align-middle">
                                <h5 style="background-color: rgba(255, 0, 0, 0.2); padding: 10px; border: 1px solid rgba(255, 0, 0, 0.5); border-radius: 10px;">TÂCHES CRITIQUES</h5>
                            </div>
                            <div class="mt-3">
                                <canvas ref="tachesCritiquesCanvas"></canvas>
                            </div>
                            <div class="table-responsive mt-3" style="display: flex; flex-direction: column; justify-content: flex-end;" >
                                <table  class="table table-striped table-bordered" style="width:100%;">
                                    <thead style="background-color: rgba(255, 0, 0, 0.5);">
                                        <tr>
                                            <th class="align-middle">MOIS</th>
                                            <th v-for="(statistique, mois) in statistiques.taches_priorite_critique_realisees" :key="mois" class="text-center align-middle">{{ moisNom(Number(mois)) }}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td class="align-middle" style="white-space: nowrap;">TÂCHES CRITIQUES RÉALISÉES</td>
                                            <td v-for="(taches, mois) in statistiques.taches_priorite_critique_realisees" :key="mois" class="text-center align-middle">
                                              {{ taches.nombre_taches }}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-6 mt-2">
                        <div class="aon-blog-style-1" style="border: 1px solid rgba(255, 159, 64, 0.5); border-radius: 10px; padding: 20px 10px; margin: 0px 5px; background-color: rgba(50, 50, 168, 0.04);">
                            <div class="py-2 text-center align-middle">
                                <h5 style="background-color: rgba(255, 159, 64, 0.2); padding: 10px; border: 1px solid rgba(255, 159, 64, 0.5); border-radius: 10px;">TÂCHES URGENTES</h5>
                            </div>
                            <div class="mt-3">
                                <canvas ref="tachesUrgentesCanvas"></canvas>
                            </div>
                            <div class="table-responsive mt-3" style="display: flex; flex-direction: column; justify-content: flex-end;" >
                                <table class="table table-striped table-bordered" style="width:100%;">
                                    <thead style="background-color: rgba(255, 159, 64, 0.5);">
                                        <tr>
                                            <th class="align-middle">MOIS</th>
                                            <th v-for="(statistique, mois) in statistiques.taches_priorite_urgente_realisees" :key="mois" class="text-center align-middle">{{ moisNom(Number(mois)) }}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td class="align-middle" style="white-space: nowrap;">TÂCHES URGENTES RÉALISÉES</td>
                                            <td v-for="(taches, mois) in statistiques.taches_priorite_urgente_realisees" :key="mois" class="text-center align-middle">
                                              {{ taches.nombre_taches }}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>

                        <div class="aon-blog-style-1 mt-4" style="border: 1px solid rgba(255, 206, 64, 0.5); border-radius: 10px; padding: 20px 10px; margin: 0px 5px; background-color: rgba(50, 50, 168, 0.04);">
                            <div class="py-2 text-center align-middle">
                                <h5 style="background-color: rgba(255, 206, 64, 0.2); padding: 10px; border: 1px solid rgba(255, 206, 64, 0.5); border-radius: 10px;">TÂCHES PLANIFIÉES</h5>
                            </div>
                            <div class="mt-3" >
                                <canvas ref="tachesPlanifieesCanvas"></canvas>
                            </div>
                            <div class="table-responsive mt-3" style="display: flex; flex-direction: column; justify-content: flex-end;" >
                                <table class="table table-striped table-bordered" style="width:100%;">
                                    <thead style="background-color: rgba(255, 206, 64, 0.5);">
                                        <tr>
                                            <th class="align-middle" style="white-space: nowrap;">MOIS</th>
                                            <th v-for="(statistique, mois) in statistiques.taches_planifiees" :key="mois" class="text-center align-middle">{{ moisNom(Number(mois)) }}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td class="align-middle" style="white-space: nowrap;">TÂCHES PLANIFIÉES</td>
                                            <td v-for="(taches, mois) in statistiques.taches_planifiees" :key="mois" class="text-center align-middle">
                                              {{ taches.nombre_taches }}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="align-middle" style="white-space: nowrap;">TÂCHES PLANIFIÉES RÉALISÉES</td>
                                            <td v-for="(taches, mois) in statistiques.taches_planifiees_realisees" :key="mois" class="text-center align-middle">
                                              {{ taches.nombre_taches }}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="align-middle" style="white-space: nowrap;">TAUX D'EXÉCUTION (%)</td>
                                            <td v-for="(taches, mois) in statistiques.taux_execution_taches_planifiees" :key="mois" class="text-center align-middle">
                                              {{ taches.taux }}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-else>
                    <div v-if="isSearching" class="text-center">
                        <i class="fa fa-2x fa-spinner fa-spin mr-2"></i> Chargement des statistiques
                    </div>
                    <div v-else class="my-2 alert alert-primary">
                        Sélectionnez ci-dessus une entreprise pour voir ses statistiques
                    </div> 
                </div>
                <div v-show="isNeverVisible" id="main-container">
                    <div class="aon-card-header aon-blog-style-1 mt-4 d-flex justify-content-between align-items-center flex-wrap" style="background-color: rgba(50, 50, 168, 0.2); border: 1px solid rgba(50, 50, 168, 0.5); border-radius: 10px;">
                        <div class="text-center align-middle">
                            <h4 >STATISTIQUES DES TÂCHES</h4>
                        </div>
                        <div class="align-middle text-black">
                            <strong>ENTREPRISE</strong> : <span>{{ getSelectedEntrepriseName() }}</span>
                        </div>
                    </div>

                    <div class="mt-4 aon-blog-style-1" style="border: 1px solid rgba(135, 206, 235, 0.5); border-radius: 10px; padding: 20px 10px; margin: 0px 5px; background-color: rgba(50, 50, 168, 0.04);">
                        <div class="py-2 text-center align-middle">
                            <h5 style="background-color: rgba(135, 206, 235, 0.2); padding: 10px; border: 1px solid rgba(135, 206, 235, 0.5); border-radius: 10px;">TÂCHES NORMALES</h5>
                        </div>
                        <div class="mt-3">
                            <canvas style="max-height: 300px;" ref="tachesNormalesCanvasForPrint"></canvas>
                        </div>
                        <div class="table-responsive mt-3" style="display: flex; flex-direction: column; justify-content: flex-end;" >
                            <table class="table table-striped table-bordered" style="width:100%;">
                                <thead style="background-color: rgba(135, 206, 235, 0.5);">
                                    <tr>
                                        <th class="align-middle">MOIS</th>
                                        <th v-for="(statistique, mois) in statistiques.taches_priorite_normale_realisees" :key="mois" class="text-center align-middle">{{ moisNom(Number(mois)) }}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td class="align-middle" style="white-space: nowrap;">TÂCHES NORMALES RÉALISÉES</td>
                                        <td v-for="(taches, mois) in statistiques.taches_priorite_normale_realisees" :key="mois" class="text-center align-middle">
                                          {{ taches.nombre_taches }}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <div class="aon-blog-style-1 mt-4" style="border: 1px solid rgba(255, 159, 64, 0.5); border-radius: 10px; padding: 20px 10px; margin: 0px 5px; background-color: rgba(50, 50, 168, 0.04);">
                        <div class="py-2 text-center align-middle">
                            <h5 style="background-color: rgba(255, 159, 64, 0.2); padding: 10px; border: 1px solid rgba(255, 159, 64, 0.5); border-radius: 10px;">TÂCHES URGENTES</h5>
                        </div>
                        <div class="mt-3">
                            <canvas style="max-height: 300px;" ref="tachesUrgentesCanvasForPrint"></canvas>
                        </div>
                        <div class="table-responsive mt-3" style="display: flex; flex-direction: column; justify-content: flex-end;" >
                            <table class="table table-striped table-bordered" style="width:100%;">
                                <thead style="background-color: rgba(255, 159, 64, 0.5);">
                                    <tr>
                                        <th class="align-middle">MOIS</th>
                                        <th v-for="(statistique, mois) in statistiques.taches_priorite_urgente_realisees" :key="mois" class="text-center align-middle">{{ moisNom(Number(mois)) }}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td class="align-middle" style="white-space: nowrap;">TÂCHES URGENTES RÉALISÉES</td>
                                        <td v-for="(taches, mois) in statistiques.taches_priorite_urgente_realisees" :key="mois" class="text-center align-middle">
                                        {{ taches.nombre_taches }}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                        
                    <div class="mt-4 aon-blog-style-1" style="border: 1px solid rgba(255, 0, 0, 0.5); border-radius: 10px; padding: 20px 10px; margin: 0px 5px; background-color: rgba(50, 50, 168, 0.04);">
                        <div class="py-2 text-center align-middle">
                            <h5 style="background-color: rgba(255, 0, 0, 0.2); padding: 10px; border: 1px solid rgba(255, 0, 0, 0.5); border-radius: 10px;">TÂCHES CRITIQUES</h5>
                        </div>
                        <div class="mt-3">
                            <canvas style="max-height: 300px;" ref="tachesCritiquesCanvasForPrint"></canvas>
                        </div>
                        <div class="table-responsive mt-3" style="display: flex; flex-direction: column; justify-content: flex-end;" >
                            <table  class="table table-striped table-bordered" style="width:100%;">
                                <thead style="background-color: rgba(255, 0, 0, 0.5);">
                                    <tr>
                                        <th class="align-middle">MOIS</th>
                                        <th v-for="(statistique, mois) in statistiques.taches_priorite_critique_realisees" :key="mois" class="text-center align-middle">{{ moisNom(Number(mois)) }}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td class="align-middle" style="white-space: nowrap;">TÂCHES CRITIQUES RÉALISÉES</td>
                                        <td v-for="(taches, mois) in statistiques.taches_priorite_critique_realisees" :key="mois" class="text-center align-middle">
                                        {{ taches.nombre_taches }}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <div class="aon-blog-style-1 mt-4" style="border: 1px solid rgba(255, 206, 64, 0.5); border-radius: 10px; padding: 20px 10px; margin: 0px 5px; background-color: rgba(50, 50, 168, 0.04);">
                        <div class="py-2 text-center align-middle">
                            <h5 style="background-color: rgba(255, 206, 64, 0.2); padding: 10px; border: 1px solid rgba(255, 206, 64, 0.5); border-radius: 10px;">TÂCHES PLANIFIÉES</h5>
                        </div>
                        <div class="mt-3">
                            <canvas style="max-height: 300px;" ref="tachesPlanifieesCanvasForPrint"></canvas>
                        </div>
                        <div class="table-responsive mt-3" style="display: flex; flex-direction: column; justify-content: flex-end;" >
                            <table class="table table-striped table-bordered" style="width:100%;">
                                <thead style="background-color: rgba(255, 206, 64, 0.5);">
                                    <tr>
                                        <th class="align-middle" style="white-space: nowrap;">MOIS</th>
                                        <th v-for="(statistique, mois) in statistiques.taches_planifiees" :key="mois" class="text-center align-middle">{{ moisNom(Number(mois)) }}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td class="align-middle" style="white-space: nowrap;">TÂCHES PLANIFIÉES</td>
                                        <td v-for="(taches, mois) in statistiques.taches_planifiees" :key="mois" class="text-center align-middle">
                                          {{ taches.nombre_taches }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="align-middle" style="white-space: nowrap;">TÂCHES PLANIFIÉES RÉALISÉES</td>
                                        <td v-for="(taches, mois) in statistiques.taches_planifiees_realisees" :key="mois" class="text-center align-middle">
                                          {{ taches.nombre_taches }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="align-middle" style="white-space: nowrap;">TAUX D'EXÉCUTION (%)</td>
                                        <td v-for="(taches, mois) in statistiques.taux_execution_taches_planifiees" :key="mois" class="text-center align-middle">
                                          {{ taches.taux }}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
progress {
  background-color: #022279;
}

.finished-task{
    background-color: rgba(50, 168, 103, 0.164);
}

.pending-task{
   background-color: rgba(50, 50, 168, 0.164);
    
}

.scheduled-task{
    background-color: rgba(168, 152, 50, 0.164);
}
</style>
