<script>
import ServiceEntrprise from "./ServiceEntrprise.vue";
import ListeService from "./ListeService.vue";
import { useRouter } from "vue-router";
export default {
    components: { ServiceEntrprise, ListeService},

    setup() {
        const router = useRouter();

        function goBack() {
            router.back();
        }

        return {
            goBack,
        };
    }
}
</script>

<template>           
    <div class="aon-admin-heading">
        <div class="d-flex justify-content-start align-items-center">
            <button class="btn btn-light btn-lg mr-2" type="button" @click="goBack()">
                <i class="feather-arrow-left"></i>
            </button>
            <h3 class="text-center">SERVICE</h3>
        </div>
    </div>    
    <!-- Modale service -->
    <ServiceEntrprise></ServiceEntrprise>
    <!-- Liste service -->
    <ListeService></ListeService>          
</template>
<style>


</style>