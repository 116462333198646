<script setup>
import { computed, onMounted, ref, watch } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { BACK_URL } from "@/constant";
import Swal from "sweetalert2";

    const store = useStore();
    const router = useRouter();
    const isTableLoading = ref(false);
    const isSearching = ref(false);
    const nomQuery = ref('');
    const prenomsQuery = ref('');
    const raisonSocialeQuery = ref('');
    const currentPage = ref(1);
    const perPage = 10;

    const prestataires = computed(() => 
        store.state.serviceModule.servicePrestataires || {}
    );


    const paginatedPrestataires = computed(() => {
        const startIndex = (currentPage.value - 1) * perPage;
        const endIndex = startIndex + perPage;
        return prestataires.value.slice(startIndex, endIndex);
    });

    const totalPages = computed(() => Math.ceil(prestataires.value.length / perPage));

    function clearFilters(){
        nomQuery.value = '';
        prenomsQuery.value = '';
        raisonSocialeQuery.value = '';
    }

    watch(nomQuery, async (newValue) => {
        isSearching.value = true
        store.dispatch('serviceModule/fetchPrestatairesByService', { 
            nomQuery: newValue, prenomsQuery: prenomsQuery.value, raisonSocialeQuery: raisonSocialeQuery.value
        }).then(()=>{
            isSearching.value = false;
        })
    });

    watch(prenomsQuery, async (newValue) => {
        isSearching.value = true
        store.dispatch('serviceModule/fetchPrestatairesByService', { 
            nomQuery: nomQuery.value, prenomsQuery: newValue, raisonSocialeQuery: raisonSocialeQuery.value
        }).then(()=>{
            isSearching.value = false;
        })
    });

    watch(raisonSocialeQuery, async (newValue) => {
        isSearching.value = true
        store.dispatch('serviceModule/fetchPrestatairesByService', { 
            nomQuery: nomQuery.value, prenomsQuery: prenomsQuery.value, raisonSocialeQuery: newValue
        }).then(()=>{
            isSearching.value = false;
        })
    });

    onMounted((async ) => {
        isTableLoading.value = true
        store.dispatch('serviceModule/fetchPrestatairesByService', { 
            nomQuery: nomQuery.value, prenomsQuery: prenomsQuery.value, raisonSocialeQuery: raisonSocialeQuery.value
        }).then(()=>{
                isTableLoading.value = false;
            })
    });

    function displayContrats(prestataireId){
        router.push(`/serv-prestataire/${prestataireId}/contrats`)
    }

    function showPrestataire(prestataire){
        Swal.fire({
            imageUrl: `${ prestataire.photo ? BACK_URL + 'storage/photoProfile/' + prestataire.photo : '/../../../images/placeholder/placeholder-user.jpg'}`,
            imageWidth: 400,
            imageHeight: 400,
            showCloseButton: true,
            showConfirmButton: false,
            imageAlt: `${prestataire.photo ? BACK_URL + 'storage/photoProfile/' + prestataire.photo : '/../../../images/placeholder/placeholder-user.jpg'}`,
            title: `${prestataire.nom + ' ' + prestataire.prenoms}`,
        })

    }

    function goBack(){
        router.back();
    }

</script>

<template>
    <div class="container">
        <div class="aon-admin-heading">
            <div class="d-flex justify-content-start align-items-center">
                <button class="btn btn-light btn-lg mr-2" type="button" @click="goBack()">
                    <i class="feather-arrow-left"></i>
                </button>
                <h3 class="text-center">PRESTATAIRES</h3>
            </div>
        </div>     
        <div class="card aon-card">
            <div class="card-header aon-card-header">
                <div class="aon-card-header aon-blog-style-1 d-flex justify-content-between align-items-center flex-wrap" style="background-color: rgba(50, 50, 168, 0.2); border: 1px solid rgba(50, 50, 168, 0.5); border-radius: 10px;">
                    <div class="py-2">
                        <h4><i class="fa fa-list"></i> LISTE  DES PRESTATAIRES</h4>
                    </div>
                    <!--<div>
                        <button class="btn admin-button-secondry align-middle" type="button" @click="nouveauContrat()">
                            <i class="fa fa-plus"></i>
                            Nouveau contrat 
                        </button>
                    </div> -->
                </div>
            </div>
            <div class="card-body aon-card-body">
                <div>
                    <div class="pb-3 px-3 mb-4" style="background-color: rgba(50, 50, 168, 0.2); border: 1px solid rgba(50, 50, 168, 0.5); border-radius: 10px;">
                        <div class="py-3 d-flex justify-content-between align-items-center flex-wrap">
                            <h5>RECHERCHER UN PRESTATAIRE</h5>
                            <button class="btn btn-secondary" @click="clearFilters">Effacer les filtres</button>
                        </div>
                        <div class="row">
                            <div class="col-lg-4">
                                <div class="form-group">
                                    <input type="text" class="form-control" placeholder="NOM DU PRESTATAIRE" v-model="nomQuery">
                                </div>
                            </div>
                            <div class="col-lg-4">
                                <div class="form-group">
                                    <input type="text" class="form-control" placeholder="PRÉNOMS DU PRESTATAIRE" v-model="prenomsQuery">
                                </div>
                            </div>
                            <div class="col-lg-4">
                                <div class="form-group">
                                    <input type="text" class="form-control" placeholder="RAISON SOCIALE" v-model="raisonSocialeQuery">
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-if="isSearching" class="text-center align-middle">
                        <i class="fa fa-2x fa-spinner fa-spin mr-2"></i> Filtrage en cours...
                    </div>
                </div>
                <div v-if="prestataires.length > 0" class="sf-bs-data-table mt-2">
                    <div  class="table-responsive">
                        <table  class="table table-striped table-bordered" style="width:100%">
                            <thead class="bg-warning">
                                <tr>
                                    <th class="text-center align-middle">N°</th>
                                    <th class="text-start align-middle">NOM & PRENOMS</th>
                                    <th class="text-center align-middle">RAISON SOCIALE</th>
                                    <th class="text-center align-middle">PHOTO</th>
                                    <th class="text-center align-middle">TELEPHONE</th>
                                    <th class="text-center align-middle">ACTIONS</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(prestataire, index) in paginatedPrestataires" :key="index">
                                    <td class="text-center align-middle">{{ (currentPage- 1)*(perPage) + (index +1) }}</td>
                                    <td class="text-start align-middle">{{ prestataire.nom }} {{ prestataire.prenoms }}</td>
                                    <td class="text-center align-middle">{{ prestataire.raison_sociale }}</td>
                                    <td class="text-center align-middle">
                                        <a href="javascript:void(0)" @click="showPrestataire(prestataire)">
                                            <img class="company_logo aon-icon" :src="prestataire.photo ? BACK_URL + 'storage/photoProfile/' + prestataire.photo : '/../../../images/placeholder/placeholder-user.jpg'" alt="" style="height: 50px; width: 50px;">
                                        </a>
                                    </td>
                                    <td class="text-center align-middle">{{ prestataire.telephone }}</td>
                                    <td class="text-center align-middle">
                                        <button class="btn admin-button-secondry" type="button" @click="displayContrats(prestataire.id)" >
                                            <i class="fa fa-file-text"></i> CONTRATS
                                        </button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <div class="d-flex justify-content-between align-items-center">
                            <button class="btn admin-button sm:btn-sm" @click="previousPage" :disabled="currentPage === 1">
                                <i class="feather-arrow-left"></i>
                                Précédent
                            </button>
                            {{ currentPage }} sur {{ totalPages }} pages
                            <button class="btn admin-button sm:btn-sm" @click="nextPage" :disabled="currentPage === totalPages">
                                Suivant
                                <i class="feather-arrow-right"></i>
                            </button>
                        </div>
                    </div>
                </div>
                <div v-else>
                    <div v-if="isTableLoading" class="text-center">
                        <i class="fa fa-2x fa-spinner fa-spin mr-2"></i> Chargement
                    </div>
                    <div v-else class="my-2 alert alert-warning">
                        Aucun prestataire retrouvé
                    </div> 
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>

</style>