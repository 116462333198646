import { createStore } from 'vuex'
import axiosClient from "../axios";
import entrepriseModule from './entrepriseModule';
import prestataireModule from './prestataireModule'
import tacheModule from './tacheModule';
import contactModule from './contactModule';
import statistiqueModule from './statistiqueModule'
import serviceModule from './serviceModule'

const store =  createStore({

  state: {
    isLoggedIn: !!sessionStorage.getItem('TOKEN'),
    user: {
        data: JSON.parse(sessionStorage.getItem('userData')) || {},
        token: sessionStorage.getItem('TOKEN'),
        role: JSON.parse(sessionStorage.getItem('USER_ROLE')),
    },
    entreprise: [],
    prestataire: null,
    users: [],
    services: [],
    prestataires: {},
    entrepriseUser: {},
    servicesUser: {},
    prestataireUser: {},
    adminUser: {},

  },

  getters: {

    getUserRole: (state) => {
        if (state.user.role) {
            return state.user.role.designation;
        } else {
            const roleString = sessionStorage.getItem('USER_ROLE');
            const role = roleString ? JSON.parse(roleString) : null;
            return role ? role.designation : null;
        }
    },

    getEntrepriseUser: (state) => {
        if (state.user.data) {
            return state.user.data.entreprise;
        } else {
            const userDataString = sessionStorage.getItem('userData');
            const userData = userDataString ? JSON.parse(userDataString) : null;
            return userData ? userData.entreprise: {};
        }
    },

    getServiceUser: (state) => {
        if (state.user.data) {
            return state.user.data.service;
        } else {
            const userDataString = sessionStorage.getItem('userData');
            const userData = userDataString ? JSON.parse(userDataString) : null;
            return userData ? userData.service: {};
        }
    },

    getPrestataireUser: (state) => {
        if (state.user.data) {
            return state.user.data.prestataire;
      } else {
          const userDataString = sessionStorage.getItem('userData');
          const userData = userDataString ? JSON.parse(userDataString) : null;
            return userData ? userData.prestataire: {};
        }
    },

    //getServicesUser: (state) => {
    //    return state.servicesUser
    //},

    //getPrestataireUser: (state) => {
    //    return state.prestataireUser
    //},

    getAdminUser: (state) => {
        return state.adminUser
    },

    isLoggedIn: state => {
        return state.isLoggedIn
    },

    isRegistered: state => {
        return state.isRegistered
    },
  },
  
  mutations: {

    logout: (state) => {
        state.user.token = null;
        state.user.data = {};
        state.isLoggedIn = false;
        sessionStorage.removeItem('USER_ROLE');
        sessionStorage.removeItem('userData');
        sessionStorage.removeItem('TOKEN');
        sessionStorage.removeItem('ENTREPRISE_USER');
    },

    setUser: (state, user) => {
        state.user.data = user;
        state.isLoggedIn = true;
        sessionStorage.setItem('userData', JSON.stringify(user));
    },
    
    setToken: (state, token) => {
        state.user.token = token;
        sessionStorage.setItem('TOKEN', token);
    },

    setUserRole: (state, role) => {
        state.user.role = role;
        const roleString = JSON.stringify(role);
        sessionStorage.setItem('USER_ROLE', roleString);
    },

    setIsRegistered(state, isRegistered) {
        state.isRegistered = isRegistered;
    },
    
    setEntrepriseUser(state, entrepriseUser) {
        state.entrepriseUser = entrepriseUser;
        sessionStorage.setItem('ENTREPRISE_USER', JSON.stringify(entrepriseUser));
    },

    setServicesUser(state, servicesUser) {
        state.servicesUser = servicesUser;
    },

    setPrestataireUser(state, prestataireUser) {
        state.prestataireUser = prestataireUser;
    },

    setAdminUser(state, adminUser) {
        state.adminUser = adminUser;
    },

    setEntreprise(state, entreprise) {
        state.entreprise = entreprise;
    },
    
    setUsers(state, users) {
        state.users = users;
    },

    setPrestataire(state, prestataire) {
      state.prestataire = prestataire;
    },

    setServices(state, services) {
        state.services = services;
    },

    ADD_SERVICE(state, service) {
        state.services.push(service)
    },
    setPrestataires(state, prestataires) {
        state.prestataires = prestataires;
    },
  },

  actions: {

    async createUser({ commit }, user) {
        try {
            const response = await axiosClient.post('/users', user);
            const createdUser = response.data;
            commit('setUsers', createdUser);
            return createdUser; // Retourner les données de l'utilisateur nouvellement créé
        } catch (error) {
            console.error(error);
            throw error; 
        }
    },

    async createEntreprise({ commit }, entreprise) {
        try {
            const response = await axiosClient.post('/entreprise', entreprise);
            const createEntreprise = response.data;
            commit('setEntreprise', response.data);
            return createEntreprise;
        } catch (error) {
            console.error(error);
            throw error;
        }
    },

    async getEntrepriseUser({commit}, entrepriseUser) {
        try {
            const response = await axiosClient.get('/entreprise-utilisateur', entrepriseUser);
            const getEntrepriseUser = response.data.entreprise;
            commit('setEntrepriseUser', response.data.entreprise);
            return getEntrepriseUser;
        } catch (error) {
            //console.error(error);
            throw error;
        }
    },

    async getServicesUser({commit}, servicesUser) {
        try {
            const response = await axiosClient.get('/service-utilisateur', servicesUser);
            const getServicesUser = response.data.service;
            commit('setServicesUser', response.data.service);
            return getServicesUser;
        } catch (error) {
            //console.error(error);
            throw error; 
        }
        
    },

    async getPrestataireUser({commit}, prestataireUser) {
        try {
            const response = await axiosClient.get('/prestataire-utilisateur', prestataireUser);
            const getPrestataireUser = response.data.prestataire;
            commit('setPrestataireUser', response.data.prestataire);
            return getPrestataireUser;
        } catch (error) {
            //console.error(error);
            throw error; 
        }
        
    },

    async getAdminUser({commit}, adminUser) {
        try {
            const response = await axiosClient.get('/admin/info', adminUser);
            const getAdminUser = response.data.admin;
            commit('setAdminUser', response.data.admin);
            return getAdminUser;
        } catch (error) {
            console.error(error);
            throw error;
        }
    },

    async updateAdminUser({ commit }, formData) {
        try {
          const response = await axiosClient.put('/admin-update', formData);
          const updatedAdminUser = response.data.admin;
          commit('setAdminUser', updatedAdminUser);
          return updatedAdminUser;
        } catch (error) {
          console.error('Erreur lors de la mise à jour de l\'utilisateur administrateur :', error);
          throw error;
        }
    },

    async fetchEntreprises({ commit }, page = 1) {
        try {
          const response = await axiosClient.get(`/entreprise?page=${page}`);
          commit('setEntreprise', response.data.data);
        } catch (error) {
          console.error(error);
        }
    },

    async deleteEntreprise({ commit }, entrepriseId) {
        try {
          await axiosClient.delete(`/entreprise/${entrepriseId}`);
          
        } catch (error) {
          console.error('Erreur lors de la suppression de l\'entreprise :', error);
        }
    },

    async createPrestataire({ commit }, prestataire) {
        try {
            const response = await axiosClient.post('/prestataire', prestataire);
            const createPrestataire = response.data;
            commit('setPrestataires', response.data);
            return createPrestataire;
        } catch (error) {
            console.error(error);
            throw error;
        }
    },

    async fetchPrestataire({ commit }, search) {
        try {
            const response = await axiosClient.get('/prestataire');
            commit('setPrestataires', response.data.data);
            //console.log(response.data.data);
        } catch (error) {
            console.error(error);
        }
    },

    async addService({ commit }, service) {
        try {
            const response = await axiosClient.post('/services', service);
            const addService = response.data;
            commit('setServices', response.data);
            return addService;
        } catch (error) {
            console.error(error);
            throw error;
        }
    },
    
    async fetchService({ commit }) {
        try {
            const response = await axiosClient.get('/services');
            commit('setServices', response.data.data)
        } catch (error) {
            console.error(error);
        }
    },

    async register({ commit }, user) {
      return axiosClient.post('/register', user)
      .then(({ data }) => {
          commit('setUser', data.user);
          commit('setToken', data.token);
          commit('setUserRole', data.user.role);
          return data;
      })
    },
  
    async login({ commit }, user) {
        try {
            const response = await axiosClient.post('/login', user);
            const { data } = response;
            commit('setUser', data.user);
            commit('setToken', data.token);
            commit('setUserRole', data.user.role);
            return data;
        } catch (error) {
            console.error(error);
            throw error; 
        }
    },

    async FORGOT_PASSWORD({ commit }, forgotData) {
        return await axiosClient.post('/forgot-password', forgotData);
    },

    async RESET_PASSWORD({ commit }, resetData) {
        return await axiosClient.post('/reset-password', resetData);
    },
    
    async logout({ commit }) {
        return axiosClient.post('/logout')
        .then(response => {
            commit('logout');
            window.location.reload();
            return response;
        })
    },

  },
  modules: {
    entrepriseModule,
    serviceModule,
    prestataireModule,
    tacheModule,
    contactModule,
    statistiqueModule,
  }

});

export default store;
