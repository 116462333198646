<script>
import axios from '../../axios';
import ApexCharts from 'apexcharts';

export default {
    data() {
        return {
            contrats: {
                actif: 0,
                terminee: 0,
                total_prestataires: 0
            },
            taches: {
                terminee: 0,
                en_cours: 0
            },
            prestataires: {
                nombre_total: 0,
            }
        };
    },
    mounted() {

        this.createChart();

        // Requête pour récupérer les contrats
        axios.get(`/statcontratservice`)
            .then(response => {
                this.contrats = response.data.contrats;
            })
            .catch(error => {
                console.error('Erreur lors de la récupération des données des contrats : ', error);
            });

        // Requête pour récupérer les tâches
        axios.get(`/stattachesservice`)
            .then(response => {
                this.taches = response.data.taches;
            })
            .catch(error => {
                console.error('Erreur lors de la récupération des données des tâches : ', error);
            });

        // Requête pour récupérer le nombre total de prestataires
        axios.get(`/statprestataireservice`)
            .then(response => {
                this.prestataires.nombre_total = response.data.nombre_total;
            })
            .catch(error => {
                console.error('Erreur lors de la récupération du nombre total de prestataires : ', error);
            });
    },
    methods: {
        createChart() {
            const options = {
                series: [
                    {
                        name: 'Contrat',
                        data: [
                            { x: '2024', y: 1292 },
                            { x: '2025', y: 4432 },
                            { x: '2026', y: 5423 },
                            { x: '2027', y: 6653 },
                            { x: '2028', y: 8133 },
                            { x: '2029', y: 7132 },
                            { x: '2030', y: 7332 },
                            { x: '2031', y: 6553 }
                        ]
                    },
                    {
                        name: 'Tâches',
                        data: [
                            { x: '2024', y: 1000 },
                            { x: '2025', y: 2000 },
                            { x: '2026', y: 3000 },
                            { x: '2027', y: 4000 },
                            { x: '2028', y: 5000 },
                            { x: '2029', y: 6000 },
                            { x: '2030', y: 7000 },
                            { x: '2031', y: 8000 }
                        ]
                    },
                    {
                        name: 'Prestataire',
                        data: [
                            { x: '2024', y: 500 },
                            { x: '2025', y: 1000 },
                            { x: '2026', y: 1500 },
                            { x: '2027', y: 2000 },
                            { x: '2028', y: 2500 },
                            { x: '2029', y: 3000 },
                            { x: '2030', y: 3500 },
                            { x: '2031', y: 4000 }
                        ]
                    }
                ],
                chart: {
                    height: 350,
                    type: 'bar'
                },
                plotOptions: {
                    bar: {
                        columnWidth: '60%'
                    }
                },
                colors: ['#00E396', '#775DD0', '#FF7F0E'],
                dataLabels: {
                    enabled: false
                },
                legend: {
                    show: true,
                    showForSingleSeries: true,
                    customLegendItems: ['Contrat', 'Tâches', 'Prestataire'],
                    markers: {
                        fillColors: ['#00E396', '#775DD0', '#FF7F0E']
                    }
                }
            };

            const chart = new ApexCharts(document.querySelector("#chart"), options);
            chart.render();
        }
    }
};
</script>

<template>
    <div class="row">
        <div class="col-xl-12 col-lg-12 m-b30 break-1300">
            <div class="card aon-card">
                <div class="card-body aon-card-body">
                    <div class="row">
                        <div class="col-lg-4 m-b30">
                            <div class="panel panel-default ser-card-default">
                                <div class="panel-body ser-card-body ser-orange p-a30">
                                    <div class="ser-card-title">PRESTATAIRES</div>
                                    <div class="ser-card-amount">{{ prestataires.nombre_total }} </div>
                                    <div class="ser-card-table">
                                        <div class="ser-card-right">
                                            <div class="ser-card-icon"><i class="glyph-icon flaticon-money-3"></i></div>
                                        </div>
                                    </div>
                                    <span class="ser-card-circle-bg"></span>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 m-b30">
                            <div class="panel panel-default ser-card-default">
                                <div class="panel-body ser-card-body ser-yellow p-a30">
                                    <div class="ser-card-title"> CONTRATS EN COURS</div>
                                    <div class="ser-card-amount">{{ contrats.actif }}</div>
                                    <div class="ser-card-table">
                                        <div class="ser-card-right">
                                            <div class="ser-card-icon"><i class="glyph-icon flaticon-wallet"></i></div>
                                        </div>
                                    </div>
                                    <span class="ser-card-circle-bg"></span>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 m-b30">
                            <div class="panel panel-default ser-card-default">
                                <div class="panel-body ser-card-body ser-blue p-a30">
                                    <div class="ser-card-title">CONTRATS TERMINÉS</div>
                                    <div class="ser-card-amount">{{ contrats.terminee }}</div>
                                    <div class="ser-card-table">
                                        <div class="ser-card-right">
                                            <div class="ser-card-icon"><i class="glyph-icon flaticon-calendar"></i></div>
                                        </div>
                                    </div>
                                    <span class="ser-card-circle-bg"></span>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="row">

        <div class="col-12">
            <div class="card aon-card">
                <div class="card-header aon-card-header aon-card-header2">
                    <h4> Statistiques de mes tâches</h4>
                </div>
                <div>
                    <ul class="list-unstyled">
                        <li class="py-3">
                            <div class="d-flex align-items-center">
                                <div class="avatar-xs rounded-circle m-r10">
                                    <i class="feather-check-circle"></i>
                                </div>
                                <div class="flex-grow-1">
                                    <p class="text-muted mb-2">Terminée(s) <b>( {{ taches.terminee }} )</b> </p>
                                    <div class="progress progress-sm animated-progess">
                                        <div class="progress-bar bg-success" role="progressbar"
                                            :style="{ width: taches.terminee + '%' }" :aria-valuenow="taches.terminee"
                                            aria-valuemin="0" aria-valuemax="100">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li class="py-3">
                            <div class="d-flex align-items-center">
                                <div class="avatar-xs rounded-circle m-r10">
                                    <i class="feather-calendar"></i>
                                </div>
                                <div class="flex-grow-1">
                                    <p class="text-muted mb-2">En attente(s) <b> ( {{ taches.en_cours }} ) </b></p>
                                    <div class="progress progress-sm animated-progess">
                                        <div class="progress-bar bg-warning" role="progressbar"
                                            :style="{ width: taches.en_cours + '%' }" :aria-valuenow="taches.enCours"
                                            aria-valuemin="0" aria-valuemax="100">
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </li>
                    </ul>
                </div>
                <div class="card aon-card">
                    <div class="card-header aon-card-header aon-card-header2">
                        <h4><i class="feather-bar-chart"></i> Statistiques Annuel</h4>
                    </div>
                    <div class="card-body aon-card-body">
                        <div id="chart"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
  
<style></style>