<script>
import axios from '@/axios';
import { BACK_URL } from '@/constant';
import axiosClient from '@/axios';
import Swal from 'sweetalert2';

export default {
    data() {
        return {
            tasks: {},
            isSearching: false,
            isTableLoading: false,
            currentPage: 1,
            perPage:  5,
            lastPage: 1,
            nom: '',
            prenoms: '',
            raisonSociale:'',
            BACK_URL,
        };
    },

    computed: {

        prestataireTaches(){
            return this.tasks;
        },

        paginatedGroupedTaches(){
            const startIndex = (this.currentPage - 1) * this.perPage;
            const endIndex = startIndex + this.perPage;
            return Object.keys(this.prestataireTaches).slice(startIndex, endIndex).reduce((obj, key) => {
              obj[key] = this.prestataireTaches[key];
              return obj;
            }, {});
        },

        totalPages() {
            return Math.ceil(Object.keys(this.prestataireTaches).length / this.perPage);
        },
    },

    methods: {
        async fetchTasks() {
            try {
                const params = {
                    nom: this.nom,
                    prenoms: this.prenoms,
                    raison: this.raisonSociale
                };

                const response = await axios.get('/taches-pour-service', { params });
                if (response.status === 200 && response.data && response.data) {
                    this.tasks = response.data;
                } else {
                    console.error('Les données de tâches reçues ne sont pas valides :', response.data);
                }
            } catch (error) {
                console.error('Erreur lors de la récupération des tâches :', error);
            }
        },

        async validerTache(taskId) {
            await axiosClient.put(`/tache/${taskId}/validerstatut`)
            .then((response)=>{
                if (response.status === 201) {
                    Swal.fire({
                        title: 'Succès',
                        text: 'la validation s\'est effectuée avec succès',
                        icon: 'success',
                        confirmButtonColor: '#022279', //'#ffc107',
                        confirmButtonText: 'OK'
                    });
                    this.fetchTasks(); // Recharge les tâches après la validation
                }
            }).catch ((error) => {
                console.error('Erreur lors de la validation de la tâche :', error);
            })
        },

        async relancerExecution(taskId) {
            await axiosClient.put(`/tache/${taskId}/relancerexecution`)
            .then((response)=>{
                if (response.status === 201) {
                    Swal.fire({
                        title: 'Succès',
                        text: 'Votre relance d\'exécution à bien été envoyée au prestataire',
                        icon: 'success',
                        confirmButtonColor: '#022279', //'#ffc107',
                        confirmButtonText: 'OK'
                    });
                }
            }).catch ((error) => {
                console.error('Erreur lors de la relance de l\'exécution de la tâche :', error);
            })
        },

        async devaliderTache(taskId) {
            await axiosClient.put(`/tache/${taskId}/devaliderstatut`)
            .then((response)=>{
                if (response.status === 201) {
                    Swal.fire({
                        title: 'Succès',
                        text: 'la dévalidation s\'est effectuée avec succès',
                        icon: 'success',
                        confirmButtonColor: '#022279', //'#ffc107',
                        confirmButtonText: 'OK'
                    });
                    this.fetchTasks(); // Recharge les tâches après la dévalidation
                }
            }).catch ((error) => {
                console.error('Erreur lors de la dévalidation de la tâche :', error);
            })
        },

        previousPage() {
            if (this.currentPage > 1) {
                this.currentPage--;
            }
        },

        nextPage() {
            if (this.currentPage < this.totalPages) {
                this.currentPage++;
            }
        },

        shortenText(name, maxLength = 100) {
            if (name.length > maxLength) {
                return name.substring(0, maxLength - 3) + '...';
            }
                return name;
        },

        searchTasks() {
            this.isSearching = true;
            this.fetchTasks()
            .finally(() => {
                this.isSearching = false;
            });
        },

        clearFilters(){
            this.nom = '';
            this.prenoms = '';
            this.raison_sociale = '';
            this.searchTasks();
        },
        
        goBack() {
            this.$router.back();
        }
    },
    created() {
        this.isTableLoading = true;
        this.fetchTasks()
        .then(()=>{
            this.isTableLoading = false;
        })
    }
};
</script>


<template>
    <div>
        <div class="aon-admin-heading">
            <div class="d-flex justify-content-start align-items-center">
                <button class="btn btn-light btn-lg mr-2" type="button" @click="goBack()">
                    <i class="feather-arrow-left"></i>
                </button>
                <h3 class="text-center">PRESTATIONS</h3>
            </div>
        </div>
        <div class="card aon-card">
            <div class="card-header aon-card-header">
                <div class="aon-card-header aon-blog-style-1 d-flex justify-content-between align-items-center flex-wrap" style="background-color: rgba(50, 50, 168, 0.2); border: 1px solid rgba(50, 50, 168, 0.5); border-radius: 10px;">
                    <div>
                        <h4><i class="fa fa-list"></i> LISTE DES PRESTATIONS</h4>
                    </div>
                </div>
            </div>
            
            <div class="card-body aon-card-body">
                <div class="pb-3 px-3 mb-4" style="background-color: rgba(50, 50, 168, 0.2); border: 1px solid rgba(50, 50, 168, 0.5); border-radius: 10px;">
                    <div class="py-3 d-flex justify-content-between align-items-center flex-wrap">
                        <h5>RECHERCHER UNE PRESTATION</h5>
                        <button class="btn btn-secondary" @click="clearFilters">Effacer les filtres</button>
                    </div>
                    <div class="row">
                        <div class="col-lg-4 form-group">
                            <input type="text" class="form-control" placeholder="NOM DU PRESTATAIRE" id="nom" v-model="nom" @input="searchTasks">
                        </div>
                        <div class="col-lg-4 form-group">
                            <input type="text" class="form-control" placeholder="PRÉNOMS DU PRESTATAIRE" id="prenom" v-model="prenoms" @input="searchTasks">
                        </div>
                        <div class="col-lg-4 form-group">
                            <input type="text" class="form-control" placeholder="RAISON SOCIALE" id="raison-sociale" v-model="raisonSociale" @input="searchTasks">
                        </div>
                    </div>
                </div>
                <div v-if="isSearching" class="text-center align-middle mb-2">
                    <i class="fa fa-2x fa-spinner fa-spin mr-2"></i> Filtrage en cours...
                </div>
                <div v-if="Object.keys(prestataireTaches).length != 0">
                    <div v-for="(group, date) in paginatedGroupedTaches" :key="date" class="mt-3">
                        <h3>{{ date }}</h3><hr>
                        <div class="row">
                            <div v-for="(tache, index) in group" :key="index" class="col-lg-6">
                                <div class="aon-post-jobsCol mba-bdr-15">
                                    <div class="aon-post-jobs2 aon-icon-effect" :class="{'finished-task': tache.statut == 'terminee', 'pending-task': tache.statut == 'en cours', 'scheduled-task': tache.statut == 'planifiee'}">
                                        <div class="job-comapny-logo mr-2">
                                            <img class="company_logo aon-icon" :src="tache.prestataire.photo ? BACK_URL + 'storage/photoProfile/' + tache.prestataire.photo : '/../../../images/placeholder/placeholder-user.jpg'" alt="">
                                        </div>
                                        <p style="color: #000000; font-weight: 800;" class="text-start">
                                            {{ tache.date_debut }} au {{ tache.date_fin }}<br />
                                            <span v-if="tache.objet.niveau_priorite == 'normal'" class="badge badge-secondary mr-2">Normal</span>
                                            <span v-if="tache.objet.niveau_priorite == 'urgent'" class="badge badge-warning mr-2">Urgent</span>
                                            <span v-if="tache.objet.niveau_priorite == 'critique'" class="badge badge-danger mr-2">Critique</span>
                                            <span v-if="tache.statut == 'en cours'" class="badge badge-primary">En cours</span>
                                            <span v-if="tache.statut == 'terminee'" class="badge badge-success">Terminée</span>
                                            <span v-if="tache.statut == 'planifiee'" class="badge badge-info">Planifiée</span>
                                        </p>
                                        <div class="text-start py-3">
                                            <div style="color: #000000; overflow: hidden; font-size: larger; display: flex; align-items: center;">
                                                {{ shortenText(tache.description) }}
                                            </div>
                                        </div>
                                        <div class="d-flex justify-content-between align-items-center flex-wrap">
                                                <div class="mt-2">
                                                    <a type="button" class="btn admin-button mr-1"
                                                        :href="'/service/taches/details/' + tache.id">
                                                        <i class="fa fa-eye"></i>
                                                        voir plus
                                                    </a>
                                                </div>
                                                <div class="mt-2">
                                                    <button v-if="tache.statut == 'terminee'" class="btn admin-button-danger" @click="devaliderTache(tache.id)">
                                                        DÉVALIDER L'EXÉCUTION DE LA TÂCHE
                                                    </button>
                                                    <button v-else-if="tache.statut == 'planifiee'" class="btn admin-button-secondry" @click="relancerExecution(tache.id)">
                                                        RELANCER L'EXÉCUTION DE LA TÂCHE
                                                    </button>
                                                    <button v-else type="button" class="btn admin-button-success" @click="validerTache(tache.id)">
                                                        VALIDER L'EXÉCUTION DE LA TÂCHE
                                                    </button>
                                                </div>
                                            </div>
                                        <div class="dropdown action-dropdown dropdown-left">
                                            <button class="action-button gray dropdown-toggle" type="button" data-toggle="dropdown"
                                                aria-expanded="true"><i class="fa fa-ellipsis-v"></i></button>
                                            <ul class="dropdown-menu">
                                                <li><a @click="editTache(tache)" href="javascript:;"><i class="feather-edit text-primary"></i> Editer</a></li>
                                                <li><a @click="deleteTache(tache.id)" href="javascript:;"><i class="feather-trash text-danger"></i> Supprimer</a></li>
                                                <li v-if="tache.rapport != null" class="mx-1">
                                                    <button class="btn btn-sm btn-light" @click="downloadRapport(tache.rapport)">
                                                        <i class=" fa fa-download text-success"></i> Télécharger Rapport
                                                    </button>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr>
                    <div class="d-flex justify-content-between align-items-center">
                        <button class="btn admin-button sm:btn-sm" @click="previousPage" :disabled="currentPage === 1">
                            <i class="feather-arrow-left"></i>
                            Précédent
                        </button>
                        {{ currentPage }} sur {{ totalPages }} pages
                        <button class="btn admin-button sm:btn-sm" @click="nextPage" :disabled="currentPage === totalPages">
                            Suivant
                            <i class="feather-arrow-right"></i>
                        </button>
                    </div>
                </div>
                <div v-else>
                    <div v-if="isTableLoading" class="text-center">
                        <i class="fa fa-2x fa-spinner fa-spin mr-2"></i> Chargement
                    </div>
                    <div v-else class="my-2 alert alert-warning">
                        Aucune tâche retrouvée.
                    </div>
                </div>
            </div>
        </div>


        <!--<div v-if="tasks.data.length === 0" class="alert alert-warning" role="alert">
            Aucune tâche disponible pour validation.
        </div>
        <div v-else>
            <div v-for="task in tasks.data" :key="task.id">
                <div class="row">
                    <div class="col-lg-6 col-md-6">
                        <div class="aon-post-jobsCol media-bg-animate mba-bdr-15">
                            <div class="aon-post-jobs2 aon-icon-effect">
                                <div class="job-comapny-logo mr-2">
                                    <img class="company_logo aon-icon" src="/images/jobs/4.jpg" alt="">
                                </div>
                                <p class="text-start">
                                    {{ task.date_debut }} au {{ task.date_fin }}<br />
                                    <span v-if="task.statut == 'en cours'" class="badge badge-warning">
                                        en attente</span>
                                    <span v-if="task.statut == 'terminee'" class="badge badge-success">
                                        validée</span>
                                </p>
                                <p class="text-start">
                                    {{ task.description }}<br />
                                </p>
                                <div class="d-flex justify-content-between align-items-center" style="height: 50px;">
                                    <div class="mt-2">
                                        <a type="button" class=" btn admin-button mr-2" :href="'/service/taches/details/' + task.id">
                                        <i class="fa fa-eye"></i>
                                        Voir plus
                                    </a>
                                    </div>
                                    <div class="mt-2">
                                        <button v-if="task.statut === 'en cours'" type="button" class="btn btn-success ml-1"
                                            @click="validerTache(task.id)">
                                            <i class="fas fa-check"></i> Validé
                                        </button>

                                        <button v-else-if="task.statut === 'terminee'" type="button"
                                            class="btn btn-danger ml-1" @click="devaliderTache(task.id)">
                                            <i class="fas fa-times"></i> Dévalidé
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="d-flex justify-content-between align-items-center">
            <button class="btn sm:btn-sm btn-warning" @click="previousPage()">
                <i class="feather-arrow-left"></i>
                Précédent
            </button>
            Page {{ currentPage }} / {{ lastPage }}
            <button class="btn sm:btn-sm btn-primary" @click="nextPage()">
                Suivant
                <i class="feather-arrow-right"></i>
            </button>
        </div>-->
    </div>
</template>

<style scoped>
    .finished-task{
        background-color: rgba(50, 168, 103, 0.164);
    }

    .pending-task{
       background-color: rgba(50, 50, 168, 0.164);
    }

    .scheduled-task{
        background-color: rgba(168, 152, 50, 0.164);
    }
</style>