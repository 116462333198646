<script>
import axios from '../../axios';

export default {
    data() {
        return {
            nombreServices: 0,
            nombrePrestataires: 0,
            nombreContrats: 0
        };
    },
    mounted() {
        this.getNombreServices();
        this.getNombrePrestataires();
        this.getNombreContrats();
    },
    methods: {
        async getNombreServices() {
            try {
                const response = await axios.get('/statnbrservice');
                this.nombreServices = response.data.nombre_services;
            } catch (error) {
                console.error(error);
            }
        },
        async getNombrePrestataires() {
            try {
                const response = await axios.get('/statprestataireentreprise');
                this.nombrePrestataires = response.data.nombre_prestataires;
            } catch (error) {
                console.error(error);
            }
        },
        async getNombreContrats() {
            try {
                const response = await axios.get('/statcontratentreprise');
                this.nombreContrats = response.data.nombre_contrats;
            } catch (error) {
                console.error(error);
            }
        },
    }
}
</script>

<template>
    <div class="row">
        <div class="col-xl-12 col-lg-12 m-b30 break-1300">
            <div class="card aon-card">
                <div class="card-body aon-card-body">
                    <div class="row">
                        <div class="col-lg-4 m-b30">
                            <div class="panel panel-default ser-card-default">
                                <div class="panel-body ser-card-body ser-yellow p-a30">
                                    <div class="ser-card-title">PRESTATAIRES</div>
                                    <div class="ser-card-amount">{{ nombrePrestataires }}</div>
                                    <div class="ser-card-table">
                                        <div class="ser-card-right">
                                            <div class="ser-card-icon"><i class="glyph-icon flaticon-wallet"></i></div>
                                        </div>
                                    </div>
                                    <span class="ser-card-circle-bg"></span>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 m-b30">
                            <div class="panel panel-default ser-card-default">
                                <div class="panel-body ser-card-body ser-puple p-a30">
                                    <div class="ser-card-title">SERVICES</div>
                                    <div class="ser-card-amount">{{ nombreServices }}</div>
                                    <div class="ser-card-table">
                                        <div class="ser-card-right">
                                            <div class="ser-card-icon"><i class="glyph-icon flaticon-money-3"></i></div>
                                        </div>
                                    </div>
                                    <span class="ser-card-circle-bg"></span>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 m-b30">
                            <div class="panel panel-default ser-card-default">
                                <div class="panel-body ser-card-body ser-orange p-a30">
                                    <div class="ser-card-title">CONTRATS</div>
                                    <div class="ser-card-amount">{{ nombreContrats }}</div>
                                    <div class="ser-card-table">
                                        <div class="ser-card-right">
                                            <div class="ser-card-icon"><i class="glyph-icon flaticon-wallet"></i></div>
                                        </div>
                                    </div>
                                    <span class="ser-card-circle-bg"></span>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-12">
            <div class="card aon-card">
                <div class="card-header aon-card-header aon-card-header2">
                    <h4><i class="feather-pie-chart"></i> Statistiques des tâches</h4>
                </div>
                <div class="card-body aon-card-body">
                    <div>
                        <ul class="list-unstyled">
                            <li class="py-3">
                                <div class="d-flex align-items-center">
                                    <div class="avatar-xs rounded-circle m-r10">
                                        <i class="feather-check-circle"></i>
                                    </div>
                                    <div class="flex-grow-1">
                                        <p class="text-muted mb-2">Terminée(s)</p>
                                        <div class="progress progress-sm animated-progess">
                                            <div class="progress-bar bg-success" role="progressbar" aria-valuemin="0" aria-valuemax="100">
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </li>
                            <li class="py-3">
                                <div class="d-flex align-items-center">
                                    <div class="avatar-xs rounded-circle m-r10">
                                        <i class="feather-calendar"></i>
                                    </div>
                                    <div class="flex-grow-1">
                                        <p class="text-muted mb-2">En attente(s)</p>
                                        <div class="progress progress-sm animated-progess">
                                            <div class="progress-bar bg-warning" role="progressbar" aria-valuemin="0" aria-valuemax="100">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <hr>
                    <div class="text-center">
                        <div class="row aon-states-row">
                            <div class="col-6">
                                <div class="mt-2">
                                    <p class="text-muted mb-2">Terminée(s)</p>
                                    <h5 class="font-size-16 mb-0"> </h5>
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="mt-2">
                                    <p class="text-muted mb-2">En attente(s)</p>
                                    <h5 class="font-size-16 mb-0"> </h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style></style>