<template>
  <router-view/>
</template>

<style>
</style>

<script>

export default {

}
</script>


<style>


</style>