<template>
  <div class="page-wraper">
    <Header />
            <!-- CONTENT START -->
            <div class="page-content">
           
           <!-- BANNER SECTION START -->
           <section class="aon-banner-wrap">

               <!--Left Section-->
               <div class="aon-banner-outer sf-overlay-wrapper">
                   <div class="aon-banner-pic">
                       <div class="aon-curve-area"></div>
                       <div class="aon-overlay-main" style="opacity:0.69; background-color:#022278;"></div>
                       <img src="images/banner/entretien-clim-2.jpg" width="1919" height="976" alt="">
                   </div>
                   <div class="aon-banner-text">
                       <div class="container"> 
                           <div class="aon-bnr-write">
                               <h2 class="text-top-line"><span class="text-secondry">KATUFAI</span></h2>
                               <h2 class="text-bot-line">la plateforme digitale des prestations de service.</h2>
                           </div>
                       </div>
                   </div>
               </div>
               <!--Right Section-->
               <div class="aon-find-bar aon-findBar-vertical" style="margin-top: -210px;">
                 <div class="container">
                   <!-- Search Form start--> 
                            <div class="aon-bnr2-media-wrap">
                                <!-- COLUMNS 1 -->
                                <div class="aon-bnr2-media">
                                    <img src="images/banner2/bg.png" alt="">
                                </div>
                                <!-- COLUMNS 2 -->
                                <div class="aon-bnr2-lines-left">
                                    <div class="aon-bnr2-line-left-content">
                                        <img src="images/banner2/line-left.png" alt="">
                                        <span class="circle-l-1 slide-fwd-center"></span>
                                        <span class="circle-l-2 slide-fwd-center2"></span>
                                        <span class="circle-l-3 slide-fwd-center3"></span>
                                    </div>
                                </div>
                                <!-- COLUMNS 3 -->
                                <div class="aon-bnr2-lines-right">
                                    <img src="images/banner2/line-right.png" alt="">
                                    <span class="circle-r-1 slide-fwd-center3"></span>
                                    <span class="circle-r-2 slide-fwd-center2"></span>
                                    <span class="circle-r-3 slide-fwd-center"></span>
                                </div>
                            </div>
                   <!-- Search Form End--> 
                 </div>
               </div>

           </section>
           <!-- BANNER SECTION END -->

           <!-- Why Choose us -->    
           <div class="aon-whycoose-area sf-curve-pos">
               <div class="container-fluid">
                   <div class="sf-whycoose-section">
                       <div class="row sf-w-choose-bg-outer d-flex flex-wrap a-b-none">
                           <!-- Left Section -->
                           <div class="col-md-7 margin-b-50 sf-w-choose-left-cell">
                               <div class="sf-w-choose-info-left">
                                   <!--Title Section Start-->
                                   <div class="section-head">
                                       <div class="row">
                                           <div class="col-md-12  margin-b-50">
                                               <h2 class="sf-title">QUI SOMMES NOUS</h2>
                                               <p>
                                                  <span class="text-warning"> KATUFAI </span> est la plateforme qui permet aux entreprises de suivre en temps réel les travaux réalisés par leurs prestataires.
                                               </p>
                                               <p>
                                                  <span class="text-warning"> KATUFAI </span> est la plateforme qui permet aux prestataires d’enregistrer en temps réel les prestations exécutées dans le cadre d’un contrat avec une entreprise.
                                               </p>
                                           </div>
                                       </div>
                                   </div>
                                   <!--Title Section End-->

                                   <!-- COLUMNS 1 -->
                                   <div class="sf-w-choose margin-b-20">
                                       <div class="sf-w-choose-icon">
                                           <span>
                                               <img src="images/whychoose/1.png" alt="">
                                           </span>
                                       </div>
                                       <div class="sf-w-choose-info">
                                           <h4 class="sf-title"> CREEZ LE REPERTOIRE NUMERIQUE DE VOS PRESTATAIRES</h4>
                                           <p>
                                                <span class="text-warning"> KATUFAI </span>  permet aux responsables des services techniques et logistiques des entreprises privées et publiques d’enregistrer la liste de leurs prestataires de service et de valider en ligne les prestations réalisées.
                                           </p>
                                           <p>
                                            <span class="text-warning"> KATUFAI </span> permet aux prestataires de services en relation avec les entreprises privées et publiques d’enregistrer toutes les prestations réalisées dans le cadre d’un contrat de prestations de services.
                                           </p>
                                       </div>
                                   </div>
                                   <!-- COLUMNS 2 -->
                                   <div class="sf-w-choose margin-b-20">
                                       <div class="sf-w-choose-icon">
                                           <span>
                                               <img src="images/whychoose/2.png" alt="">
                                           </span>
                                       </div>
                                       <div class="sf-w-choose-info">
                                           <h4 class="sf-title"> SUPERVISION EN TEMPS REEL</h4>
                                           <p>
                                            <span class="text-warning"> KATUFAI </span> informe en temps réel les responsables des services techniques sur le niveau d’avancement des travaux confiés aux prestataires. KATUFAI envoi une notification instantanée pour chaque nouvelle prestation réalisée.
                                            </p>
                                       </div>
                                   </div>
                                   <!-- COLUMNS 3 -->
                                    <!--<div class="sf-w-choose">
                                       <div class="sf-w-choose-icon">
                                           <span>
                                               <img src="images/whychoose/3.png" alt="">
                                           </span>
                                       </div>
                                       <div class="sf-w-choose-info">
                                           <h4 class="sf-title">Construisez votre réputation en ligne</h4>
                                           <p>Suspendisse tincidunt rutrum ante. Vestibulum elementum ipsum sit amet turpis elementum lobortis.</p>
                                       </div>
                                   </div>-->
                               </div>
                           </div>
                           <!-- Right Section -->
                           <div class="col-md-5 sf-w-choose-bg-wrap sf-w-choose-right-cell">
                               <div class="sf-w-choose-bg" style="background-image: url(images/background/KATUFAI3.jpg);"></div>
                           </div>
                       </div>
                   </div>
               </div>
           </div>
           <!-- Why Choose us END -->

           <!-- How it Work -->
           <section class="bg-white aon-how-service-area sf-curve-pos">
               <div class="container">

                   <div class="section-content">
                      <div class="row">
                           <!--Title Section Start-->
                           <div class="col-lg-4 col-md-12">
                               <span class="aon-sub-title">ETAPE</span>
                               <h2 class="sf-title">COMMENT SA MARCHE ?</h2>
                           </div>
                           <!--Title Section End-->

                           <div class="col-lg-8 col-md-12">
                               <!-- Steps Block Start-->
                               <div class="aon-step-blocks">
                                   <div class="row">
                                       <!-- COLUMNS 1 -->
                                       <div class="col-md-4 col-sm-4 m-b30">
                                           <div class="aon-step-section step-position-1 aon-icon-effect">
                                               <div class="aon-step-icon aon-icon-box">
                                                   <span>
                                                       <i class="aon-icon"><img src="images/step-icon/1.png" alt=""></i>
                                                   </span>
                                               </div>
                                               <div class="aon-step-info">
                                                   <h4 class="aon-title">Le prestataire </h4>
                                                   <p>  « Enregistre une tâche »</p>
                                               </div>
                                           </div>
                                       </div>

                                       <!-- COLUMNS 2 -->
                                       <div class="col-md-4 col-sm-4 m-b30">
                                           <div class="aon-step-section step-position-2 aon-icon-effect">
                                               <div class="aon-step-icon">
                                                   <span>
                                                       <i class="aon-icon"><img src="images/step-icon/2.png" alt=""></i>
                                                   </span>
                                               </div>
                                               <div class="aon-step-info">
                                                   <h4 class="aon-title">Le responsable</h4>
                                                   <p>« Valide la tâche »</p>
                                               </div>
                                           </div>
                                       </div>

                                       <!-- COLUMNS 3 -->
                                       <div class="col-md-4 col-sm-4 m-b30">
                                           <div class="aon-step-section  step-position-3  aon-icon-effect">
                                               <div class="aon-step-icon">
                                                   <span>
                                                       <i class="aon-icon"><img src="images/step-icon/3.png" alt=""></i>
                                                   </span>
                                               </div>
                                               <div class="aon-step-info">
                                                   <h4 class="aon-title"> Le prestataire </h4>
                                                   <p>« Imprime son rapport périodique »</p>
                                               </div>
                                           </div>
                                       </div>
                                   </div>
                               </div>
                               <!-- Steps Block End-->
                           </div>

                       </div>
                   </div>

               </div>
           </section>
           <!-- How it Work END -->                         
           
       </div>
       <!-- CONTENT END -->
    <Footer />

    <button class="scroltop"><span class="fa fa-angle-up  relative" id="btn-vibrate"></span></button>
  </div>
  <Auth />
</template>

<script>
import Header from "../components/Header.vue";
import Footer from "../components/Footer.vue";
import Auth from "../components/Auth.vue";

export default {
  components: { Header, Footer, Auth },
};
</script>

<style></style>